import React, { Component } from "react";
import { ID } from "../utils/id";
import { CoverImg, PrimaryText } from "./components";
import { GTAmerica } from "../components/typography.js";

import globeIMG from "../images/globe.png";
import brazilHalfGlobe from "../images/brazil_halfglobe.png";

const text1 = (
  <PrimaryText
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
  >
    Join me on a journey back to my home in Brazil...
  </PrimaryText>
);

export const globeModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 1,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [text1],
      bottom: [<CoverImg src={globeIMG} />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [],
      bottom: [<CoverImg src={brazilHalfGlobe} />]
    }
  ]
};
