import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import birdsEyeVillageIMG from "../images/birds-eye-village.png";
import birdsEyeVillageMobileIMG from "../images/birds-eye-village-annotated-mobile-cropped.png";
import birdsEyeVillageAnnotatedSVG from "../images/birds-eye-village-annotated-laptop-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentSVG } from "../images/birds-eye-village-annotated-laptop-01.svg";
import birdsEyeVillageAnnotatedMobileSVG from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentMobileSVG } from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import ladyProfilePicIMG from "../images/lady.jpeg";
import kitchenProfilePicIMG from "../images/kitchen.jpeg";
import spiritualProfilePicIMG from "../images/spiritual.jpeg";
import educationProfilePicIMG from "../images/education.png";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const PersonButton = ({ ...props }) => (
  <Flex
    width={["72px", "72px", "72px", "90px"]}
    height={["72px", "72px", "72px", "90px"]}
    borderRadius={"50%"}
    overflow={"hidden"}
    bg={"#ffffff"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Flex
      width={["66px", "66px", "66px", "82px"]}
      height={["66px", "66px", "66px", "82px"]}
      borderRadius={"50%"}
      overflow={"hidden"}
    >
      <ImgStyled src={props.src} />
    </Flex>
  </Flex>
);

const TopFlexFit = styled.div`
  width: 1712px;
  height: 1980px;
  object-fit: cover;
`;

class BirdsEyeVillageAnnotatedComponentClass extends Component {
  _educationClick(event) {
    this.props.history.push("/sao-sebastian/education");
  }
  _spiritClick(event) {
    this.props.history.push("/sao-sebastian/spiritual");
  }
  _communityClick(event) {
    this.props.history.push("/sao-sebastian/community");
  }
  _peopleClick(event) {
    this.props.history.push("/sao-sebastian/people");
  }
  addClickEventListeners() {
    const educationButton = document.querySelector("#village-image-one");
    this.educationClickCallback = () => {
      this._educationClick();
    };
    educationButton.addEventListener("click", this.educationClickCallback);

    const spiritButton = document.querySelector("#village-image-two");
    this.spiritClickCallback = () => {
      this._spiritClick();
    };
    spiritButton.addEventListener("click", this.spiritClickCallback);

    const communityButton = document.querySelector("#village-image-three");
    this.communityClickCallback = () => {
      this._communityClick();
    };
    communityButton.addEventListener("click", this.communityClickCallback);

    const peopleButton = document.querySelector("#village-image-four");
    this.peopleClickCallback = () => {
      this._peopleClick();
    };
    peopleButton.addEventListener("click", this.peopleClickCallback);
  }
  removeClickEventListeners() {
    const educationButton = document.querySelector("#village-image-one");
    educationButton.removeEventListener("click", this._educationClickCalback);

    const spiritButton = document.querySelector("#village-image-two");
    spiritButton.removeEventListener("click", this._spiritClickCalback);

    const communityButton = document.querySelector("#village-image-three");
    communityButton.removeEventListener("click", this._communityClickCalback);

    const peopleButton = document.querySelector("#village-image-four");
    peopleButton.removeEventListener("click", this._peopleClickCalback);
  }
  componentDidMount() {
    this.addClickEventListeners();
  }
  componentWillUnmount() {
    this.removeClickEventListeners();
  }
  shouldComponentUpdate(nextProps, nextState) {
    // If we are about to switch to a new SVG remove the click event listeners
    if (
      nextProps.browser.greaterThan.medium !==
      this.props.browser.greaterThan.medium
    ) {
      this.removeClickEventListeners();
    }
  }
  componentDidUpdate(nextProps) {
    // If we just re-rendered after switchging to a diff SVG re-add click listeners
    if (
      nextProps.browser.greaterThan.medium !==
      this.props.browser.greaterThan.medium
    ) {
      this.addClickEventListeners();
    }
  }
  render() {
    return (
      <>
        {this.props.browser.greaterThan.medium ? (
          <BirdsEyeVillageAnnotatedComponentSVG
            style={{
              width: "100vw",
              height: "100vh",
              pointerEvents: "none"
            }}
          />
        ) : (
          <BirdsEyeVillageAnnotatedComponentMobileSVG
            style={{
              width: "100vw",
              height: "100vh",
              pointerEvents: "none"
            }}
          />
        )}
      </>
    );
  }
}
const BirdsEyeVillageAnnotatedComponent = connect(
  state => ({
    browser: state.browser
  }),
  null
)(withRouter(BirdsEyeVillageAnnotatedComponentClass));

export const saopeopleModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 3,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <PlayButton
          position={"absolute"}
          bottom={["17vh"]}
          clickHandler={() => console.log("navigate")}
        />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            LEARN MORE ABOUT THE
            <br />
            COMMUNITY'S HISTORY
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [
        <Text color={"white"}>
          Camilo lives in the small community of Sao Sebastian. People settled
          this area 30 years ago, in search of a deeper connection to nature,
          peace, and community.
        </Text>
      ],
      bottom: [
        <CoverImgResponsive
          desktopSrc={birdsEyeVillageIMG}
          mobileSrc={birdsEyeVillageMobileIMG}
        />
      ]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <Flex
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          pointerEvents={"none"}
        >
          <BirdsEyeVillageAnnotatedComponent />
        </Flex>
      ],
      middle: [
        <Text color={"white"}>
          Explore the Sao Sebastian
          <br />
          community
        </Text>
      ],
      bottom: [
        <CoverImgResponsive
          desktopSrc={birdsEyeVillageIMG}
          mobileSrc={birdsEyeVillageMobileIMG}
        />
      ]
    }
  ]
};
