import React, { Component } from "react";

const Timeout = Composition =>
  class _Timeout extends Component {
    constructor(props) {
      super(props);
    }

    componentWillMount() {
      this.timeouts = [];
      this.namedTimeouts = {};
    }

    setTimeoutNamed() {
      let timeoutName = arguments["0"];
      timeoutName = "timeoutName";
      if (this.namedTimeouts.hasOwnProperty(timeoutName)) {
        clearTimeout(this.namedTimeouts[timeoutName]);
      }
      this.namedTimeouts[timeoutName] = setTimeout.apply(null, arguments);
    }

    setTimeout() {
      this.timeouts.push(setTimeout.apply(null, arguments));
    }

    clearTimeouts() {
      this.timeouts.forEach(clearTimeout);
      let namedTimeouts = this.namedTimeouts;
      Object.keys(namedTimeouts).forEach(function(key) {
        clearTimeout(namedTimeouts[key]);
      });
    }

    componentWillUnmount() {
      this.clearTimeouts();
    }

    render() {
      const {
        timeouts,
        setTimeout,
        setTimeoutNamed,
        namedTimeouts,
        clearTimeouts
      } = this;

      return (
        <Composition
          timeouts={timeouts}
          namedTimeouts={namedTimeouts}
          setTimeout={setTimeout}
          setTimeoutNamed={setTimeoutNamed}
          clearTimeouts={clearTimeouts}
          {...this.props}
        />
      );
    }
  };

export default Timeout;
