import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg } from "./components";
import { PrimaryButton } from "./components";
import { PulseCircles } from "./components";
import { MissCacao } from "./missCacaoInteractive";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { modules } from "./index";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";
import { ReactComponent as FingerScrollSVG } from "../images/finger-scroll.svg";
import { ReactComponent as MouseScrollSVG } from "../images/mouse-scroll.svg";
import { ReactComponent as HeadphonesSVG } from "../images/headphones.svg";
import { ReactComponent as VRGlassesSVG } from "../images/vr-glasses.svg";
import { ID } from "../utils/id";

import { forceToNextSubModule } from "../actions/forceScrollPercent.js";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";
import { bindActionCreators } from "redux";

import { toggle as toggleAudio } from "../actions/audio";

import heroIMG from "../images/hero.jpeg";

const HeroTextStyled = styled(GTAmerica)`
  @media screen and (min-width: 768px) {
    text-shadow: 0px 0px 10px #000000;
  }
`;
export const HeroText = ({ ...props }) => (
  <HeroTextStyled
    color={"white"}
    fontSize={["21px", "28px", "28px", "36px"]}
    lineHeight={["27px", "39px", "39px", "50px"]}
    fontWeight={[400, 400, 400, 300]}
    fontStyle={props.fontStyle ? props.fontStyle : []}
    {...props}
  >
    {props.children}
  </HeroTextStyled>
);

const HeroNextButtonComponent = ({ ...props }) => (
  <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"} zIndex={5}>
    <Flex
      zIndex={5}
      width={["50px", "50px", "50px", "70px"]}
      height={["50px", "50px", "50px", "70px"]}
      borderRadius={["50%"]}
      bg={"#02DCA1"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}
      onClick={() => {
        //props.forceToNextLockin();
      }}
    >
      {props.browser.greaterThan.small ? (
        <Box width={["85%"]} height={["85%"]} pointerEvents={"none"}>
          <MouseScrollSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "#ffffff"
            }}
          />
        </Box>
      ) : (
        <Box width={["100%"]} height={["100%"]} pointerEvents={"none"}>
          <FingerScrollSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "#ffffff"
            }}
          />
        </Box>
      )}
    </Flex>
    <GTAmerica
      pt={"16px"}
      letterSpacing={"0.15em"}
      fontWeight={700}
      fontSize={["11px", "11px", "11px", "14px"]}
      lineHeight={["17px", "17px", "17px", "20px"]}
      color={"white"}
      textAlign={["center"]}
    >
      {props.browser.greaterThan.small ? <>{props.desktopText}</> : <>{props.mobileText}</>}
    </GTAmerica>
  </Flex>
);

export const HeroNextButton = connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch)
  })
)(HeroNextButtonComponent);

export const HeroLanding = ({ ...props }) => (
  <Flex justifyContent={"center"} flexDirection={"column"} width={["76.2%"]} pointerEvents="none" pb={"20px"}>
    <Flex justifyContent={"center"} position={"relative"} pointerEvents="none">
      <GTAmerica
        fontSize={["40px", "52px", "52px", "64px"]}
        color={"white"}
        textShadow={["0px 4px 4px rgba(0, 0, 0, 0.25);"]}
        textAlign={"center"}
        display={"inline-block"}
      >
        I am
        <br /> Wild Chocolate
        <AbsoluteDiv bottom={"-10px"} left={"1px"} width={"calc(100% - 2px)"} height={"3px"} bg={"#01C993"} />
      </GTAmerica>
    </Flex>
    <GTAmerica
      pt={["18px"]}
      fontSize={["21px", "28px", "28px", "36px"]}
      lineHeight={["27px", "39px", "39px", "50px"]}
      color={"white"}
      textShadow={["0px 0px 10px rgba(0, 0, 0, 1);"]}
      textAlign={"center"}
    >
      The rare, mythical cacao that grows wild in the heart of the Amazon.
    </GTAmerica>
  </Flex>
);

export const SecondHeroText = ({ ...props }) => (
  <HeroText
    px={["18px", "18px", "18px", "26.1%"]}
    pt={["65.5vh", "65.5vh", "65.5vh", "62.6vh"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["center", "center", "center", "center"]}
    pb={"20px"}
  >
    Join me on a journey home,
    <br /> from bar to bean.
  </HeroText>
);
export const ThirdHeroText = ({ ...props }) => (
  <HeroText
    px={["18px", "18px", "18px", "26.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "62.6vh"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "center"]}
  >
    I am wild cacao... wild by nature.
    <br /> Some say magical.
  </HeroText>
);
export const FourthHeroText = ({ ...props }) => (
  <Flex
    flexDirection={"column"}
    pt={["65.9vh", "65.9vh", "65.9vh", "62.7vh"]}
    width={"100%"}
    pointerEvents="none"
    justifyContent={"flex-end"}
    alignItems={"center"}
    pointerEvents="none"
  >
    <HeroText
      px={["18px", "18px", "18px", "26.1%"]}
      fontStyle={["normal", "normal", "normal", "normal"]}
      textAlign={["center"]}
      width={["76.5%"]}
    >
      For the most immersive experience, please enjoy this story with audio.
    </HeroText>
    <Flex alignItems={"center"} pt={["25px", "25px", "25px", "45px"]}>
      <VRGlassesSVG
        style={{
          width: "24px",
          fill: "#ffffff"
        }}
      />
      <GTAmerica
        pl={["5px", "5px", "5px", "12px"]}
        fontSize={["16px", "16px", "16px", "21px"]}
        fontStyle={"italic"}
        color={"white"}
      >
        Virtual Reality headset is optional
      </GTAmerica>
    </Flex>
  </Flex>
);

export const HeroHeadphoneButton = ({ ...props }) => (
  <Flex
    position={"absolute"}
    top={["54.5vh", "54.5vh", "54.5vh", "50.7vh"]}
    left={"0px"}
    right={"0px"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <PulseCircles smallSize={"60px"} largeSize={"80px"} />
    <ClickCursorFlex
      pointerEvents={"all"}
      zIndex={4}
      pointerEvents={"all"}
      onClick={() => {
        if (props.clickCallback) {
          props.clickCallback();
        }
      }}
    >
      <PrimaryButton>
        <HeadphonesSVG
          style={{
            paddingBottom: "4px",
            width: "100%",
            height: "100%",
            fill: "#ffffff"
          }}
        />
      </PrimaryButton>
    </ClickCursorFlex>
  </Flex>
);

export const FifthHeroText = ({ ...props }) => (
  <HeroText
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
  >
    I am wild cacao... wild by nature. Some say magical.
  </HeroText>
);
const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 35.36%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.51%, rgba(0, 0, 0, 0.6) 100%);
`;
export const heroModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 0,
  scenes: [
    {
      headerColor: "white",
      top_slide: [<HeroNextButton />],
      top_fade: [],
      middle: [<HeroLanding />],
      bottom: [<MissCacao />]
    }
  ]
};
