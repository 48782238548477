export const forceNewScrollPercent = percent => ({
  type: "FORCE_SCROLL_PERCENT",
  percent
});

export const forceToNextSubModule = (currentScrollPercent, modules_length) => ({
  type: "FORCE_TO_NEXT_MODULE",
  currentScrollPercent,
  modules_length
});

export const forceToNextLockin = () => ({
  type: "FORCE_TO_NEXT_LOCKIN"
});

export const forceToPreviousLockin = () => ({
  type: "FORCE_TO_PREVIOUS_LOCKIN"
});
