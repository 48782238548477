import React, { Component } from "react";
import { GTAmerica } from "../components/typography.js";
import { Flex } from "../components/layout.js";
import { connect } from "react-redux";
import { FullScreen } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import { AnimalText } from "./components";
import { withRouter } from "react-router-dom";
import { SecondaryNextButton } from "./animals";
import IFrameWithLoading from "../components/iframeWithLoading";
import { CoverImg, CoverImgResponsive } from "./components";
import educationOneLoading from "../images/educationOne.jpg";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin } from "gsap/all";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
`;
class SecondaryEducationStart extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.firstTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.secondaryScrollPercent.subModulePercent !== this.props.secondaryScrollPercent.subModulePercent ||
      nextProps.secondaryScrollPercent.moduleIndex !== this.props.secondaryScrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.secondaryScrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (
        this.props.secondaryScrollPercent.moduleIndex !== myIndex &&
        nextProps.secondaryScrollPercent.moduleIndex !== myIndex
      ) {
        return;
      }

      let isEntering =
        nextProps.secondaryScrollPercent.moduleIndex == myIndex &&
        (this.props.secondaryScrollPercent.moduleIndex < myIndex ||
          this.props.secondaryScrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.secondaryScrollPercent.moduleIndex == myIndex &&
        (nextProps.secondaryScrollPercent.moduleIndex > myIndex ||
          nextProps.secondaryScrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.secondaryScrollPercent.moduleIndex > nextProps.secondaryScrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.secondaryScrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.secondaryScrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.secondaryScrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    } else {
      let boundedScroll = Math.min(Math.max(nextProps.secondaryScrollPercent.subModulePercent, 0), 1);

      const myIndex = this.props.myIndex;
      if (
        this.props.secondaryScrollPercent.moduleIndex !== myIndex &&
        nextProps.secondaryScrollPercent.moduleIndex !== myIndex
      ) {
        return;
      }

      let endSubPercent = boundedScroll;

      this._updateTimelines(endSubPercent);
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  /*
        <IFrameWithLoading url="https://www.wild-cacao.org/educationOne/index.html" id="educationOne">
          <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={educationOneLoading} />
        </IFrameWithLoading>
   * */
  render() {
    return (
      <>
        {this.props.global.debug ? (
          <IFrameWithLoading url="http://localhost:8081/index.html" id="educationOne">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={educationOneLoading} />
          </IFrameWithLoading>
        ) : (
          <IFrameWithLoading url="https://wild-cacao.org/educationOne/index.html" id="educationOne">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={educationOneLoading} />
          </IFrameWithLoading>
        )}
        <Gradient />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <Flex
            position={"absolute"}
            top={"0px"}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            flexDirection={["column", "column", "column", "column"]}
            alignItems={"flex-start"}
            px={["18px", "18px", "18px", "5.1%"]}
          >
            <AnimalText px={["0px"]} color={["white", "white", "white", "white"]}>
              The local school goes up to the fourth grade. Classrooms are essentially bare, except for desks.
              Government funded teachers come and go, leaving the community with few options to educate their kids.
            </AnimalText>
          </Flex>
          <SecondaryNextButton isSecondary={true} mobileText={"Next Screen"} desktopText={"Next Screen"} />
        </FullScreen>
      </>
    );
  }
}

export default (SecondaryEducationStart = connect(
  state => ({
    global: state.global,
    secondaryScrollPercent: state.secondaryScrollPercent,
    browser: state.browser
  }),
  null
)(withRouter(SecondaryEducationStart)));
