import map from "lodash/map";

//import layer1 from "../images/misscacao/00.png";
//import layer2 from "../images/misscacao/01.png";
//import layer3 from "../images/misscacao/02.png";
//import layer4 from "../images/misscacao/03.png";
//import layer5 from "../images/misscacao/04.png";
//import layer6 from "../images/misscacao/05.png";
//import layer7 from "../images/misscacao/06.png";
//import layer8 from "../images/misscacao/07.png";
//import layer9 from "../images/misscacao/08.png";
//import layer10 from "../images/misscacao/09.png";
//import layer11 from "../images/misscacao/10.png";
//import layer12 from "../images/misscacao/11.png";
//import layer13 from "../images/misscacao/12.png";
//import layer14 from "../images/misscacao/13.png";
//import layer15 from "../images/misscacao/14.png";
//import layer16 from "../images/misscacao/15.png";
//import layer17 from "../images/misscacao/16.png";
//import layer18 from "../images/misscacao/17.png";
//import layer19 from "../images/misscacao/18.png";
//import layer20 from "../images/misscacao/19.png";
//import layer21 from "../images/misscacao/20.png";
//import layer22 from "../images/misscacao/21.png";
//import layer23 from "../images/misscacao/22.png";
//import layer24 from "../images/misscacao/23.png";
//import layer25 from "../images/misscacao/24.png";
//import layer26 from "../images/misscacao/25.png";
import base64ImageArray from "../images/misscacao-base64.json";

const LAYER_WIDTH_HQ = 3000;
const LAYER_HEIGHT_HQ = 2319;

export const LAYER_WIDTH = 1000;
export const LAYER_HEIGHT = 773;

export const layers = [
  {
    autoScale: false,
    classNames: "",
    height: 780,
    name: "BACKGROUND",
    width: 1248,
    x: -124,
    y: -3,
    zOffset: -800
  },
  {
    autoScale: false,
    classNames: "",
    height: 496,
    name: "Back_leaf_group",
    width: 760,
    x: 72,
    y: 222,
    zOffset: -600
  },
  {
    autoScale: false,
    classNames: "",
    height: 677,
    name: "tree",
    width: 479,
    x: 203,
    y: 0,
    zOffset: -500
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 677,
    name: "tree_glow",
    width: 479,
    x: 203,
    y: 0,
    zOffset: -500
  },
  {
    autoScale: false,
    classNames: "",
    height: 433,
    name: "Middle_Leaf_group_3",
    width: 892,
    x: 39,
    y: 298,
    zOffset: -350
  },
  {
    autoScale: false,
    classNames: "",
    height: 409,
    name: "Middle_Leaf_group_2",
    width: 462,
    x: 315,
    y: 332,
    zOffset: -300
  },
  {
    autoScale: false,
    classNames: "",
    height: 613,
    name: "bananeira",
    width: 395,
    x: 160,
    y: 10,
    zOffset: -250
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 613,
    name: "bananeira_glow",
    width: 395,
    x: 160,
    y: 10,
    zOffset: -250
  },
  {
    autoScale: false,
    classNames: "",
    height: 354,
    name: "Middle_Leaves_Group",
    width: 544,
    x: 220,
    y: 335,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 249,
    name: "Middle_Leaves_one_leaf_glow",
    width: 198,
    x: 560,
    y: 421,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 223,
    name: "leaf",
    width: 118,
    x: 676,
    y: 139,
    zOffset: 140
  },
  {
    autoScale: false,
    classNames: "",
    height: 409,
    name: "branch",
    width: 623,
    x: 219,
    y: 100,
    zOffset: 160
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 385,
    name: "branch_glow",
    width: 594,
    x: 241,
    y: 109,
    zOffset: 160
  },
  {
    autoScale: false,
    classNames: "",
    height: 173,
    name: "leaf-2",
    width: 174,
    x: 553,
    y: 115,
    zOffset: 170
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 490,
    name: "GLOW",
    width: 473,
    x: 275,
    y: 117,
    zOffset: 180
  },
  {
    autoScale: false,
    classNames: "",
    height: 378,
    name: "MISS",
    width: 356,
    x: 325,
    y: 211,
    zOffset: 210
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 336,
    name: "glow-2",
    width: 330,
    x: 338,
    y: 247.5,
    zOffset: 210
  },
  {
    autoScale: false,
    classNames: "",
    height: 206,
    name: "front_leaves_on_edges",
    width: 679,
    x: 165,
    y: 554,
    zOffset: 230
  },
  {
    autoScale: false,
    classNames: "",
    height: 188,
    name: "leaf-3",
    width: 155,
    x: 420,
    y: 444,
    zOffset: 250
  },
  {
    autoScale: false,
    classNames: "",
    height: 274,
    name: "leaf-4",
    width: 165,
    x: 454,
    y: 451,
    zOffset: 270
  },
  {
    autoScale: false,
    classNames: "",
    height: 213,
    name: "leaf-5",
    width: 124,
    x: 467,
    y: 499,
    zOffset: 290
  },
  {
    autoScale: false,
    classNames: "",
    height: 180,
    name: "monstera",
    width: 159,
    x: 312,
    y: 476,
    zOffset: 310
  },
  {
    autoScale: false,
    classNames: "",
    height: 164,
    name: "monstera-2",
    width: 149,
    x: 404,
    y: 532,
    zOffset: 330
  },
  {
    autoScale: false,
    classNames: "",
    height: 126,
    name: "plant",
    width: 64,
    x: 600,
    y: 542,
    zOffset: 350
  },
  {
    autoScale: false,
    classNames: "",
    height: 143,
    name: "plant-2",
    width: 100,
    x: 350,
    y: 531,
    zOffset: 370
  },
  {
    autoScale: false,
    classNames: "",
    height: 167,
    name: "leaf-6",
    width: 196,
    x: 449,
    y: 605,
    zOffset: 390
  }
].map(function(e, i) {
  let l = base64ImageArray.length;
  e["image"] = base64ImageArray[l - 1 - i]["base64"];
  e["imagename"] = base64ImageArray[l - 1 - i]["imagepath"];
  e["parentName"] = "";
  return e;
});
