import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import scrollPercent from "./scrollPercent";
import mouseXY from "./mouseXY";
import secondaryScrollPercent from "./secondaryScrollPercent";
import shareMenu from "./shareMenu";
import eventSupportHas from "./eventSupport";
import menu from "./menu";
import deltas from "./deltas";
import motion from "./motion";
import sponsors from "./sponsors";
import credits from "./credits";
import audio from "./audio";
import datasource from "./datasource";
import iframeLoading from "./iframeLoading";
import header from "./header";
import global from "./global";
import forceScrollPercent from "./forceScrollPercent";
import forceSecondaryScrollPercent from "./forceSecondaryScrollPercent";
import * as breakpoints from "../utils/breakpoints.js";

import { createResponsiveStateReducer } from "redux-responsive";

export default combineReducers({
  secondaryScrollPercent,
  scrollPercent,
  eventSupportHas,
  forceScrollPercent,
  forceSecondaryScrollPercent,
  shareMenu,
  datasource,
  iframeLoading,
  global,
  header,
  mouseXY,
  deltas,
  motion,
  menu,
  sponsors,
  credits,
  audio,
  browser: createResponsiveStateReducer(
    {
      extraSmall: breakpoints.WIDTH_0,
      small: breakpoints.WIDTH_1,
      medium: breakpoints.WIDTH_2,
      large: breakpoints.WIDTH_3
    },
    {
      extraFields: () => ({
        width: window.innerWidth,
        height: window.innerHeight,
        isLandscape: window.isLandscape
      })
    }
  ),
  router: routerReducer
});
