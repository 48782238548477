import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { GTAmerica } from "../components/typography.js";
import { bindActionCreators } from "redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { hide as hideShareMenu } from "../actions/shareMenu";
import { hide as hideMenu } from "../actions/menu";
import { Route } from "react-router-dom";
import Header from "../components/header";
import { AudioButton } from "../components/audioButton";
import { CloseButton } from "../components/closeButton";
import { CoverImg, CoverImgStyled } from "../modules/components";
import { ClickCursorFlex, Flex, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { ModuleManager } from "../components/moduleManager.js";
import HorizontalScrollBar from "../components/horizontalScrollBar.js";
import Menu from "../components/menu.js";
import store, { history } from "../store";
import { basePathname, baseRouter } from "../utils/routes";

import cacaoBasketIMG from "../images/cacao-basket.jpg";
import cacaoBoatCarryIMG from "../images/cacao-boat-carry.jpg";
import cacaoBoatIMG from "../images/cacao-boat.jpg";
import cacaoCarryIMG from "../images/cacao-carry.jpg";
import cacaoDryingIMG from "../images/cacao-drying.jpg";
import cacaoGroupIMG from "../images/cacao-group.jpg";
import cacaoPluckIMG from "../images/cacao-pluck.jpg";

import camilaPeopleIMG from "../images/community-Camila.jpg";
import camilloPortraitGalleryIMG from "../images/community-Camilo.jpg";
import franciscaGalleryIMG from "../images/community-Francisca.jpg";
import mariaGalleryIMG from "../images/community-MariaDeJesus.jpg";
import stickmanGalleryIMG from "../images/community-Joao.jpg";

import jaguarIMG from "../images/largecat-shrunk.jpg";
import antEaterIMG from "../images/anteater-cropped.jpg";
import pygmyIMG from "../images/pygmy.jpg";
import brazilNutTreeIMG from "../images/largetree-cropped.jpg";

import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";

import { Provider } from "react-redux";

import map from "lodash/map";
import filter from "lodash/filter";

const GalleryGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.22%, rgba(0, 0, 0, 0.56) 100%);
`;

const DryingGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.5%, #000000 100%);
`;
export const animalsPhotoGallery = map(
  [
    {
      img: <CoverImg objectPositionX={"70%"} src={jaguarIMG} />,
      text: "Jaguar",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg objectPositionX={"40%"} src={antEaterIMG} />,
      text: "Silky anteater",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg objectPositionX={"51.5%"} src={pygmyIMG} />,
      text: "Pygmy marmoset",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={brazilNutTreeIMG} />,
      text: "Brazil nut tree",

      gradient: <GalleryGradient />
    }
  ],
  (object, index) => {
    return Object.assign({}, { index: index }, { ...object });
  }
);

export const harvestPhotoGallery = map(
  [
    {
      img: <CoverImg src={cacaoPluckIMG} objectPositionY={"10%"} objectPositionX={"45%"} />,
      text: "Cacao hunters travel small trails to find ripe pods hidden in the forest.",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoCarryIMG} objectPositionY={"10%"} objectPositionX={"20%"} />,
      text: "The hunters load up the cacao on their backs to bring back to the boats.",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoGroupIMG} objectPositionX={"15%"} />,
      text: "Machetes are used to open the pods and scoop out the slippery white beans.",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoBoatIMG} objectPositionX={"30%"} />,
      text: "Everyone in the community is present for the harvest, even the little ones!",

      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoBoatCarryIMG} objectPositionX={"35%"} />,
      text: "The unfermented beans are then carried to the fermentation facilities.",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoBasketIMG} objectPositionX={"10%"} />,
      text: "The beans are poured into boxes so they can be fermented for 5-7 days.",
      gradient: <GalleryGradient />
    },
    {
      img: <CoverImg src={cacaoDryingIMG} />,
      text: "Finally, the beans are spread out on platforms to be dried for 3-5 days.",
      gradient: <DryingGradient />
    }
  ],
  (object, index) => {
    return Object.assign({}, { index: index }, { ...object });
  }
);

const PeopleGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 56.12%, rgba(0, 0, 0, 0.5) 100%);
`;

export const ProfileCoverImgStyled = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 50% 20%;
`;
export const ProfileCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <ProfileCoverImgStyled src={props.src} />
  </Flex>
);

export const peoplePhotoGallery = map(
  [
    {
      img: <ProfileCoverImg src={camilloPortraitGalleryIMG} />,
      name: "Camilo",
      text: "Director of Production of the Coop",
      gradient: <PeopleGradient />
    },
    {
      img: <CoverImg objectPositionX={"60%"} src={camilaPeopleIMG} />,
      name: "Camila",
      text: "Camilo’s daughter",
      gradient: <PeopleGradient />
    },
    {
      img: <CoverImg src={stickmanGalleryIMG} />,
      name: "Joao",
      text: "Camilo’s neighbor",

      gradient: <PeopleGradient />
    },
    {
      img: <CoverImg objectPositionX={"25%"} src={franciscaGalleryIMG} />,
      name: "Francisca",
      text: "Community member",
      gradient: <PeopleGradient />
    },
    {
      img: <CoverImg src={mariaGalleryIMG} objectPositionY={"0%"} />,
      text: "Francisca’s sister",
      name: "Maria de Jesus",
      gradient: <PeopleGradient />
    }
  ],
  (object, index) => {
    return Object.assign({}, { index: index }, { ...object });
  }
);

const BOTTOM_TRANSITION_TIMEOUT = 300;
const MIDDLE_TRANSITION_TIMEOUT = 600;

const Bottom = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => (props.zIndex ? props.zIndex : 0)};
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : "auto")};
`;

class PhotoGallery extends Component {
  state = {
    count: 10,
    index: 0,
    directionLeft: false,
    directionRight: true
  };
  render() {
    const { photos } = this.props;
    let photoComponentsMapped = map(photos, photoObject =>
      Object.assign(
        {},
        {
          zIndex: this.state.directionRight ? photoObject.index + 10 : this.props.photos.length - photoObject.index + 10
        },
        { ...photoObject }
      )
    );
    let photoComponents = filter(photoComponentsMapped, photoObject => {
      return photoObject.index === this.state.index;
    });
    return (
      <Flex
        pointerEvents={"auto"}
        height={"100%"}
        width={"100%"}
        bg={"rgba(0, 0, 0, 0.8)"}
        id="photo-gallery"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex
          height={["100%", "100%", "100%", "78.4vh"]}
          width={["100%", "100%", "100%", "75vw"]}
          flexDirection={"column"}
          position={"relative"}
        >
          <Flex width={"100%"} height={["100%", "100%", "100%", "calc(100% - 90px)"]} position={"relative"}>
            <TransitionGroup component={null} exit={true}>
              {map(photoComponents, photoObject => (
                <CSSTransition
                  timeout={{
                    enter: BOTTOM_TRANSITION_TIMEOUT * 2,
                    exit: BOTTOM_TRANSITION_TIMEOUT * 2
                  }}
                  className="mm-bottom"
                  classNames="fade"
                  key={"key" + photoObject.index}
                  onEntering={() => {
                    console.log("entering bottom ");
                    console.log("\n");
                    //debugger;
                  }}
                  onEntered={() => {
                    console.log("entered bottom ");
                    console.log("\n");
                    //debugger;
                  }}
                  onExiting={() => {
                    console.log("exiting bottom ");
                    console.log("\n");
                    //debugger;
                  }}
                  onExited={() => {
                    console.log("exiting bottom ");
                    console.log("\n");
                    //debugger;
                  }}
                >
                  <Bottom zIndex={this.state.count}>
                    {photoObject.img}
                    {photoObject.gradient !== null ? photoObject.gradient : null}
                  </Bottom>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Flex>

          <Flex
            display={["flex", "flex", "flex", "none"]}
            top={"0px"}
            left={"20px"}
            right={"20px"}
            bottom={"70px"}
            position={"absolute"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            zIndex={600}
          >
            <GTAmerica display={["block"]} fontSize={["16px"]} lineHeight={["normal"]} color={"white"}>
              {this.props.photos[this.state.index].text}
            </GTAmerica>
          </Flex>
          <Flex
            position={["absolute", "absolute", "absolute", "relative"]}
            width={"100%"}
            bottom={"0px"}
            left={"0px"}
            right={"0px"}
            height={["70px", "70px", "70px", "90px"]}
            alignItems={"center"}
            zIndex={50}
          >
            <Flex
              width={["calc(100% - 263px)", "calc(100% - 263px)", "calc(100% - 263px)", "calc(100% - 268px)"]}
              height={"100%"}
              alignItems={"center"}
            >
              {this.props.photos[this.state.index].name ? (
                <GTAmerica
                  display={["none", "none", "none", "block"]}
                  fontWeight={"bold"}
                  fontSize={["22px"]}
                  lineHeight={["27px"]}
                  color={"white"}
                >
                  {this.props.photos[this.state.index].name}
                  {" /"}
                  &nbsp;
                </GTAmerica>
              ) : null}
              <GTAmerica
                display={["none", "none", "none", "block"]}
                fontSize={["22px"]}
                lineHeight={["27px"]}
                color={"white"}
              >
                {this.props.photos[this.state.index].text}
              </GTAmerica>
            </Flex>
            <Flex height={"100%"} alignItems={"center"} flex={"1000 1 auto"}>
              <GTAmerica fontSize={["16px"]} lineHeight={["22px"]} color={"white"} textAlign={"right"} width={"100%"}>
                Image {this.state.index + 1} of {this.props.photos.length}
              </GTAmerica>
            </Flex>
            <ClickCursorFlex
              ml={["15px"]}
              position={"relative"}
              justifyContent={"center"}
              alignItems={"center"}
              width={["40px", "40px", "40px", "50px"]}
              height={["40px", "40px", "40px", "50px"]}
              borderRadius={"50%"}
              bg={"white"}
              pointerEvents={"auto"}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  index: Math.max(this.state.index - 1, 0),
                  directionRight: false,
                  directionLeft: true,
                  count: this.state.count + 1
                });
              }}
            >
              <DownArrowSVG
                style={{
                  transform: "rotate(90deg)",
                  width: "100%",
                  fill: "#000000"
                }}
              />
            </ClickCursorFlex>
            <ClickCursorFlex
              ml={["15px"]}
              mr={["15px", "15px", "15px", "0px"]}
              position={"relative"}
              justifyContent={"center"}
              alignItems={"center"}
              width={["40px", "40px", "40px", "50px"]}
              height={["40px", "40px", "40px", "50px"]}
              borderRadius={"50%"}
              bg={"white"}
              pointerEvents={"auto"}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  index: Math.min(this.state.index + 1, this.props.photos.length - 1),
                  directionRight: true,
                  directionLeft: false,
                  count: this.state.count + 1
                });
              }}
            >
              <DownArrowSVG
                style={{
                  transform: "rotate(270deg)",
                  width: "100%",
                  fill: "#000000"
                }}
              />
            </ClickCursorFlex>
          </Flex>
          <Flex
            position={"absolute"}
            right={["15px", "15px", "15px", "20px"]}
            top={["15px", "15px", "15px", "20px"]}
            zIndex={10000}
          >
            <CloseButton
              onClick={e => {
                e.stopPropagation();
                this.props.history.push(basePathname(this.props.location.pathname));
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default withRouter(
  connect(
    null,
    null
  )(PhotoGallery)
);
