export const forceNewSecondaryScrollPercent = percent => ({
  type: "FORCE_SECONDARY_SCROLL_PERCENT",
  percent
});

export const forceToNextSecondarySubModule = (currentScrollPercent, modules_length) => ({
  type: "FORCE_TO_NEXT_SECONDARY_MODULE",
  currentScrollPercent,
  modules_length
});

export const forceToSecondaryNextLockin = () => ({
  type: "FORCE_SECONDARY_TO_NEXT_LOCKIN"
});

export const forceToSecondaryPreviousLockin = () => ({
  type: "FORCE_SECONDARY_TO_PREVIOUS_LOCKIN"
});
