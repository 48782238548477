import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { Flex, Box, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { chapters } from "../modules/chapters";
import { GTAmerica } from "../components/typography.js";
import { CSSTransition } from "react-transition-group";
import { TimelineMax, CSSPlugin, AttrPlugin, TweenLite } from "gsap/all";
import Timeout from "../utils/timeout";
import debounce from "lodash/debounce";

import styled from "styled-components";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];
const totalYOffsetForScrollTitle = 20;
const approximateHeightOfChapterBubble = 20;

const ChapterAbsoluteDiv = styled(AbsoluteDiv)`
  pointer-events: none;
`;

const RightTriangleHack = styled(AbsoluteDiv)`
  transform: translateY(-50%);
  height: 0;
  width: 0;
  border-top: ${props => props.size}px solid transparent;
  border-bottom: ${props => props.size}px solid transparent;
  border-left: ${props => props.size}px solid white;
`;

const SCROLLBAR_CHAPTER_TRANSITION_TIMEOUT = 2000;
class ScrollBar extends Component {
  state = { showChapter: false };
  _chapterScrollTitle() {
    return (
      chapters[this.props.scrollPercent.chapterNumber].scrollTitle +
      " " +
      chapters[this.props.scrollPercent.chapterNumber].title
    );
  }
  constructor(props) {
    super(props);
    this.setTimeoutNamedScrollDebounced = debounce(this.setTimeoutNamedScroll, 500);
    // Setup bg scaling & scrolling timeline
    this.barInnerRef = React.createRef();
    this.transformTimeline = null;
  }
  componentDidMount() {
    this.transformSetup();
  }
  transformSetup() {
    TweenLite.to(this.barInnerRef, 1, {
      y: "-99%"
    });
  }
  setTimeoutNamedScroll() {
    this.props.setTimeoutNamed(
      () => {
        this.setState({ showChapter: false });
      },
      SCROLLBAR_CHAPTER_TRANSITION_TIMEOUT,
      "scroll"
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollPercent.chapterNumber !== this.props.scrollPercent.chapterNumber) {
      //Perform some operation
      this.setState({ showChapter: true });
      this.props.setTimeout(() => {
        this.setState({ showChapter: false });
      }, SCROLLBAR_CHAPTER_TRANSITION_TIMEOUT);

      //this.props.history.push( "/" + chapters[nextProps.scrollPercent.chapterNumber].url);
    } else if (
      nextProps.scrollPercent.percent !== this.props.scrollPercent.percent &&
      this.props.browser.greaterThan.medium
    ) {
      this.setState({ showChapter: true });
      this.setTimeoutNamedScrollDebounced();
    }

    if (nextProps.scrollPercent.percent !== this.props.scrollPercent.percent) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.percent, 0), 1);
      TweenLite.to(this.barInnerRef, 1, {
        y: boundedScroll * 100 - 100 + "%"
      });
    }
  }
  render() {
    return (
      <FixedDiv
        zIndex={this.props.zIndex ? this.props.zIndex : 10000}
        right={"0px"}
        width={["5px", "8px"]}
        top={["0px"]}
        bottom={["0px"]}
        innerRef={div => (this.barInnerRef = div)}
      >
        <AbsoluteDiv height={"100%"} width={"100%"} bg={"#02DCA1"} />
        <CSSTransition
          timeout={SCROLLBAR_CHAPTER_TRANSITION_TIMEOUT}
          className="element"
          classNames="fade"
          in={this.state.showChapter}
        >
          <ChapterAbsoluteDiv
            bottom={
              this.props.scrollPercent.percent > 0.9
                ? this.props.scrollPercent.percent * totalYOffsetForScrollTitle -
                  approximateHeightOfChapterBubble / 2 +
                  0 +
                  "px"
                : (-1 * approximateHeightOfChapterBubble + 2) / 2 + "px"
            }
            left={"-275px"}
            right={"13px"}
          >
            <Flex width={"100%"} pointerEvents="none" justifyContent={"flex-end"}>
              <Box bg={"white"} pointerEvents="none">
                <GTAmerica
                  color={"#02DCA1"}
                  lineHeight={1}
                  fontSize={"12px"}
                  px={"6px"}
                  pt={"4px"}
                  pb={"6px"}
                  pointerEvents="none"
                >
                  {this._chapterScrollTitle()}{" "}
                </GTAmerica>
              </Box>
              <RightTriangleHack size={4} right={"-4px"} top={"50%"} />
            </Flex>
          </ChapterAbsoluteDiv>
        </CSSTransition>
      </FixedDiv>
    );
  }
}

export default connect(
  state => ({
    browser: state.browser,
    scrollPercent: state.scrollPercent
  }),
  null
)(withRouter(Timeout(ScrollBar)));
