import map from "lodash/map";

import layer1 from "../images/misscacaoday/00.png";
import layer2 from "../images/misscacaoday/01.png";
import layer3 from "../images/misscacaoday/02.png";
import layer4 from "../images/misscacaoday/03.png";
import layer5 from "../images/misscacaoday/04.png";
import layer6 from "../images/misscacaoday/05.png";
import layer7 from "../images/misscacaoday/06.png";
import layer8 from "../images/misscacaoday/07.png";
import layer9 from "../images/misscacaoday/08.png";
import layer10 from "../images/misscacaoday/09.png";
import layer11 from "../images/misscacaoday/10.png";
import layer12 from "../images/misscacaoday/11.png";
import layer13 from "../images/misscacaoday/12.png";
import layer14 from "../images/misscacaoday/13.png";
import layer15 from "../images/misscacaoday/14.png";
import layer16 from "../images/misscacaoday/15.png";
import layer17 from "../images/misscacaoday/16.png";
import layer18 from "../images/misscacaoday/17.png";
import layer19 from "../images/misscacaoday/18.png";
import layer20 from "../images/misscacaoday/19.png";
import layer21 from "../images/misscacaoday/20.png";
import layer22 from "../images/misscacaoday/21.png";
import layer23 from "../images/misscacaoday/22.png";
import layer24 from "../images/misscacaoday/23.png";
import layer25 from "../images/misscacaoday/24.png";
import layer26 from "../images/misscacaoday/25.png";
import layer27 from "../images/misscacaoday/26.png";
import layer28 from "../images/misscacaoday/27.png";
import layer29 from "../images/misscacaoday/28.png";
import layer30 from "../images/misscacaoday/29.png";
import layer31 from "../images/misscacaoday/30.png";
import layer32 from "../images/misscacaoday/31.png";
import layer33 from "../images/misscacaoday/32.png";
import layer34 from "../images/misscacaoday/33.png";
import layer35 from "../images/misscacaoday/34.png";
import layer36 from "../images/misscacaoday/35.png";
import layer37 from "../images/misscacaoday/36.png";
import layer38 from "../images/misscacaoday/37.png";
import layer39 from "../images/misscacaoday/38.png";
import layer40 from "../images/misscacaoday/39.png";
import layer41 from "../images/misscacaoday/40.png";
import layer42 from "../images/misscacaoday/41.png";
import layer43 from "../images/misscacaoday/42.png";
import layer44 from "../images/misscacaoday/43.png";
import layer45 from "../images/misscacaoday/44.png";
import layer46 from "../images/misscacaoday/45.png";
import layer47 from "../images/misscacaoday/46.png";
import layer48 from "../images/misscacaoday/47.png";
import layer49 from "../images/misscacaoday/48.png";
import layer50 from "../images/misscacaoday/49.png";
import layer51 from "../images/misscacaoday/50.png";
import layer52 from "../images/misscacaoday/51.png";
import layer53 from "../images/misscacaoday/52.png";
import layer54 from "../images/misscacaoday/53.png";
import layer55 from "../images/misscacaoday/54.png";
import layer56 from "../images/misscacaoday/55.png";

const LAYER_WIDTH_HQ = 3000;
const LAYER_HEIGHT_HQ = 2319;

export const LAYER_WIDTH = 1000;
export const LAYER_HEIGHT = 746;

//Adjust tree in BG manually, And adjust HQ up a few pixels on vines and glow
//
export const layers = [
  {
    autoScale: false,
    classNames: "",
    height: 790,
    image: layer56,
    name: "BACKGROUND",
    width: 1264,
    x: -132,
    y: -31,
    zOffset: -400
  },
  {
    autoScale: false,
    classNames: "",
    height: 290,
    image: layer55,
    name: "plant",
    width: 229,
    x: 630,
    y: 363,
    zOffset: -390
  },
  {
    autoScale: false,
    classNames: "",
    height: 282,
    image: layer54,
    name: "plant-2",
    width: 260,
    x: 393,
    y: 205,
    zOffset: -380
  },
  {
    autoScale: false,
    classNames: "",
    height: 199,
    image: layer53,
    name: "leaf",
    width: 164,
    x: 682,
    y: 445,
    zOffset: -370
  },
  {
    autoScale: false,
    classNames: "",
    height: 282,
    image: layer52,
    name: "plant-3",
    width: 260,
    x: 572,
    y: 284,
    zOffset: -360
  },
  {
    autoScale: false,
    classNames: "",
    height: 282,
    image: layer51,
    name: "plant-4",
    width: 259,
    x: 210,
    y: 263,
    zOffset: -350
  },
  {
    autoScale: false,
    classNames: "",
    height: 264,
    image: layer50,
    name: "plant-5",
    width: 235,
    x: 72,
    y: 438,
    zOffset: -340
  },
  {
    autoScale: false,
    classNames: "",
    height: 263,
    image: layer49,
    name: "leaf-2",
    width: 163,
    x: 538,
    y: 257,
    zOffset: -330
  },
  {
    autoScale: false,
    classNames: "",
    height: 196,
    image: layer48,
    name: "leaf-3",
    width: 205,
    x: 96,
    y: 518,
    zOffset: -320
  },
  {
    autoScale: false,
    classNames: "",
    height: 625,
    image: layer47,
    name: "TREE",
    width: 468,
    x: 212,
    y: -100,
    zOffset: -300
  },
  {
    autoScale: false,
    classNames: "",
    height: 282,
    image: layer46,
    name: "plant-6",
    width: 259,
    x: 450,
    y: 281,
    zOffset: -250
  },
  {
    autoScale: false,
    classNames: "",
    height: 226,
    image: layer45,
    name: "leaf-4",
    width: 133,
    x: 611,
    y: 377,
    zOffset: -240
  },
  {
    autoScale: false,
    classNames: "",
    height: 317,
    image: layer44,
    name: "plant-7",
    width: 242,
    x: 142,
    y: 389,
    zOffset: -230
  },
  {
    autoScale: false,
    classNames: "",
    height: 208,
    image: layer43,
    name: "leaf-5",
    width: 157,
    x: 518,
    y: 348,
    zOffset: -220
  },
  {
    autoScale: false,
    classNames: "",
    height: 190,
    image: layer42,
    name: "leaf-6",
    width: 153,
    x: 507,
    y: 315,
    zOffset: -210
  },
  {
    autoScale: false,
    classNames: "",
    height: 271,
    image: layer41,
    name: "leaf-7",
    width: 167,
    x: 315,
    y: 346,
    zOffset: -200
  },
  {
    autoScale: false,
    classNames: "",
    height: 236,
    image: layer40,
    name: "leaf-8",
    width: 171,
    x: 608,
    y: 455,
    zOffset: -190
  },
  {
    autoScale: false,
    classNames: "",
    height: 613,
    image: layer39,
    name: "bananeira",
    width: 396,
    x: 159,
    y: 0,
    zOffset: -150
  },
  {
    autoScale: false,
    classNames: "",
    height: 262,
    image: layer38,
    name: "plant-8",
    width: 212,
    x: 557,
    y: 404,
    zOffset: -145
  },
  {
    autoScale: false,
    classNames: "",
    height: 280,
    image: layer37,
    name: "plant-9",
    width: 231,
    x: 220,
    y: 319,
    zOffset: -140
  },
  {
    autoScale: false,
    classNames: "",
    height: 335,
    image: layer36,
    name: "branch",
    width: 617,
    x: 241,
    y: 93,
    zOffset: -120
  },
  {
    autoScale: false,
    classNames: "",
    height: 165,
    image: layer35,
    name: "plant-10",
    width: 118,
    x: 281,
    y: 489,
    zOffset: -110
  },
  {
    autoScale: false,
    classNames: "",
    height: 86,
    image: layer34,
    name: "cacao",
    width: 44,
    x: 638,
    y: 275,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 76,
    image: layer33,
    name: "cacao-2",
    width: 40,
    x: 566,
    y: 215,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 74,
    image: layer32,
    name: "cacao-3",
    width: 47,
    x: 386,
    y: 126,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 71,
    image: layer31,
    name: "cacao-4",
    width: 40,
    x: 443,
    y: 124,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 72,
    image: layer30,
    name: "cacao-5",
    width: 40,
    x: 707,
    y: 318,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 411,
    image: layer29,
    name: "glow",
    width: 266,
    x: 379,
    y: 200,
    zOffset: -80
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 381,
    image: layer28,
    name: "rays",
    width: 408,
    x: 320,
    y: 161,
    zOffset: -70
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 267,
    image: layer27,
    name: "sparkles",
    width: 391,
    x: 315,
    y: 196,
    zOffset: -60
  },
  {
    autoScale: false,
    classNames: "",
    height: 359,
    image: layer26,
    name: "MISS_CACAO",
    width: 362,
    x: 322,
    y: 227.5,
    zOffset: -20
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 327,
    image: layer25,
    name: "GLOW-2",
    width: 330,
    x: 338,
    y: 237,
    zOffset: -20
  },
  {
    autoScale: false,
    classNames: "",
    height: 307,
    image: layer24,
    name: "VINES",
    width: 116,
    x: 446,
    y: 185.5,
    zOffset: -19.9
  },
  {
    autoScale: false,
    classNames: "",
    height: 48,
    image: layer23,
    name: "CACAO_on_hands",
    width: 331,
    x: 337,
    y: 374,
    zOffset: -20
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 188,
    image: layer22,
    name: "SPARKLES-2",
    width: 455,
    x: 283,
    y: 281,
    zOffset: 30
  },
  {
    autoScale: false,
    classNames: "",
    height: 180,
    image: layer21,
    name: "leaf-9",
    width: 149,
    x: 400,
    y: 429,
    zOffset: 40
  },
  {
    autoScale: false,
    classNames: "",
    height: 273,
    image: layer20,
    name: "leaf-10",
    width: 165,
    x: 454,
    y: 435,
    zOffset: 45
  },
  {
    autoScale: false,
    classNames: "",
    height: 213,
    image: layer19,
    name: "leaf_pink",
    width: 123,
    x: 468,
    y: 484,
    zOffset: 50
  },
  {
    autoScale: false,
    classNames: "",
    height: 175,
    image: layer18,
    name: "monstera",
    width: 158,
    x: 329,
    y: 446,
    zOffset: 55
  },
  {
    autoScale: false,
    classNames: "",
    height: 164,
    image: layer17,
    name: "monstera-2",
    width: 149,
    x: 386,
    y: 481,
    zOffset: 60
  },
  {
    autoScale: false,
    classNames: "",
    height: 200,
    image: layer16,
    name: "leaf-11",
    width: 165,
    x: 705,
    y: 546,
    zOffset: 70
  },
  {
    autoScale: false,
    classNames: "",
    height: 210,
    image: layer15,
    name: "branch-2",
    width: 511,
    x: 187,
    y: 428,
    zOffset: 75
  },
  {
    autoScale: false,
    classNames: "",
    height: 45,
    image: layer14,
    name: "",
    width: 147,
    x: 357,
    y: 549,
    zOffset: 80
  },
  {
    autoScale: false,
    classNames: "",
    height: 118,
    image: layer13,
    name: "chameleon",
    width: 141,
    x: 345,
    y: 516,
    zOffset: 85
  },
  {
    autoScale: false,
    classNames: "",
    height: 240,
    image: layer12,
    name: "arara",
    width: 111,
    x: 541,
    y: 123,
    zOffset: -40
  },
  {
    autoScale: false,
    classNames: "",
    height: 38,
    image: layer11,
    name: "butterfly",
    width: 28,
    x: 428,
    y: 340,
    zOffset: 200
  },
  {
    autoScale: false,
    classNames: "",
    height: 27,
    image: layer10,
    name: "butterfly-2",
    width: 32,
    x: 635,
    y: 479,
    zOffset: 250
  },
  {
    autoScale: false,
    classNames: "",
    height: 141,
    image: layer9,
    name: "monstera-3",
    width: 127,
    x: 626,
    y: 562,
    zOffset: 105
  },
  {
    autoScale: false,
    classNames: "",
    height: 123,
    image: layer8,
    name: "plant-11",
    width: 65,
    x: 598,
    y: 528,
    zOffset: 110
  },
  {
    autoScale: false,
    classNames: "",
    height: 114,
    image: layer7,
    name: "plant-12",
    width: 79,
    x: 429,
    y: 557,
    zOffset: 115
  },
  {
    autoScale: false,
    classNames: "",
    height: 84,
    image: layer6,
    name: "plant-13",
    width: 83,
    x: 709,
    y: 585,
    zOffset: 120
  },
  {
    autoScale: false,
    classNames: "",
    height: 91,
    image: layer5,
    name: "plant-14",
    width: 77,
    x: 165,
    y: 577,
    zOffset: 125
  },
  {
    autoScale: false,
    classNames: "",
    height: 124,
    image: layer4,
    name: "leaf-12",
    width: 117,
    x: 312,
    y: 526,
    zOffset: 130
  },
  {
    autoScale: false,
    classNames: "",
    height: 167,
    image: layer3,
    name: "leaf-13",
    width: 196,
    x: 440,
    y: 575,
    zOffset: 135
  },
  {
    autoScale: false,
    classNames: "",
    height: 194,
    image: layer2,
    name: "leaf-14",
    width: 170,
    x: 182,
    y: 529,
    zOffset: 140
  },
  {
    autoScale: false,
    classNames: "",
    height: 108,
    image: layer1,
    name: "plant-15",
    width: 61,
    x: 322,
    y: 551,
    zOffset: 145
  }
];
