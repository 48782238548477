import React, { Component } from "react";
import { GTAmerica } from "../components/typography.js";
import { Flex } from "../components/layout.js";
import { connect } from "react-redux";
import { FullScreen } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import { AnimalText } from "./components";
import deforestationIMG from "../images/deforestation.png";
import { PulseCircles } from "./components";
import { FixedDiv, AbsoluteDiv, Box, ClickCursorFlex } from "../components/layout.js";
import { forceToSecondaryNextLockin } from "../actions/forceSecondaryScrollPercent.js";
import { bindActionCreators } from "redux";
import { WhitePrimarySmallButton } from "./components";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";
import Iframe from "react-iframe";
import { withRouter } from "react-router-dom";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin } from "gsap/all";
import IFrameWithLoading from "../components/iframeWithLoading";
import { CoverImg, CoverImgResponsive } from "./components";
import spiritualLastLoading from "../images/spiritualLast.jpg";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
`;
const SecondaryNextButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Flex
      position={"absolute"}
      top={["-40px", "-50px", "-50px", "-50px"]}
      left={"-100px"}
      right={"-100px"}
      pointerEvents={"none"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GTAmerica
        fontSize={["16px", "21px", "21px", "21px"]}
        lineHeight={["21px", "27px", "27px", "27px"]}
        color={"white"}
        textAlign={"center"}
      >
        Back to Community Map
      </GTAmerica>
    </Flex>
    <ClickCursorFlex
      pointerEvents={"auto"}
      onClick={e => {
        e.stopPropagation();
        props.history.push("/saosebastiao");
      }}
    >
      <PulseCircles smallSize={"40px"} largeSize={"60px"} />
      <WhitePrimarySmallButton>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={["9px", "9px", "9px", "12px"]}
          height={["13px", "13px", "13px", "22px"]}
        >
          <DownArrowOldSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "black"
            }}
          />
        </Flex>
      </WhitePrimarySmallButton>
    </ClickCursorFlex>
  </Flex>
);

export const SecondaryNextButton = connect(
  null,
  dispatch => ({
    forceToSecondaryNextLockin: bindActionCreators(forceToSecondaryNextLockin, dispatch)
  })
)(withRouter(SecondaryNextButtonComponent));

class SecondarySpiritualCommunity extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.firstTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.secondaryScrollPercent.subModulePercent !== this.props.secondaryScrollPercent.subModulePercent ||
      nextProps.secondaryScrollPercent.moduleIndex !== this.props.secondaryScrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.secondaryScrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (
        this.props.secondaryScrollPercent.moduleIndex !== myIndex &&
        nextProps.secondaryScrollPercent.moduleIndex !== myIndex
      ) {
        return;
      }

      let isEntering =
        nextProps.secondaryScrollPercent.moduleIndex == myIndex &&
        (this.props.secondaryScrollPercent.moduleIndex < myIndex ||
          this.props.secondaryScrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.secondaryScrollPercent.moduleIndex == myIndex &&
        (nextProps.secondaryScrollPercent.moduleIndex > myIndex ||
          nextProps.secondaryScrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.secondaryScrollPercent.moduleIndex > nextProps.secondaryScrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.secondaryScrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.secondaryScrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.secondaryScrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Community the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  render() {
    return (
      <>
        {this.props.global.debug ? (
          <IFrameWithLoading url="http://localhost:8081/index.html" id="spiritualLast">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={spiritualLastLoading} />
          </IFrameWithLoading>
        ) : (
          <IFrameWithLoading url="https://wild-cacao.org/spiritualLast/index.html" id="spiritualLast">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={spiritualLastLoading} />
          </IFrameWithLoading>
        )}
        <Gradient />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <SecondaryNextButton isSecondary={true} />
        </FullScreen>
      </>
    );
  }
}

export default (SecondarySpiritualCommunity = connect(
  state => ({
    global: state.global,
    secondaryScrollPercent: state.secondaryScrollPercent,
    browser: state.browser
  }),
  null
)(withRouter(SecondarySpiritualCommunity)));
