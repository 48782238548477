import React, { Component } from "react";
import styled from "styled-components";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";
import { forceToSecondaryNextLockin } from "../actions/forceSecondaryScrollPercent.js";
import { forceToNextSubModule } from "../actions/forceScrollPercent.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ID } from "../utils/id";
import { CoverImg, PrimaryText } from "./components";
import { PulseCircles } from "./components";
import { GTAmerica } from "../components/typography.js";
import { WhitePrimarySmallButton } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { modules } from "./index";
import AnimalRiverIMG from "../images/animalRiver.png";
import AntEaterIMG from "../images/anteater.jpeg";
import rubberTreesIMG from "../images/rubber-trees.png";
import DeforestationIMG from "../images/deforestation.png";
import TogetherIllustrationIMG from "../images/together-illustration.jpeg";

import { ReactComponent as GraphSVG } from "../images/child-labor-graph.svg";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";
import { ReactComponent as FingerScrollSVG } from "../images/finger-scroll.svg";
import { ReactComponent as MouseScrollSVG } from "../images/mouse-scroll.svg";

export const AnimalText = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "50px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    fontStyle={props.fontStyle ? props.fontStyle : []}
    {...props}
  >
    {props.children}
  </GTAmerica>
);
const RiverNextText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Next Screen
    </GTAmerica>
  </Flex>
);

const text1 = (
  <>
    <AnimalText color={["black", "black", "black", "white"]}>
      ...to the banks of the Purus River in the heart of the Amazon.
    </AnimalText>
  </>
);

const text2 = (
  <AnimalText color={"white"}>
    I share this home with my rainforest family: silky anteaters, jaguars, pygmy marmosets, Brazil Nut trees, and{" "}
    <span style={{ fontWeight: "bold" }}>Rubber trees.</span>
  </AnimalText>
);

const Text3 = ({ ...props }) => (
  <>
    <AnimalText color={"white"}>
      It pains me that in other places rainforests are cut down and people are exploited to bring my fruits to market.
    </AnimalText>
    <GTAmerica
      pt={["36px", "36px", "36px", "79px"]}
      px={["18px", "18px", "18px", "5.1%"]}
      width={["auto", "auto", "auto", "29.1%"]}
      fontSize={["16px", "16px", "16px", "21px"]}
      color={"white"}
      lineHeight={["normal"]}
      fontWeight={[400]}
    >
      $100 Billion cacao industry is responsible for 1% forest loss globally
    </GTAmerica>
  </>
);
const LaborText = ({ ...props }) => (
  <>
    <AnimalText color={"black"}>It was never meant to be this way.</AnimalText>
  </>
);
const LaborGraphText = ({ ...props }) => (
  <Flex
    width={"100%"}
    height={"100%"}
    position={"absolute"}
    top={["28.8%", "28.8%", "28.8%", "35.5%"]}
    flexDirection={"column"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["21px", "21px", "21px", "24px"]}
      lineHeight={["27px", "27px", "27px", "29px"]}
      width={["100%", "100%", "100%", "40%"]}
      fontStyle={["normal", "normal", "normal", "normal"]}
      textAlign={["center", "center", "center", "center"]}
      fontWeight={[700]}
      color={"black"}
    >
      Child labor in cacao
    </GTAmerica>
    <GTAmerica
      fontSize={["18px", "18px", "18px", "21px"]}
      lineHeight={["24px", "24px", "24px", "27px"]}
      width={["100%", "100%", "100%", "40%"]}
      fontStyle={["normal", "normal", "normal", "normal"]}
      textAlign={["center", "center", "center", "center"]}
      fontWeight={[400]}
      color={"black"}
    >
      in top two cacao producing countries
    </GTAmerica>
  </Flex>
);

const LaborGraph = ({ ...props }) => (
  <Flex position={"absolute"} top={["51.5%", "51.5%", "51.5%", "44.4%"]} justifyContent={"center"} width={"100%"}>
    <Flex position={"relative"} width={["79.2%", "79.2%", "79.2%", "68%"]} height={["25vh", "25vh", "25vh", "35vh"]}>
      <svg id="labor-graph" width="100%" height="100%" style={{ overflow: "visible" }}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 310 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ overflow: "visible" }}
        >
          <path className="graph-line" d="M5 157L80 127L130 121L183 85H237L302 9" stroke="#10CFAB" stroke-width="3" />
        </svg>
        <svg
          preserveAspectRatio="xMinYMax meet"
          viewBox="0 0 310 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ overflow: "visible" }}
        >
          <circle cx="7" cy="156" r="7" fill="#10CFAB" />
        </svg>
        <svg
          preserveAspectRatio="xMaxYMin meet"
          viewBox="0 0 310 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ overflow: "visible" }}
        >
          <circle className="graph-end-circle" cx="300" cy="7" r="7" fill="#10CFAB" />
        </svg>
      </svg>
      <Flex position={"absolute"} bottom={["30%", "30%", "30%", "20%"]} left={"0px"} pointerEvents={"none"}>
        <GTAmerica
          fontSize={["21px", "21px", "21px", "30px"]}
          lineHeight={["29px", "29px", "29px", "42px"]}
          textShadow={"1px 1px 5px white"}
        >
          1,820,000
          <br />
          children
        </GTAmerica>
      </Flex>
      <Flex position={"absolute"} top={["-68px", "-68px", "-68px", "-100px"]} right={"0px"} pointerEvents={"none"}>
        <GTAmerica
          fontSize={["21px", "21px", "21px", "30px"]}
          lineHeight={["29px", "29px", "29px", "42px"]}
          textShadow={"1px 1px 5px white"}
        >
          2,260,000
          <br />
          children
        </GTAmerica>
      </Flex>
      <Flex position={"absolute"} bottom={["-25px", "-25px", "-25px", "-35px"]} left={"0px"} pointerEvents={"none"}>
        <GTAmerica
          transform={"translateX(-25%)"}
          fontSize={["16px", "16px", "16px", "21px"]}
          textShadow={"1px 1px 5px white"}
        >
          2009
        </GTAmerica>
      </Flex>
      <Flex position={"absolute"} bottom={["-25px", "-25px", "-25px", "-35px"]} right={"0px"} pointerEvents={"none"}>
        <GTAmerica
          transform={"translateX(25%)"}
          fontSize={["16px", "16px", "16px", "21px"]}
          textShadow={"1px 1px 5px white"}
        >
          2014
        </GTAmerica>
      </Flex>
    </Flex>
  </Flex>
);

const text4 = (
  <AnimalText color={"white"}>
    By nature, I exist to bring communities together and to reconnect us to Mother Earth.
  </AnimalText>
);

const RiverNextButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Flex
      position={"absolute"}
      top={["-40px", "-50px", "-50px", "-50px"]}
      left={"-100px"}
      right={"-100px"}
      pointerEvents={"none"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GTAmerica
        fontSize={["16px", "21px", "21px", "21px"]}
        lineHeight={["21px", "27px", "27px", "27px"]}
        color={"white"}
        textAlign={"center"}
      >
        {props.browser.greaterThan.small ? <>{props.desktopText}</> : <>{props.mobileText}</>}
      </GTAmerica>
    </Flex>
    <ClickCursorFlex
      pointerEvents={"auto"}
      onClick={() => {
        props.forceToNextLockin();
      }}
    >
      <PulseCircles smallSize={"40px"} largeSize={"60px"} />
      <WhitePrimarySmallButton>
        <Flex justifyContent={"center"} alignItems={"center"} width={["100%"]} height={["100%"]}>
          {props.browser.greaterThan.small ? (
            <Box width={["85%"]} height={["85%"]} pointerEvents={"none"}>
              <DownArrowSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "black"
                }}
              />
            </Box>
          ) : (
            <Box width={["100%"]} height={["100%"]} pointerEvents={"none"}>
              <DownArrowSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "black"
                }}
              />
            </Box>
          )}
        </Flex>
      </WhitePrimarySmallButton>
    </ClickCursorFlex>
  </Flex>
);

export const RiverNextButton = connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch)
  })
)(RiverNextButtonComponent);

const SecondaryNextButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Flex
      position={"absolute"}
      top={["-40px", "-50px", "-50px", "-50px"]}
      left={"-100px"}
      right={"-100px"}
      pointerEvents={"none"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GTAmerica
        fontSize={["16px", "21px", "21px", "21px"]}
        lineHeight={["21px", "27px", "27px", "27px"]}
        color={"white"}
        textAlign={"center"}
      >
        {props.browser.greaterThan.small ? <>{props.desktopText}</> : <>{props.mobileText}</>}
      </GTAmerica>
    </Flex>
    <ClickCursorFlex
      pointerEvents={"auto"}
      onClick={() => {
        props.forceToSecondaryNextLockin();
      }}
    >
      <PulseCircles smallSize={"40px"} largeSize={"60px"} />
      <WhitePrimarySmallButton>
        <Flex justifyContent={"center"} alignItems={"center"} width={["100%"]} height={["100%"]}>
          {props.browser.greaterThan.small ? (
            <Box width={["85%"]} height={["85%"]} pointerEvents={"none"}>
              <DownArrowSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "black"
                }}
              />
            </Box>
          ) : (
            <Box width={["100%"]} height={["100%"]} pointerEvents={"none"}>
              <DownArrowSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "black"
                }}
              />
            </Box>
          )}
        </Flex>
      </WhitePrimarySmallButton>
    </ClickCursorFlex>
  </Flex>
);

export const SecondaryNextButton = connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    forceToSecondaryNextLockin: bindActionCreators(forceToSecondaryNextLockin, dispatch)
  })
)(SecondaryNextButtonComponent);

const AntEaterGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 82.91%, rgba(0, 0, 0, 0.5) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
`;
const DeforestationGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 83.36%, rgba(0, 0, 0, 0.6) 100%);
`;
export const animalsModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 1,
  scenes: [
    {
      headerColor: "black",
      top_slide: [],
      top_fade: [<RiverNextButton />, <RiverNextText />],
      middle: [text1],
      bottom: [<CoverImg src={AnimalRiverIMG} />]
    },
    {
      top_slide: [],
      top_fade: [<DataSourceStandardButton color="black" index={0} />],
      middle: [text2],
      bottom: [<CoverImg src={rubberTreesIMG} />, <AntEaterGradient />]
    },
    {
      top_slide: [],
      top_fade: [<DataSourceStandardButton color="white" index={1} />],
      middle: [<Text3 />],
      bottom: [<CoverImg src={DeforestationIMG} />, <DeforestationGradient />]
    },
    {
      headerColor: "black",
      top_slide: [],
      top_fade: [<DataSourceStandardButton color="black" index={1} />],
      middle: [<LaborText />, <LaborGraphText />],
      bottom: [<Flex bg={"white"} width={"100%"} height={"100%"} />, <LaborGraph />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [text4],
      bottom: [<CoverImg src={TogetherIllustrationIMG} />]
    }
  ]
};
