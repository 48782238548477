import reduce from "lodash/reduce";
import { findIndex } from "lodash";

// Percent total is sum of all the <module>.percent values.
// These values can be any integer number
// Longer modules may want to be 200 and smaller ones 100 or 50.
// The totla is all of them summed up
//
export const modulePercentTotal = function(modules) {
  return reduce(
    modules,
    function(sum, n) {
      return sum + n.percent;
    },
    0
  );
};

// Returns the moduleIndex for a given scrollPercent.
// Scroll percent is a decimal 0 - 1
//
export const moduleArrayIndex = function(scrollPercent, modules) {
  if (scrollPercent <= 0) {
    return 0;
  } else {
    let percentTotal = modulePercentTotal(modules);
    let moduleArrayIndex = 0;
    let scrollPercentTotal = scrollPercent * percentTotal;
    reduce(
      modules,
      function(sum, n, index) {
        let sumAfter = sum + n.percent;
        if (scrollPercentTotal > sum && scrollPercentTotal < sumAfter) {
          moduleArrayIndex = index;
        }
        return sumAfter;
      },
      0
    );
    return moduleArrayIndex;
  }
};

export const moduleFor = function(scrollPercent, modules) {
  let index = moduleArrayIndex(scrollPercent, modules);
  return modules[index];
};

// Upto, not including, 0-1 based because its totalScroll
export const totalScrollPercentUpto = function(moduleIndex, modules) {
  let percentTotal = modulePercentTotal(modules);
  let partialPercent = reduce(
    modules.slice(0, moduleIndex),
    function(sum, n) {
      return sum + n.percent;
    },
    0
  );
  return partialPercent / percentTotal;
};

// Might use this to get the total scroll percent for the first lockin in a module
//
export const totalScrollPercentFor = function(moduleIndex, moduleSubPercent, modules) {
  let totalPercentUpto = totalScrollPercentUpto(moduleIndex, modules);

  let percentTotal = modulePercentTotal(modules);
  let totalPercentFromModule = 0;
  if (modules[moduleIndex]) {
    totalPercentFromModule = (modules[moduleIndex].percent * moduleSubPercent) / percentTotal;
  }
  return totalPercentUpto + totalPercentFromModule;
};

export const subModulePercent = function(scrollPercent, modules) {
  let index = moduleArrayIndex(scrollPercent, modules);
  let percentTotal = modulePercentTotal(modules);
  let partialPercent = reduce(
    modules.slice(0, index),
    function(sum, n) {
      return sum + n.percent;
    },
    0
  );
  let module = modules[index];
  return (percentTotal * scrollPercent - partialPercent) / module.percent;
};

export const modulePercentForChapter = function(chapterNumber, modules) {
  let moduleIndex = findIndex(modules, ["chapterNumber", chapterNumber]);
  let firstLockin = modules[moduleIndex].lockinPercents[0];
  return totalScrollPercentFor(moduleIndex, firstLockin, modules);
};
