export const defaultState = {
  DeviceOrientationEvent: window.DeviceOrientationEvent !== undefined,
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    default:
      return state;
  }
};
