import React, { Component } from "react";
import Interactive from "./Interactive";

import {
  layers,
  LAYER_WIDTH,
  LAYER_HEIGHT
} from "./bridgeInteractiveImageImports";

const PERSPECTIVE_START = 600;
const PERSPECTIVE_END = 4000;
const ROTATION_ADJUST = 90;
const BG_SCALE_END = 1.6;
const BG_SCALE_START = 1.9;
const BG_TRANSLATE_Z = -400;
const BACKGROUND_WRAPPER_ID = "bridge-background-wrapper";
const BACKGROUND_ID = "bridge-background-rotater";

const shorterLayers = layers.slice(1, 99);

export const Bridge = ({ ...props }) => (
  <Interactive
    bgLayer={layers[0]}
    layers={shorterLayers}
    layerWidth={LAYER_WIDTH}
    layerHeight={LAYER_HEIGHT}
    perspectiveStart={PERSPECTIVE_START}
    perspectiveEnd={PERSPECTIVE_END}
    bgScaleEnd={BG_SCALE_END}
    bgScaleStart={BG_SCALE_START}
    rotationAdjust={ROTATION_ADJUST}
    bgTranslateZ={BG_TRANSLATE_Z}
    backgroundWrapperId={BACKGROUND_WRAPPER_ID}
    backgroundId={BACKGROUND_ID}
  />
);
