import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { connect } from "react-redux";
import VideoInteractive from "./video";
import { forceToNextLockin, forceToPreviousLockin } from "../actions/forceScrollPercent.js";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import Iframe from "react-iframe";
import { RiverNextButton } from "./animals";
import { AnimalText, Play360Button, CoverImg, GalleryButton } from "./components";
import { withRouter } from "react-router-dom";

import DataSourceStandardButton from "../components/dataSourceButton";
import riverVideo from "../images/boat_shrunk.mp4";
import fruitPeelerSmallIMG from "../images/fruit-pealing-small.jpg";
import { VideoWrapper, VideoContainer } from "./video";

import { Swipeable } from "react-swipeable";
import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
import throttle from "lodash/throttle";
import { disable as disableAudio } from "../actions/audio";
import { withMediaProps } from "react-media-player";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const ShadowedFlex = styled(Flex)`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const throttledWheelHandler = throttle(
  (deltaY, forceToNextLockin, forceToPreviousLockin) => {
    if (deltaY > 0) {
      forceToNextLockin();
    } else if (deltaY < 0) {
      forceToPreviousLockin();
    }
  },
  2000,
  { leaading: true, trailing: false }
);
const ShadowedFlexComponent = ({ ...props }) => (
  <ShadowedFlex
    mt={["8vh", "8vh", "8vh", "21.4vh"]}
    width={["70%", "60%", "50%", "40%"]}
    pt={["50%", "40%", "33%", "26.6%"]}
    justifyContent={"center"}
    position={"relative"}
    alignItems={"center"}
    onClick={() => props.history.push("/riverjourney/harvest")}
    onWheel={event => {
      throttledWheelHandler(event.deltaY, props.forceToNextLockin, props.forceToPreviousLockin);
    }}
    pointerEvents={"all"}
  >
    <Swipeable
      onSwipedUp={eventData => props.forceToNextLockin()}
      onSwipedDown={eventData => props.forceToPreviousLockin()}
    >
      {props.children}
    </Swipeable>
  </ShadowedFlex>
);
export const ShadowedFlexWrapped = connect(
  null,
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch),
    forceToPreviousLockin: bindActionCreators(forceToPreviousLockin, dispatch)
  })
)(withRouter(ShadowedFlexComponent));

class HeroRiverVideo extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.videoInnerRef = React.createRef();
    this.firstTimeline = null;
    this.secondTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
    this.secondTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 2.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }

  secondTimelineSetup() {
    this.secondTimeline = new TimelineMax();

    this.secondTimeline.add(
      TweenMax.set(this.secondRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 2.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        display: "block",
        autoAlpha: 1
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _videoSetup() {
    //this.videoInnerRef.playbackRate = 1;
    this.videoInnerRef.play();
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
    this.secondTimeline.progress(value);
  }
  render() {
    return (
      <>
        <VideoWrapper className={"video-wrapper"}>
          <VideoContainer
            onCanPlay={() => this._videoSetup()}
            autoPlay
            muted={true}
            loop
            playsInline
            innerRef={div => (this.videoInnerRef = div)}
          >
            <source src={riverVideo} type="video/mp4" />
          </VideoContainer>
        </VideoWrapper>
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <AnimalText color={["white", "white", "white", "white"]}>
            The river is the lifeblood of the people. As cacao, I unite them as one family.
          </AnimalText>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "15.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Play360Button
              clickHandler={() => {
                if (this.props.audio.touched && this.props.media.isMuted !== true) {
                  this.props.media.mute(true);
                  this.props.disableAudio();
                }
                this.props.history.push("/riverjourney/journey");
              }}
            />
            <GTAmerica
              letterSpacing={"0.15em"}
              fontWeight={700}
              fontSize={["11px", "11px", "11px", "14px"]}
              lineHeight={["17px", "17px", "17px", "20px"]}
              color={"white"}
              textAlign={["center"]}
            >
              TAKE A JOURNEY
              <br />
              ALONG THE RIVER
            </GTAmerica>
          </Flex>
        </FullScreen>
        <FullScreen innerRef={div => (this.secondRef = div)}>
          <DataSourceStandardButton color="white" index={2} />
          <Flex
            position={"absolute"}
            top={"0px"}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"space-between"}
            flexDirection={["column", "column", "column", "row"]}
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            px={["18px", "18px", "18px", "5.1%"]}
          >
            <AnimalText px={["0px"]} color={["white", "white", "white", "white"]}>
              Each contributor is important to the whole.
              <br />
              <br /> Some community members harvest cacao, others open pods or dry the beans, and some drive the boats.
            </AnimalText>
            <ShadowedFlexWrapped>
              <Flex
                flexDirection={"column"}
                width={"100%"}
                height={"100%"}
                top={"0px"}
                left={"0px"}
                position={"absolute"}
                justifyContent={"center"}
                alignItems={"center"}
                pointerEvents={"all"}
              >
                <CoverImg
                  top={"0px"}
                  position={"absolute"}
                  objectPositionY={"50%"}
                  objectPositionX={"50%"}
                  src={fruitPeelerSmallIMG}
                />
                <GalleryButton mb={"20px"} clickHandler={() => this.props.history.push("/riverjourney/harvest")} />
                <GTAmerica
                  fontWeight={700}
                  fontSize={["11px", "11px", "11px", "14px"]}
                  lineHeight={["17px", "17px", "17px", "20px"]}
                  letterSpacing={"0.15em"}
                  color={"white"}
                  textAlign={["center"]}
                >
                  SEE PHOTOS OF HARVEST
                </GTAmerica>
              </Flex>
            </ShadowedFlexWrapped>
          </Flex>
        </FullScreen>
      </>
    );
  }
}

export default (HeroRiverVideo = connect(
  state => ({
    audio: state.audio,
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  dispatch => ({
    disableAudio: bindActionCreators(disableAudio, dispatch)
  })
)(withMediaProps(withRouter(HeroRiverVideo))));
