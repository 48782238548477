import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { ReactComponent as PlaySVG } from "../images/play.svg";
import { ReactComponent as CarretSVG } from "../images/carret.svg";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as Three60SVG } from "../images/360.svg";
import { ReactComponent as GallerySVG } from "../images/gallery.svg";

export const AnimalText = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "50px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    fontStyle={props.fontStyle ? props.fontStyle : []}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

export const ImgStyled = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const CoverImgStyled = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  object-position: ${props => (props.objectPositionX ? props.objectPositionX : "50%")}
    ${props => (props.objectPositionY ? props.objectPositionY : "50%")};
  width: 100%;
  height: 100%;
`;
export const CoverImg = ({ ...props }) => (
  <Flex
    bg={"black"}
    width={"100%"}
    height={"100%"}
    position={props.position ? props.position : ""}
    top={props.top ? props.top : ""}
  >
    <CoverImgStyled objectPositionY={props.objectPositionY} objectPositionX={props.objectPositionX} src={props.src} />
  </Flex>
);

const CoverImgResponsiveComponent = ({ ...props }) => (
  <>{props.browser.greaterThan.medium ? <CoverImg src={props.desktopSrc} /> : <CoverImg src={props.mobileSrc} />}</>
);
export const CoverImgResponsive = connect(
  state => ({
    browser: state.browser
  }),
  null
)(CoverImgResponsiveComponent);

const PrimaryTextStyled = styled(GTAmerica)`
  pointer-events: none;
  @media screen and (min-width: 768px) {
    text-shadow: 0px 0px 10px #000000;
  }
`;
export const PrimaryText = ({ ...props }) => (
  <PrimaryTextStyled
    color={"white"}
    fontSize={["21px", "28px", "28px", "36px"]}
    lineHeight={["27px", "39px", "39px", "50px"]}
    fontWeight={[400, 400, 400, 300]}
    fontStyle={props.fontStyle ? props.fontStyle : []}
    {...props}
  >
    {props.children}
  </PrimaryTextStyled>
);

export const PrimaryButton = ({ ...props }) => (
  <Flex
    justifyContent={"center"}
    alignItems={"center"}
    width={["60px", "60px", "60px", "80px"]}
    height={["60px", "60px", "60px", "80px"]}
    borderRadius={"50%"}
    bg={"#02DCA1"}
  >
    {props.children}
  </Flex>
);

export const WhitePrimaryButton = ({ ...props }) => (
  <Flex
    justifyContent={"center"}
    alignItems={"center"}
    width={["60px", "60px", "60px", "80px"]}
    height={["60px", "60px", "60px", "80px"]}
    borderRadius={"50%"}
    bg={"white"}
    zIndex={4}
  >
    {props.children}
  </Flex>
);

export const WhitePrimarySmallButton = ({ ...props }) => (
  <Flex
    justifyContent={"center"}
    alignItems={"center"}
    width={["40px", "40px", "40px", "60px"]}
    height={["40px", "40px", "40px", "60px"]}
    borderRadius={"50%"}
    bg={"white"}
    zIndex={4}
    {...props}
  >
    {props.children}
  </Flex>
);

export const DataSourceButton = ({ ...props }) => (
  <ClickCursorFlex
    p={["20px"]}
    onClick={event => {
      props.clickHandler();
      event.stopPropagation();
    }}
  >
    <GTAmerica color={props.color} fontSize={["12px", "12px", "12px", "14px"]}>
      Data Source
    </GTAmerica>
    <Flex width={"8px"} pl={["7px"]} alignItems={"flex-end"} pb={["2px"]}>
      <CarretSVG
        style={{
          transform: "rotate(" + (props.flipped ? 180 : 0) + "deg)",
          width: "100%",
          stroke: props.color,
          strokeWidth: "1px",
          fill: "none"
        }}
      />
    </Flex>
  </ClickCursorFlex>
);

export const CTAButton = ({ ...props }) => (
  <ClickCursorFlex
    position={"relative"}
    justifyContent={"center"}
    alignItems={"center"}
    width={["50px", "50px", "50px", "70px"]}
    height={["50px", "50px", "50px", "70px"]}
    borderRadius={"50%"}
    bg={"#02DCA1"}
    pointerEvents={"auto"}
    onClick={event => {
      if (props.clickCallback) {
        props.clickCallback();
      }
      event.stopPropagation();
    }}
    {...props}
  >
    {props.children}
  </ClickCursorFlex>
);

export const LargeCTAButton = ({ ...props }) => (
  <ClickCursorFlex
    position={"relative"}
    justifyContent={"center"}
    alignItems={"center"}
    width={["70px", "70px", "70px", "100px"]}
    height={["70px", "70px", "70px", "100px"]}
    borderRadius={"50%"}
    bg={"#02DCA1"}
    pointerEvents={"auto"}
    onClick={event => {
      if (props.clickCallback) {
        props.clickCallback();
      }
      event.stopPropagation();
    }}
    {...props}
  >
    {props.children}
  </ClickCursorFlex>
);

export const GalleryButton = ({ ...props }) => (
  <CTAButton clickCallback={props.clickHandler} {...props}>
    <GallerySVG
      style={{
        width: "75%",
        height: "75%",
        stroke: "none",
        fill: "#FFFFFF"
      }}
    />
  </CTAButton>
);

export const PlayButton = ({ ...props }) => (
  <CTAButton clickCallback={props.clickHandler} {...props}>
    <Box width={["12px", "12px", "12px", "14px"]} height={["15px", "15px", "15px", "17px"]}>
      <PlaySVG
        style={{
          width: "100%",
          height: "100%",
          stroke: "#ffffff",
          strokeWidth: "1px",
          fill: "#FFFFFF"
        }}
      />
    </Box>
  </CTAButton>
);

export const LargePlayButton = ({ ...props }) => (
  <LargeCTAButton clickCallback={props.clickHandler} {...props}>
    <Box width={["14px", "14px", "14px", "20px"]} height={["17px", "17px", "17px", "24px"]}>
      <PlaySVG
        style={{
          width: "100%",
          height: "100%",
          stroke: "#ffffff",
          strokeWidth: "1px",
          fill: "#FFFFFF"
        }}
      />
    </Box>
  </LargeCTAButton>
);

export const Play360Button = ({ ...props }) => (
  <CTAButton clickCallback={props.clickHandler} {...props}>
    <Box width={["14px", "14px", "14px", "17px"]} height={["14px", "14px", "14px", "17px"]}>
      <PlaySVG
        style={{
          width: "100%",
          height: "100%",
          stroke: "#ffffff",
          strokeWidth: "1px",
          fill: "#FFFFFF"
        }}
      />
    </Box>
    <AbsoluteDiv top={"-25%"} right={"-25%"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width={["40px", "40px", "40px", "40px"]}
        height={["40px", "40px", "40px", "40px"]}
        borderRadius={"50%"}
        bg={"white"}
      >
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Three60SVG
            style={{
              width: "100%",
              height: "100%",
              stroke: "none",
              fill: "black"
            }}
          />
        </Flex>
      </Flex>
    </AbsoluteDiv>
  </CTAButton>
);

export const PulseCirclesFast = ({ ...props }) => (
  <>
    <AbsoluteDiv
      zIndex={1}
      width={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      height={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      borderRadius={["50%"]}
      bg={props.color}
      className={"pulse-two-fast"}
    />
    <AbsoluteDiv
      zIndex={2}
      width={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      height={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      borderRadius={["50%"]}
      bg={props.color}
      className={"pulse-one-fast"}
    />
  </>
);
export const PulseCircles = ({ ...props }) => (
  <>
    <AbsoluteDiv
      zIndex={1}
      width={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      height={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      borderRadius={["50%"]}
      bg={"white"}
      className={"pulse-two"}
    />
    <AbsoluteDiv
      zIndex={2}
      width={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      height={[props.smallSize, props.smallSize, props.smallSize, props.largeSize]}
      borderRadius={["50%"]}
      bg={"white"}
      className={"pulse-one"}
    />
  </>
);
export const ProfilePic = ({ ...props }) => (
  <Flex
    width={["145px", "145px", "145px", "200px"]}
    height={["145px", "145px", "145px", "200px"]}
    justifyContent={"center"}
    alignItems={"center"}
    borderRadius={"50%"}
    position={"relative"}
  >
    <Flex
      width={["145px", "145px", "145px", "200px"]}
      height={["145px", "145px", "145px", "200px"]}
      justifyContent={"center"}
      bg={"#EEEEEE"}
      alignItems={"center"}
      borderRadius={"50%"}
      overflow={"hidden"}
      position={"absolute"}
      zIndex={3}
    />
    <Flex
      width={["115px", "115px", "115px", "160px"]}
      height={["115px", "115px", "115px", "160px"]}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"50%"}
      overflow={"hidden"}
      position={"relative"}
      zIndex={4}
    >
      <ImgStyled src={props.src} />
    </Flex>
  </Flex>
);
export const ProfilePicNoPulse = ({ ...props }) => (
  <Flex
    width={["145px", "145px", "145px", "200px"]}
    height={["145px", "145px", "145px", "200px"]}
    justifyContent={"center"}
    alignItems={"center"}
    borderRadius={"50%"}
    position={"relative"}
    bg={"#EEEEEE"}
  >
    <Flex
      width={["115px", "115px", "115px", "160px"]}
      height={["115px", "115px", "115px", "160px"]}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"50%"}
      overflow={"hidden"}
      position={"relative"}
      zIndex={4}
    >
      <ImgStyled src={props.src} />
    </Flex>
  </Flex>
);
