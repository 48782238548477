import React, { Component } from "react";
import { CSSPlugin, AttrPlugin, TweenLite } from "gsap/all";
import { connect } from "react-redux";
import styled from "styled-components";
import map from "lodash/map";
import throttle from "lodash/throttle";
import { hideButton } from "../actions/motion";
import { showButton } from "../actions/motion";
import {
  Background,
  BackgroundWrapper,
  AbsoluteZOffsetPositioned,
  FullScreenFill,
  HeroGradient
} from "./3dComponents.js";
import { bindActionCreators } from "redux";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

export const AbsoluteZOffsetPositionedDiv = styled.div`
  position: absolute;
  width: ${props => (props.width ? (props.width / props.layerWidth) * 100 + "%" : "auto")};
  height: auto;
  top: ${props => (props.y ? (props.y / props.layerHeight) * 100 + "%" : "0px")};
  left: ${props => (props.x ? (props.x / props.layerWidth) * 100 + "%" : "0px")};
  transform: translateZ(${props => (props.zOffset ? props.zOffset : 0)}px);
`;

export const AbsoluteZOffsetPositionedDivHeightIncluded = styled.div`
  position: absolute;
  width: ${props => (props.width ? (props.width / props.layerWidth) * 100 + "%" : "auto")};
  height: ${props => (props.width ? (props.width / props.layerHeight) * 100 + "%" : "auto")};
  top: ${props => (props.y ? (props.y / props.layerHeight) * 100 + "%" : "0px")};
  left: ${props => (props.x ? (props.x / props.layerWidth) * 100 + "%" : "0px")};
  transform: translateZ(${props => (props.zOffset ? props.zOffset : 0)}px);
`;

class Interactive extends Component {
  state = {
    alpha: null,
    beta: null,
    gamma: null,
    initialAlpha: null,
    initialBeta: null,
    initialGamma: null,
    absolute: false,
    x_degreeMax: 12.5,
    y_degreeMax: 7.5,
    x_degreeMaxGyro: 18,
    y_degreeMaxGyro: 9,
    scaleFactor: 1,
    movementFactor: 1
  };

  constructor(props) {
    super(props);
    this.rotations = { x: 0, y: 0 };
    // Pre-bind your event handler, or define it as a fat arrow in ES7/TS
    this.handleDeviceOrientation = this.handleDeviceOrientation.bind(this);
    this.throttledHandleDeviceOrientation = throttle(this.handleDeviceOrientation, 100);
    this.motionEventsProcessed = false;

    this.throttledDeviceOrientationMaxDimensionCallback = throttle(this.deviceOrientationMaxDimensionCallback, 2000, {
      leading: false,
      trailing: true
    });
    // Setup bg scaling & scrolling timeline
    this.backgroundInnerRef = React.createRef();
    this.bgLayerInnerRef = React.createRef();
    this.state.scaleFactor = this.props.scaleFactor ? this.props.scaleFactor : 1;
    this.state.isMobileTablet = window.mobileAndTabletcheck();
  }
  handleDeviceOrientation = e => {
    this.motionEventsProcessed = true;
    this.props.hideButton();
    if (this.state.initialAlpha === null || this.state.initialBeta === null || this.state.initialGamma === null) {
      this.setState({
        initialBeta: e.beta + this.props.rotationAdjust,
        initialAlpha: e.alpha + this.props.rotationAdjust,
        initialGamma: e.gamma + this.props.rotationAdjust
      });
    } else {
      this.setState({
        beta: e.beta + this.props.rotationAdjust,
        alpha: e.alpha + this.props.rotationAdjust,
        gamma: e.gamma + this.props.rotationAdjust
      });
    }

    let rotations = this.computeBackgroundRotationFromGyro(e.absolute, e.alpha, e.beta, e.gamma);

    if (window.orientation == 90 || window.orientation == -90) {
      let swappedRotations = { x: rotations.y, y: rotations.x };
      rotations = swappedRotations;
    }
    const { scaleFactor, movementFactor } = this.state;
    if (this.backgroundInnerRef) {
      TweenLite.to(this.backgroundInnerRef, 0.25, {
        transform:
          "rotateX(" +
          rotations.y / (scaleFactor * movementFactor) +
          "deg) rotateY(" +
          rotations.x / (scaleFactor * movementFactor) +
          "deg) scale(" +
          scaleFactor +
          ")"
      });
    }
    this.rotations = rotations;
    if (
      Math.abs(rotations.x) > this.state.x_degreeMaxGyro * 0.66 ||
      Math.abs(rotations.y) > this.state.y_degreeMaxGyro * 0.66
    ) {
      this.throttledDeviceOrientationMaxDimensionCallback(rotations);
    }
    return true;
    //: this.computedBackgroundRotationFromMouse()
  };
  resize = () => this.forceUpdate();
  deviceOrientationMaxDimensionCallback(eventrotations) {
    let rotations = this.rotations;
    if (window.orientation == 90 || window.orientation == -90) {
      let swappedRotations = { x: rotations.y, y: rotations.x };
      rotations = swappedRotations;
    }
    if (
      Math.abs(rotations.x) > this.state.x_degreeMaxGyro * 0.66 ||
      Math.abs(rotations.y) > this.state.y_degreeMaxGyro * 0.66
    ) {
      // Reset the current position to be the origin.
      // Solves for people loading in one orientation but moving to another.
      //
      this.setState({
        initialBeta: this.state.beta,
        initialAlpha: this.state.alpha,
        initialGamma: this.state.gamma
      });
    } else {
      console.log("no need to reset the rotations are not maxed out anymore callback");
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.eventSupportHas.DeviceOrientationEvent && this.state.isMobileTablet) {
      window.addEventListener("deviceorientation", this.throttledHandleDeviceOrientation, true);
      setTimeout(() => {
        if (this.motionEventsProcessed == false) {
          this.props.showButton();
        }
      }, 1000);
    } else {
    }
    this.perspectiveSetup();
    this.bgSetup();
    setTimeout(() => {
      this.resetToOrigin();
    }, 0);
  }
  resetToOrigin() {
    TweenLite.to(this.backgroundInnerRef, 0.25, {
      transform: "rotateX(" + 0 + "deg) rotateY(" + 0 + "deg) scale(" + 1 + ")"
    });
  }

  perspectiveSetup() {
    TweenLite.set(this.backgroundInnerRef, {
      perspective: this.props.perspectiveStart
    });
  }

  bgSetup() {
    const { bgScaleStart, bgTranslateZ } = this.props;
    TweenLite.set(this.bgLayerInnerRef, {
      scale: bgScaleStart,
      tranlateZ: bgTranslateZ
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    if (this.props.eventSupportHas.DeviceOrientationEvent) {
      window.removeEventListener("deviceorientation", this.throttledHandleDeviceOrientation, true);
    }
  }

  _animateRotation(rotations) {
    const { scaleFactor, movementFactor } = this.state;
    TweenLite.to(this.backgroundInnerRef, 0.25, {
      transform:
        "rotateX(" +
        rotations.y / (scaleFactor * movementFactor) +
        "deg) rotateY(" +
        rotations.x / (scaleFactor * movementFactor) +
        "deg) scale(" +
        scaleFactor +
        ")"
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.mouseXY.x !== this.props.mouseXY.x || nextProps.mouseXY.y !== this.props.mouseXY.y) {
      let rotations = this.computedRotation(
        this.props.mouseXY.x,
        this.props.mouseXY.y,
        this.props.browser.width,
        this.props.browser.height
      );
      this._animateRotation(rotations);
    } else if (nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent) {
      const { bgScaleStart, bgScaleEnd, perspectiveStart, perspectiveEnd, bgTranslateZ } = this.props;
      TweenLite.to(this.backgroundInnerRef, 3, {
        perspective:
          (this.props.perspectiveEnd - this.props.perspectiveStart) * this.props.scrollPercent.subModulePercent +
          this.props.perspectiveStart
      });
      TweenLite.to(this.bgLayerInnerRef, 3, {
        scale: (bgScaleEnd - bgScaleStart) * this.props.scrollPercent.subModulePercent + bgScaleStart,
        tranlateZ: bgTranslateZ
      });
    } else if (
      nextProps.deltas.deltax !== this.props.deltas.deltax ||
      nextProps.deltas.deltay !== this.props.deltas.deltay
    ) {
      // only process if we are not processing motion events
      if (this.motionEventsProcessed == false) {
        let { deltax, deltay } = this.props.deltas;
        let x = Math.min(Math.max(0, Math.abs(deltax - 200)), 400);
        let y = Math.min(Math.max(0, Math.abs(deltay - 200)), 400);
        let rotations = this.computedRotation(x, y, 400, 400);
        this._animateRotation(rotations);
      }
    }
  }

  _onMouseMove(e) {
    //this.setOpacityCSSVariable(e);
    //this.setState({ x: e.screenX, y: e.screenY });
  }
  setOpacityCSSVariable(e) {
    let xBasedOpacity = (this.props.browser.width / 2 - e.screenX) / (this.props.browser.width / 2);
    let yBasedOpacity = (this.props.browser.height / 2 - e.screenY) / (this.props.browser.height / 2);

    let opacity = Math.max(Math.abs(xBasedOpacity), Math.abs(yBasedOpacity));
    if (e.screenX < this.props.browser.width / 2 && e.screenY < this.props.browser.height) {
      opacity = e.screenX / (this.props.browser.height / 2);
    }
    let multiple = this.props.browser.width / 2;
    opacity = (e.screenX % multiple) / multiple;
    document.documentElement.style.setProperty(`--glow-opacity`, `${opacity}`);
  }
  aspectRatio() {
    return this.props.browser.width / this.props.browser.height;
  }
  computeTopOffset() {
    const { layerHeight, layerWidth } = this.props;
    let toReturn = 0;
    if (
      this.aspectRatio() > layerWidth / layerHeight &&
      this.backgroundInnerRef &&
      this.backgroundInnerRef.clientHeight
    ) {
      toReturn =
        -1 *
        ((((this.backgroundInnerRef.clientHeight - this.props.browser.height) / this.props.browser.height) * 100) / 2);
    } else {
      toReturn = 0;
    }
    return toReturn + "%";
  }
  computeLeftOffset() {
    const { layerHeight, layerWidth } = this.props;
    let toReturn = 0;
    if (
      this.aspectRatio() < layerWidth / layerHeight &&
      this.backgroundInnerRef &&
      this.backgroundInnerRef.clientWidth
    ) {
      toReturn =
        -1 *
        ((((this.backgroundInnerRef.clientWidth - this.props.browser.width) / this.props.browser.width) * 100) / 2);
    } else {
      toReturn = 0 + (this.props.desktopLeftAdjust ? this.props.desktopLeftAdjust : 0);
    }
    return toReturn + "%";
  }
  computeBackgroundRotationFromGyro(absolute, alpha, beta_input, gamma) {
    const { rotationAdjust } = this.props;
    const denominator = 10;
    let beta = Math.max(Math.min(beta_input, 90), -90);
    let percentFromMidpointY = ((beta + rotationAdjust - this.state.initialBeta) / denominator) * 0.3;
    let percentFromMidpointX = ((gamma + rotationAdjust - this.state.initialGamma) / denominator) * 0.2;

    // Transform percentMidpoint into exponential dropoff percent
    let percentFromMidpointDecayedY = 1 - 1 * Math.pow(0.99, Math.round(percentFromMidpointY * 65));
    let percentFromMidpointDecayedX = 1 - 1 * Math.pow(0.98, Math.round(percentFromMidpointX * 65));
    console.log("y " + percentFromMidpointDecayedY);
    console.log("x " + percentFromMidpointDecayedX);

    let x_degreeMax = this.state.x_degreeMaxGyro;
    let y_degreeMax = this.state.y_degreeMaxGyro;
    let toReturn = {
      x: Math.min(Math.max(percentFromMidpointDecayedX * x_degreeMax * 1, -1 * x_degreeMax), x_degreeMax),
      y: Math.min(Math.max(percentFromMidpointDecayedY * y_degreeMax * -1, -1 * y_degreeMax), y_degreeMax)
    };
    return toReturn;
  }
  computedBackgroundRotationFromMouse() {
    if (this.props.mouseXY.x == -1 || this.props.mouseXY.y == -1) {
      return {
        y: 0,
        x: 0
      };
    }
    return this.computedRotation(
      this.props.mouseXY.x,
      this.props.mouseXY.y,
      this.props.browser.width,
      this.props.browser.height
    );
  }
  computedRotation(x, y, screenWidth, screenHeight) {
    let percentFromMidpointX = (x - screenWidth / 2) / (screenWidth / 2);
    let percentFromMidpointY = (y - screenHeight / 2) / (screenHeight / 2);
    let x_degreeMax = this.state.x_degreeMax;
    let y_degreeMax = this.state.y_degreeMax;
    let toReturn = {
      y: Math.min(Math.max(percentFromMidpointY * y_degreeMax * -1, -1 * y_degreeMax), y_degreeMax),
      x: Math.min(Math.max(percentFromMidpointX * x_degreeMax * 1, -1 * x_degreeMax), x_degreeMax)
    };
    return toReturn;
  }
  render() {
    const { x, y } = this.state;
    const {
      bgLayer,
      layers,
      supplementalLayers,
      layerWidth,
      layerHeight,
      backgroundId,
      backgroundWrapperId
    } = this.props;
    return (
      <FullScreenFill onMouseMove={this._onMouseMove.bind(this)}>
        <BackgroundWrapper id={backgroundWrapperId}>
          <Background
            initialTransform={this.props.initialTransform}
            layerHeight={layerHeight}
            layerWidth={layerWidth}
            innerRef={div => (this.backgroundInnerRef = div)}
            id={backgroundId}
            className={`interactive`}
            topOffset={this.computeTopOffset()}
            leftOffset={this.computeLeftOffset()}
          >
            <AbsoluteZOffsetPositioned
              innerRef={div => (this.bgLayerInnerRef = div)}
              layerWidth={layerWidth}
              layerHeight={layerHeight}
              key={bgLayer.image}
              className={`${bgLayer.classNames}`}
              src={bgLayer.name === "misscacao" ? { uri: bgLayer.image } : bgLayer.image}
              zOffset={bgLayer.zOffset}
              autoScale={bgLayer.autoScale}
              width={bgLayer.width}
              height={bgLayer.height}
              x={bgLayer.x}
              y={bgLayer.y}
            />
            {map(layers, e => {
              return (
                <AbsoluteZOffsetPositioned
                  layerWidth={layerWidth}
                  layerHeight={layerHeight}
                  key={e.image}
                  className={`${e.classNames}`}
                  src={e.name === "misscacao" ? { uri: e.image } : e.image}
                  zOffset={e.zOffset}
                  autoScale={e.autoScale}
                  width={e.width}
                  height={e.height}
                  x={e.x}
                  y={e.y}
                />
              );
            })}
            {map(supplementalLayers, e => {
              return (
                <AbsoluteZOffsetPositionedDivHeightIncluded
                  layerWidth={layerWidth}
                  layerHeight={layerHeight}
                  key={e.name}
                  className={`${e.classNames} supplemental-layer`}
                  zOffset={e.zOffset}
                  width={e.width}
                  height={e.height}
                  x={e.x}
                  y={e.y}
                >
                  {e.component}
                </AbsoluteZOffsetPositionedDivHeightIncluded>
              );
            })}
          </Background>
        </BackgroundWrapper>
        {this.props.gradientComponent}
      </FullScreenFill>
    );
  }
}

export default (Interactive = connect(
  state => ({
    mouseXY: state.mouseXY,
    deltas: state.deltas,
    scrollPercent: state.scrollPercent,
    browser: state.browser,
    eventSupportHas: state.eventSupportHas,
    scrollPercent: state.scrollPercent
  }),
  dispatch => ({
    showButton: bindActionCreators(showButton, dispatch),
    hideButton: bindActionCreators(hideButton, dispatch)
  })
)(Interactive));
