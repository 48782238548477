import React, { Component } from "react";
import { connect } from "react-redux";
import Interactive from "./Interactive";
import { GTAmerica } from "../components/typography";

import { layers, LAYER_WIDTH, LAYER_HEIGHT } from "./chocolateBarInteractiveImageImports";

import { ChocolateDeepDiveOverlay, ShadowText } from "./3dComponents.js";

import { Flex } from "../components/layout";
import styled from "styled-components";
import flavorHover from "../images/flavor-hover.png";

import { BorderAnimatedComponent } from "./3dComponents";
import triangleSVG from "../images/triangle.svg";
import triangleUpSVG from "../images/triangleUp.svg";
import { AbsoluteDiv } from "../components/layout";
const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0) 35.36%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.51%, rgba(0, 0, 0, 0.12) 100%);
`;

const PERSPECTIVE_START = 4000;
const PERSPECTIVE_END = 700;
const BG_SCALE_END = 1.8;
const BG_SCALE_START = 1.9;
const ROTATION_ADJUST = 90;
const BG_TRANSLATE_Z = -400;
const BACKGROUND_WRAPPER_ID = "chocolate-bar-background-wrapper";
const BACKGROUND_ID = "chocolate-bar-background-rotater";

const shorterLayers = layers.slice(1, 99);
const TriangleDiv = styled(AbsoluteDiv)`
  transform-origin: center center;
  transform: translateX(
    ${props => (props.translateX ? props.translateX : "0")}
  );
  width: 50%;
  max-width:100px;
    height: ${props => (props.heightProp ? props.heightProp : "auto")}
    overflow: ${props => (props.overflowProp ? props.overflowProp : "visible")}
`;

const TriangleImg = styled.img`
  width: 100%;
  position: absolute;
`;

class ChocolateBar extends Component {
  state = {
    showGeneticOverlay: false,
    showFlavorOverlay: false
  };
  constructor(props) {
    super(props);
  }
  componentWillMount() {}
  componentDidMount() {}
  componentWillReceiveProps(nextProps) {
    if (nextProps.shareMenu.toggle !== this.props.shareMenu.toggle) {
      this.setState({
        showGeneticOverlay: false,
        showFlavorOverlay: false
      });
    }
  }
  render() {
    const this_captured = this;
    return (
      <Interactive
        bgLayer={layers[0]}
        layers={shorterLayers}
        layerWidth={LAYER_WIDTH}
        layerHeight={LAYER_HEIGHT}
        perspectiveStart={PERSPECTIVE_START}
        perspectiveEnd={PERSPECTIVE_END}
        bgScaleEnd={BG_SCALE_END}
        bgScaleStart={BG_SCALE_START}
        rotationAdjust={ROTATION_ADJUST}
        bgTranslateZ={BG_TRANSLATE_Z}
        backgroundWrapperId={BACKGROUND_WRAPPER_ID}
        backgroundId={BACKGROUND_ID}
        gradientComponent={<HeroGradient className="hero-gradient" />}
        supplementalLayers={
          this.props.showSupplemental
            ? [
                {
                  classNames: "",
                  height: 170,
                  component: (
                    <Flex position={"absolute"} width={"100%"} height={"100%"} cursor={"pointer"}>
                      <BorderAnimatedComponent
                        onClickHandler={e => {
                          this_captured.setState({
                            showGeneticOverlay: !this.state.showGeneticOverlay,
                            showFlavorOverlay: false
                          });
                          e.stopPropagation();
                        }}
                        width={"100%"}
                        height={"100%"}
                      />
                      {this.state.showGeneticOverlay ? (
                        <>
                          <ChocolateDeepDiveOverlay
                            top={"82%"}
                            left={"0%"}
                            isDesktop={this.props.browser.greaterThan.medium}
                            title={"Genetic Rarity:"}
                            body={
                              "As wild cacao of the Purus varietal, my genetic signature is unique. Of the 12 original heirloom varietals of wild cacao, only two are being harvested. Purus is one of them."
                            }
                            paddingBottom={"40px"}
                            paddingBottomMobile={"30px"}
                          />
                          <TriangleDiv
                            top={"75%"}
                            left={"50%"}
                            rotate={"180deg"}
                            translateX={"-50%"}
                            heightProp={"20%"}
                            overflowProp={"hidden"}
                          >
                            <TriangleImg src={triangleUpSVG} />
                          </TriangleDiv>
                        </>
                      ) : null}
                      <ShadowText top={"23.5%"} left={"50%"} fontSize={"22px"}>
                        Genetics
                      </ShadowText>
                    </Flex>
                  ),
                  name: "pulse-border",
                  width: 170,
                  x: 512,
                  y: 232,
                  zOffset: 250
                },
                {
                  classNames: "",
                  height: 80,
                  component: (
                    <Flex position={"absolute"} width={"100%"} height={"100%"} cursor={"pointer"}>
                      <BorderAnimatedComponent
                        width={"100%"}
                        height={"100%"}
                        onClickHandler={e => {
                          this_captured.setState({
                            showFlavorOverlay: !this.state.showFlavorOverlay,
                            showGeneticOverlay: false
                          });
                          e.stopPropagation();
                        }}
                      />
                      {this.state.showFlavorOverlay ? (
                        <>
                          <ChocolateDeepDiveOverlay
                            bottom={"110%"}
                            left={"50%"}
                            isDesktop={this.props.browser.greaterThan.medium}
                            title={"Flavor:"}
                            body={"delicious and distinct, with notes of spice, molasses and sweet cream."}
                            paddingBottom={"60px"}
                            paddingBottomMobile={"40px"}
                          />
                          <TriangleDiv top={"-35%"} left={"50%"} rotate={"180deg"} translateX={"-50%"}>
                            <TriangleImg src={triangleSVG} />
                          </TriangleDiv>
                        </>
                      ) : null}
                      <ShadowText
                        width={"200%"}
                        bottom={"-5%"}
                        left={"50%"}
                        fontSize={this.props.browser.greaterThan.medium ? "22px" : "16px"}
                      >
                        Flavor Profile
                      </ShadowText>
                    </Flex>
                  ),
                  name: "pulse-border-2",
                  width: 80,
                  x: 455,
                  y: 530,
                  zOffset: 231
                },
                {
                  classNames: "",
                  height: 27.5,
                  component: <img width={"100%"} height={"100%"} src={flavorHover} />,
                  name: "flavor-hover",
                  width: 27.5,
                  x: 481.5,
                  y: 557,
                  zOffset: 230
                }
              ]
            : []
        }
      />
    );
  }
}

export default connect(
  state => ({
    shareMenu: state.shareMenu,
    browser: state.browser
  }),
  null
)(ChocolateBar);
