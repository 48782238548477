import globeVideo from "../images/globe-zoom.mp4";

export const cacheGlobeVideoBlob = function() {
  if (window.globeVideoBlob) {
    return;
  }
  var req = new XMLHttpRequest();
  req.open("GET", globeVideo, true);
  req.responseType = "blob";

  req.onload = function() {
    // Onload is triggered even on 404
    // so we need to check the status code
    if (this.status === 200) {
      var videoBlob = this.response;
      var vid = URL.createObjectURL(videoBlob); // IE10+
      // Video is now downloaded
      // and we can set it as source on the video element
      //
      var video = document.createElement("video");
      video.src = vid;
      window.cacheVideoTag = video;
      window.globeVideoBlob = vid;
    }
  };
  req.onerror = function() {
    // Error
  };

  req.send();
};
