import map from "lodash/map";

import layer1 from "../images/chocolatebar/00.png";
import layer2 from "../images/chocolatebar/01.png";
import layer3 from "../images/chocolatebar/02.png";
import layer4 from "../images/chocolatebar/03.png";
import layer5 from "../images/chocolatebar/04.png";
import layer6 from "../images/chocolatebar/05.png";
import layer7 from "../images/chocolatebar/06.png";
import layer8 from "../images/chocolatebar/07.png";
import layer9 from "../images/chocolatebar/08.png";
import layer10 from "../images/chocolatebar/09.png";
import layer11 from "../images/chocolatebar/10.png";
import layer12 from "../images/chocolatebar/11.png";
import layer13 from "../images/chocolatebar/12.png";
import layer14 from "../images/chocolatebar/13.png";
import layer15 from "../images/chocolatebar/14.png";
import layer16 from "../images/chocolatebar/15.png";
import layer17 from "../images/chocolatebar/16.png";
import layer18 from "../images/chocolatebar/17.png";
import layer19 from "../images/chocolatebar/18.png";
import layer20 from "../images/chocolatebar/19.png";
import layer21 from "../images/chocolatebar/20.png";
import layer22 from "../images/chocolatebar/21.png";
import layer23 from "../images/chocolatebar/22.png";
import layer24 from "../images/chocolatebar/23.png";
import layer25 from "../images/chocolatebar/24.png";
import layer26 from "../images/chocolatebar/25.png";
import layer27 from "../images/chocolatebar/26.png";
import layer28 from "../images/chocolatebar/27.png";

const LAYER_WIDTH_HQ = 3000;
const LAYER_HEIGHT_HQ = 2319;

export const LAYER_WIDTH = 1000;
export const LAYER_HEIGHT = 773;

export const layers = [
  {
    autoScale: false,
    classNames: "",
    height: 777,
    image: layer28,
    name: "BACKGROUND",
    width: 1244,
    x: -122,
    y: -3,
    zOffset: -300
  },
  {
    autoScale: false,
    classNames: "",
    height: 677,
    image: layer27,
    name: "TREE",
    width: 479,
    x: 203,
    y: 0,
    zOffset: -250
  },
  {
    autoScale: false,
    classNames: "",
    height: 248,
    image: layer26,
    name: "leaf",
    width: 152,
    x: 458,
    y: 121,
    zOffset: -340
  },
  {
    autoScale: false,
    classNames: "",
    height: 239,
    image: layer25,
    name: "palmeira",
    width: 122,
    x: 390,
    y: 101,
    zOffset: -290
  },
  {
    autoScale: false,
    classNames: "",
    height: 516,
    image: layer24,
    name: "right_side_back_leaves",
    width: 334,
    x: 524,
    y: 242,
    zOffset: -270
  },
  {
    autoScale: false,
    classNames: "",
    height: 367,
    image: layer23,
    name: "left_side_back_leaves",
    width: 410,
    x: 72,
    y: 363,
    zOffset: -240
  },
  {
    autoScale: false,
    classNames: "",
    height: 210,
    image: layer22,
    name: "palmeira-2",
    width: 155,
    x: 454,
    y: 563,
    zOffset: -220
  },
  {
    autoScale: false,
    classNames: "",
    height: 290,
    image: layer21,
    name: "plant",
    width: 229,
    x: 501,
    y: 288,
    zOffset: -60
  },
  {
    autoScale: false,
    classNames: "",
    height: 613,
    image: layer20,
    name: "bananeira",
    width: 395,
    x: 112,
    y: 80,
    zOffset: -40
  },
  {
    autoScale: false,
    classNames: "",
    height: 194,
    image: layer19,
    name: "plant-2",
    width: 171,
    x: 181,
    y: 554,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 275,
    image: layer18,
    name: "plant-3",
    width: 220,
    x: 527,
    y: 438,
    zOffset: 25
  },
  {
    autoScale: false,
    classNames: "",
    height: 190,
    image: layer17,
    name: "plantpink",
    width: 154,
    x: 536,
    y: 332,
    zOffset: 35
  },
  {
    autoScale: false,
    classNames: "",
    height: 166,
    image: layer16,
    name: "plant-4",
    width: 118,
    x: 281,
    y: 505,
    zOffset: 45
  },
  {
    autoScale: false,
    classNames: "",
    height: 189,
    image: layer15,
    name: "plant-5",
    width: 191,
    x: 499,
    y: 197,
    zOffset: 55
  },
  {
    autoScale: false,
    classNames: "",
    height: 165,
    image: layer14,
    name: "monsterapurple",
    width: 166,
    x: 300,
    y: 446,
    zOffset: 70
  },
  {
    autoScale: false,
    classNames: "",
    height: 135,
    image: layer13,
    name: "monstera",
    width: 126,
    x: 600,
    y: 585,
    zOffset: 85
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 424,
    image: layer12,
    name: "glow",
    width: 395,
    x: 325,
    y: 204,
    zOffset: 100
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 387,
    image: layer11,
    name: "rays",
    width: 481,
    x: 269,
    y: 118,
    zOffset: 110
  },
  {
    autoScale: false,
    classNames: "glow sparkles",
    height: 470,
    image: layer10,
    name: "sparkles",
    width: 481,
    x: 256,
    y: 38,
    zOffset: 120
  },
  {
    autoScale: false,
    classNames: "",
    height: 455,
    image: layer9,
    name: "Chocolate_bar",
    width: 259,
    x: 370,
    y: 193,
    zOffset: 140
  },
  {
    autoScale: false,
    classNames: "",
    height: 141,
    image: layer8,
    name: "monstera-2",
    width: 130,
    x: 370,
    y: 565,
    zOffset: 180
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 122,
    image: layer7,
    name: "yellow_glow",
    width: 202,
    x: 370,
    y: 193,
    zOffset: 141
  },
  {
    autoScale: false,
    classNames: "glow sparkles",
    height: 121,
    image: layer6,
    name: "yellow_sparkles",
    width: 202,
    x: 370,
    y: 193,
    zOffset: 142
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 302,
    image: layer5,
    name: "GLOW-2",
    width: 240,
    x: 377,
    y: 267,
    zOffset: 143
  },
  {
    autoScale: false,
    classNames: "",
    height: 409,
    image: layer4,
    name: "VINE",
    width: 317,
    x: 332,
    y: 150,
    zOffset: 160
  },
  {
    autoScale: false,
    classNames: "",
    height: 184,
    image: layer3,
    name: "plant-6",
    width: 184,
    x: 486,
    y: 565,
    zOffset: 165
  },
  {
    autoScale: false,
    classNames: "",
    height: 105,
    image: layer2,
    name: "plant-7",
    width: 59,
    x: 577,
    y: 564,
    zOffset: 170
  },
  {
    autoScale: false,
    classNames: "",
    height: 143,
    image: layer1,
    name: "plant-8",
    width: 101,
    x: 339,
    y: 542,
    zOffset: 190
  }
];
