import React, { Component } from "react";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { connect } from "react-redux";
import VideoInteractive from "./video";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import Iframe from "react-iframe";
import { RiverNextButton } from "./animals";
import { AnimalText, Play360Button, CoverImgStyled, CoverImg, GalleryButton } from "./components";
import { withRouter } from "react-router-dom";
import camilloProfilePicIMG from "../images/camillo-profile-pic.jpg";
import { ProfilePic } from "./components";

import riverMapIMG from "../images/river-map.jpeg";
import riverMapAnnotationsSVG from "../images/annotated-river-map-expanded.svg";

import riverVideo from "../images/drone-boat-warp-flipped-fullspeed-v2-shrunk.mp4";
import fruitPeelerSmallIMG from "../images/fruit-pealing-small.jpg";
import { VideoWrapper, VideoContainer } from "./video";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);
class HeroCamillo extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.videoInnerRef = React.createRef();
    this.firstTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  render() {
    return (
      <>
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <Flex
            position={"absolute"}
            top={["14.6vh", "14.6vh", "14.6vh", "31vh"]}
            width={"100%"}
            justifyContent={"center"}
          >
            <Flex
              flexDirection={["column", "column", "column", "row"]}
              width={["83.7%", "83.7%", "83.7%", "76%"]}
              justifyContent={"flex-start"}
            >
              <ProfilePic src={camilloProfilePicIMG} />
              <Flex
                flexDirection={"column"}
                pt={["23px", "23px", "23px", "0px"]}
                pl={["0px", "0px", "0px", "58px"]}
                flex={"1 1000 auto"}
              >
                <GTAmerica
                  fontSize={["21px", "21px", "21px", "36px"]}
                  lineHeight={["23px", "23px", "23px", "46px"]}
                  fontStyle={["normal", "normal", "normal", "normal"]}
                  textAlign={["left", "left", "left", "left"]}
                  fontWeight={[400]}
                >
                  "First of all, we are protecting our forest. We harvest what the forest gives us, without having to
                  destroy it. And we are giving work to people who have never had a chance to earn a penny."
                </GTAmerica>
                <GTAmerica
                  pt={["30px"]}
                  fontSize={["21px", "21px", "21px", "28px"]}
                  lineHeight={["27px", "27px", "27px", "36px"]}
                  fontStyle={["normal", "normal", "normal", "normal"]}
                  textAlign={["left", "left", "left", "left"]}
                  fontWeight={[400]}
                >
                  –Camilo
                </GTAmerica>
                <GTAmerica
                  pt={["16px"]}
                  fontSize={["16px", "16px", "16px", "21px"]}
                  lineHeight={["normal", "normal", "normal", "normal"]}
                  fontStyle={["normal", "normal", "normal", "normal"]}
                  textAlign={["left", "left", "left", "left"]}
                  fontWeight={[400]}
                >
                  Director of Production of the Mapiá and Middle <br /> Purus Agroextractivist Cooperative
                </GTAmerica>
              </Flex>
            </Flex>
          </Flex>
        </FullScreen>
        <Flex bg={"white"} width={"100%"} height={"100%"} />
      </>
    );
  }
}

export default (HeroCamillo = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(HeroCamillo));
