import React, { Component } from "react";
import { ID } from "../utils/id";
import { CoverImg, PrimaryText } from "./components";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { GTAmerica } from "../components/typography.js";
import { chapters } from "./chapters";

import chocolateIMG from "../images/chocolate.jpeg";

import ChocolateBar from "./chocolateBarInteractive";

export const FirstText = ({ ...props }) => (
  <PrimaryText
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
  >
    Unlike 99.9% of cacao, I am not cultivated. I grow deep in the Amazon,
    reachable only by boat and unmapped foot trails.
  </PrimaryText>
);

/*
export const chocolateBarModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 1,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [chocolateMiddleSub1],
      bottom: [<CoverImg src={chocolateIMG} />],
    },
  ],
};
*/

export const chocolateBarModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 0,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [FirstText],
      bottom: [<ChocolateBar />]
    }
  ]
};
