import { modules } from "../modules";
import { moduleArrayIndex, subModulePercent } from "../utils/scroll";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const percent = "percent";
const timestamp = "timestamp";
const lockinTimestamp = "lockinTimestamp";
const nextLockinDirectionUp = "nextLockinDirectionUp";
export const defaultState = {
  percent: 0,
  timestamp: (Date.now() / 1000) | 0,
  lockinTimestamp: (Date.now() / 1000) | 0,
  nextLockinDirectionUp: false
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "FORCE_SECONDARY_SCROLL_PERCENT":
      newState[percent] = action.percent;
      newState[timestamp] = (Date.now() / 1000) | 0;
      return newState;
    case "FORCE_SECONDARY_TO_NEXT_MODULE":
      let boundedScrollPercent = Math.min(action.currentScrollPercent, 1);
      let moduleIndex = moduleArrayIndex(boundedScrollPercent, action.modules_length);
      newState[percent] = (moduleIndex + 1.5) / action.modules_length;
      newState[timestamp] = (Date.now() / 1000) | 0;
      return newState;
    case "FORCE_SECONDARY_TO_NEXT_LOCKIN":
      newState[nextLockinDirectionUp] = false;
      newState[lockinTimestamp] = (Date.now() / 1000) | 0;
      return newState;
    case "FORCE_SECONDARY_TO_PREVIOUS_LOCKIN":
      newState[nextLockinDirectionUp] = true;
      newState[lockinTimestamp] = (Date.now() / 1000) | 0;
      return newState;
    default:
      return state;
  }
};
