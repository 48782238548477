import React, { Component } from "react";
import styled from "styled-components";

import {
  textAlign,
  space,
  width,
  fontSize,
  color,
  height,
  borders,
  fontWeight,
  lineHeight,
  alignSelf,
  fontStyle,
  display,
  flex
} from "styled-system";

export const GTAmericaStyled = styled.div`
  ${space}
  ${flex}
  ${width}
  ${height}
  ${color}
  ${borders}
  ${fontSize}
  ${lineHeight}
  ${alignSelf}
  ${fontWeight}
  ${textAlign}
  ${display}
  ${fontStyle}

  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : "normal")};
  transform: ${props => (props.transform ? props.transform : "none")};
  text-shadow: ${props => (props.textShadow ? props.textShadow : "none")};
  font-family:GTAmerica;
  position:relative;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
`;

export const GTAmerica = ({ ...props }) => <GTAmericaStyled {...props}>{props.children}</GTAmericaStyled>;
