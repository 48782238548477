import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import forestIMG from "../images/forest-360.jpeg";
import missCacaoIMG from "../images/misscacao.jpeg";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const Forest360Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 78.38%
  );
`;

const MissCacao360Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #6a5d5a 0%,
    #33231f 0.01%,
    rgba(51, 35, 31, 0) 50.28%
  );
`;

const ForestCoverImgStyled = styled(CoverImgStyled)`
  object-position: 100% 50%;
`;
const ForestCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <ForestCoverImgStyled src={props.src} />
  </Flex>
);
export const forestModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 4,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <PlayButton
          position={"absolute"}
          bottom={["17vh"]}
          clickHandler={() => console.log("navigate")}
        />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            HEAR CAMILO’S MESSAGE
            <br />
            TO THE WORLD
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [
        <Flex
          flexDirection={"column"}
          px={["18px", "18px", "18px", "5.1%"]}
          pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
          width={["auto", "auto", "auto", "40%"]}
        >
          <GTAmerica
            fontSize={["21px", "21px", "21px", "36px"]}
            lineHeight={["27px", "27px", "27px", "40px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            " First of all, we are protecting our forest. We harvest what the
            forest gives us, without having to destroy it. And we are giving
            work to people who have never had a chance to earn a penny."
          </GTAmerica>
          <GTAmerica
            pt={["30px"]}
            fontSize={["21px", "21px", "21px", "28px"]}
            lineHeight={["27px", "27px", "27px", "36px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            –Camilo
          </GTAmerica>
          <GTAmerica
            pt={["16px"]}
            fontSize={["16px", "16px", "16px", "21px"]}
            lineHeight={["normal", "normal", "normal", "normal"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            Director of Production of the Mapiá and Middle <br /> Purus
            Agroextractivist Cooperative
          </GTAmerica>
        </Flex>
      ],
      bottom: [<ForestCoverImg src={forestIMG} />, <Forest360Gradient />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"white"}>
          Everything we have comes from Mother nature.
          <br />
          <br />
          And we are at a crossroads. The future of life depends on our actions.
        </Text>
      ],
      bottom: [<CoverImg src={missCacaoIMG} />, <MissCacao360Gradient />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"white"}>
          Our journey ends at the beginning.
          <br />
          <br />
          Remember all that you’ve seen and know that the choice is yours to
          change it.
        </Text>
      ],
      bottom: [<CoverImg src={missCacaoIMG} />, <MissCacao360Gradient />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"white"}>
          Together we can return the planet to balance and harmony.
        </Text>
      ],
      bottom: [<CoverImg src={missCacaoIMG} />, <MissCacao360Gradient />]
    }
  ]
};
