import { modules } from "../modules";

import { moduleArrayIndex, subModulePercent } from "../utils/scroll";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const percent = "percent";
const chapterNumber = "chapterNumber";
const headerVisibility = "headerVisibility";
const headerColor = "headerColor";
const headerColor_default = "white";
export const defaultState = {
  moduleIndex: 0,
  percent: 0,
  subModulePercent: 0,
  chapterNumber: 0,
  headerVisibility: true,
  headerColor: headerColor_default
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "UPDATE_SECONDARY_SCROLL_PERCENT":
      newState[percent] = action.percent;
      let modules = action.modules;

      let moduleIndex = moduleArrayIndex(action.percent, modules);
      newState["moduleIndex"] = moduleIndex;
      let boundedScrollPercent = Math.max(Math.min(action.percent, 1), 0);
      newState["subModulePercent"] = subModulePercent(boundedScrollPercent, modules);

      return newState;
    default:
      return state;
  }
};
