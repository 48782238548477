import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PulseCircles } from "./components";
import { ProfilePic } from "./components";
import { PulseCirclesFast } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import { forceToNextSecondarySubModule } from "../actions/forceSecondaryScrollPercent.js";
import { WhitePrimarySmallButton } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";

import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { LargePlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import camilloProfilePicIMG from "../images/camillo-profile-pic.jpg";
import camillosDaughterIMG from "../images/camillos-daughter.jpeg";
import moisesIMG from "../images/moises.jpeg";
import schoolIMG from "../images/school.png";
import ladiesIMG from "../images/ladies-peeling.jpeg";
import nageIMG from "../images/nage.jpeg";
import daimeIMG from "../images/daime.png";
import franciscaProfilePicIMG from "../images/francisca-profile.jpg";
import riverLandingIMG from "../images/river-landing.jpeg";
import { educationModules } from "./index";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "52%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const SchoolGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)
  );
`;

const MoisesGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 35.33%,
    rgba(0, 0, 0, 0.9) 100%
  );
`;
const NextButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <ClickCursorFlex
      pointerEvents={"auto"}
      onClick={() => {
        props.forceToNextSecondarySubModule(
          props.secondaryScrollPercent.percent,
          educationModules.length
        );
      }}
    >
      <PulseCircles smallSize={"40px"} largeSize={"60px"} />
      <WhitePrimarySmallButton>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={["9px", "9px", "9px", "12px"]}
          height={["13px", "13px", "13px", "22px"]}
        >
          <DownArrowOldSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "black"
            }}
          />
        </Flex>
      </WhitePrimarySmallButton>
    </ClickCursorFlex>
  </Flex>
);

const NextButton = connect(
  state => ({
    secondaryScrollPercent: state.secondaryScrollPercent
  }),
  dispatch => ({
    forceToNextSecondarySubModule: bindActionCreators(
      forceToNextSecondarySubModule,
      dispatch
    )
  })
)(NextButtonComponent);

const BackButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Link to={{ pathname: "/", startPercent: props.scrollPercent.percent }}>
      <ClickCursorFlex pointerEvents={"auto"}>
        <PulseCircles smallSize={"40px"} largeSize={"60px"} />
        <WhitePrimarySmallButton>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            width={["9px", "9px", "9px", "12px"]}
            height={["13px", "13px", "13px", "22px"]}
          >
            <DownArrowOldSVG
              style={{
                width: "100%",
                height: "100%",
                fill: "black"
              }}
            />
          </Flex>
        </WhitePrimarySmallButton>
      </ClickCursorFlex>
    </Link>
  </Flex>
);

const BackButton = connect(
  state => ({
    scrollPercent: state.scrollPercent
  }),
  null
)(BackButtonComponent);

const BackText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Back to Community Map
    </GTAmerica>
  </Flex>
);

const NextText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Next Screen
    </GTAmerica>
  </Flex>
);

const CamillosDaughterCoverImgStyled = styled(CoverImgStyled)`
  @media screen and (min-width: 768px) {
    transform: translateX(25%);
  }
`;
const CamillosDaughterCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <CamillosDaughterCoverImgStyled src={props.src} />
  </Flex>
);

const MoisesCoverImgStyled = styled(CoverImgStyled)`
  object-position: 70% 50%;
`;
const MoisesCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <MoisesCoverImgStyled src={props.src} />
  </Flex>
);

const NageCoverImgStyled = styled(CoverImgStyled)`
  @media screen and (min-width: 768px) {
    transform: translateX(30%);
  }
`;
const NageCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <NageCoverImgStyled src={props.src} />
  </Flex>
);

export const educationModule = {
  id: ID(),
  scenes: [
    {
      top_slide: [],
      top_fade: [<NextText />, <NextButton />],
      middle: [
        <Text color={"white"}>
          The local school goes up to the fourth grade. Classrooms are
          essentially bare, except for desks. Government funded teachers come
          and go, leaving the community with few options to educate their kids.
        </Text>
      ],
      bottom: [<CoverImg src={schoolIMG} />, <SchoolGradient />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          position={"absolute"}
          top={["14.6vh", "14.6vh", "14.6vh", "31vh"]}
          width={"100%"}
          justifyContent={"center"}
        >
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            width={["83.7%", "83.7%", "83.7%", "76%"]}
            justifyContent={"flex-start"}
          >
            <ProfilePic src={camilloProfilePicIMG} />
            <Flex
              flexDirection={"column"}
              pt={["23px", "23px", "23px", "0px"]}
              pl={["0px", "0px", "0px", "58px"]}
              flex={"1 1000 auto"}
            >
              <GTAmerica
                fontSize={["21px", "21px", "21px", "36px"]}
                lineHeight={["23px", "23px", "23px", "46px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                “What I learned from my parents and grandparents is that when we
                do not know how to read and write, we at least learn to respect
                people, to live worthy of their work, this is one of the best
                educations you can have.”
              </GTAmerica>
              <GTAmerica
                pt={["30px"]}
                fontSize={["21px", "21px", "21px", "28px"]}
                lineHeight={["27px", "27px", "27px", "36px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                –Camilo
              </GTAmerica>
              <GTAmerica
                pt={["16px"]}
                fontSize={["16px", "16px", "16px", "21px"]}
                lineHeight={["normal", "normal", "normal", "normal"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                Director of Production of the Mapiá and Middle <br /> Purus
                Agroextractivist Cooperative
              </GTAmerica>
            </Flex>
          </Flex>
        </Flex>
      ],
      bottom: [<Flex bg={"white"} width={"100%"} height={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <LargePlayButton />
          <GTAmerica
            pt={"17px"}
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            HEAR MARIA DE JESUS TALK
            <br />
            ABOUT EDUCATION
          </GTAmerica>
        </Flex>
      ],
      middle: [],
      bottom: [<CoverImg src={ladiesIMG} />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          flexDirection={"column"}
          px={["18px", "18px", "18px", "5.1%"]}
          pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
          width={["auto", "auto", "auto", "52%"]}
        >
          <GTAmerica
            fontSize={["21px", "21px", "21px", "36px"]}
            lineHeight={["27px", "27px", "27px", "40px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            “I spent four years in Boca do Acre going to school. There, I
            learned how to read, to write. <br />
            <br />
            Eventually I returned here because unlike the city, we are not
            threatened by gangs or fear being run over in the streets.”
          </GTAmerica>
          <GTAmerica
            pt={["30px"]}
            fontSize={["21px", "21px", "21px", "28px"]}
            lineHeight={["27px", "27px", "27px", "36px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            –Camila
          </GTAmerica>
          <GTAmerica
            pt={["16px"]}
            fontSize={["16px", "16px", "16px", "21px"]}
            lineHeight={["normal", "normal", "normal", "normal"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            Camilo's Daughter
          </GTAmerica>
        </Flex>
      ],
      bottom: [
        <CamillosDaughterCoverImg src={camillosDaughterIMG} />,
        <Flex
          width={["0%", "0%", "0%", "25%"]}
          height={["0%", "0%", "0%", "100%"]}
          bg={"black"}
        />
      ]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          flexDirection={"column"}
          px={["18px", "18px", "18px", "5.1%"]}
          width={["auto", "auto", "auto", "52%"]}
          height={[
            "calc(100% - 80px)",
            "calc(100% - 80px)",
            "calc(100% - 80px)",
            "calc(100% - 120px)"
          ]}
          justifyContent={"flex-end"}
        >
          <GTAmerica
            fontSize={["21px", "21px", "21px", "36px"]}
            lineHeight={["27px", "27px", "27px", "40px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            “Here we are free, to go wherever we want, to go to the forest, go
            up river, go to the lagoons. We do not feel any danger. The main
            difficulty is the carapanã, a little mosquito that attacks us.”
          </GTAmerica>
          <GTAmerica
            pt={["30px"]}
            fontSize={["21px", "21px", "21px", "28px"]}
            lineHeight={["27px", "27px", "27px", "36px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            –Moises
          </GTAmerica>
          <GTAmerica
            pt={["16px"]}
            fontSize={["16px", "16px", "16px", "21px"]}
            lineHeight={["normal", "normal", "normal", "normal"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            Camilo's Son
          </GTAmerica>
        </Flex>
      ],
      bottom: [<MoisesCoverImg src={moisesIMG} />, <MoisesGradient />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"black"}>
          In Purus mosquito illnesses, snake bites and bad injuries can quickly
          turn deadly. Medical care is 3 hours away by boat and the cost of
          petrol for the ride is extremely high. Most women deliver their babies
          without medical care.
          <br /> <br /> Prayer and Daime (Ayahuasca) is the most widely used
          medicine in the community.
        </Text>
      ],
      bottom: [<Flex bg={"white"} height={"100%"} width={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [],
      bottom: [<CoverImg src={riverLandingIMG} />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          flexDirection={"column"}
          px={["18px", "18px", "18px", "5.1%"]}
          width={["auto", "auto", "auto", "52%"]}
          height={[
            "calc(100% - 80px)",
            "calc(100% - 80px)",
            "calc(100% - 80px)",
            "calc(100% - 120px)"
          ]}
          justifyContent={"flex-end"}
        >
          <GTAmerica
            fontSize={["21px", "21px", "21px", "36px"]}
            lineHeight={["27px", "27px", "27px", "40px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            “Being sick here is bad, because there is no doctor here to be taken
            to. When we have a boat, we go to the city. However, when there is
            not, we stay here waiting for someone to decide to go and buy gas.”
          </GTAmerica>
          <GTAmerica
            pt={["30px"]}
            fontSize={["21px", "21px", "21px", "28px"]}
            lineHeight={["27px", "27px", "27px", "36px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            –Francisca
          </GTAmerica>
          <GTAmerica
            pt={["16px"]}
            fontSize={["16px", "16px", "16px", "21px"]}
            lineHeight={["normal", "normal", "normal", "normal"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            Community member
          </GTAmerica>
        </Flex>
      ],
      bottom: [
        <NageCoverImg src={nageIMG} />,
        <Flex
          width={["0%", "0%", "0%", "30%"]}
          height={["0%", "0%", "0%", "100%"]}
          bg={"black"}
        />
      ]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          position={"absolute"}
          top={["14.6vh", "14.6vh", "14.6vh", "31vh"]}
          width={"100%"}
          justifyContent={"center"}
        >
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            width={["83.7%", "83.7%", "83.7%", "76%"]}
            justifyContent={"flex-start"}
          >
            <ProfilePic src={franciscaProfilePicIMG} />
            <Flex
              flexDirection={"column"}
              pt={["23px", "23px", "23px", "0px"]}
              pl={["0px", "0px", "0px", "58px"]}
              flex={"1 1000 auto"}
            >
              <GTAmerica
                fontSize={["21px", "21px", "21px", "36px"]}
                lineHeight={["23px", "23px", "23px", "46px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                “I started to suffer to have the baby, I had no midwife, they
                found a midwife in the field, it was far, almost two hours
                away...My mother-in-law who took it, gave me the Daime, then I
                began to vomit, provoke the contractions, and then it was born.”
              </GTAmerica>
              <GTAmerica
                pt={["30px"]}
                fontSize={["21px", "21px", "21px", "28px"]}
                lineHeight={["27px", "27px", "27px", "36px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                –Francisca
              </GTAmerica>
              <GTAmerica
                pt={["16px"]}
                fontSize={["16px", "16px", "16px", "21px"]}
                lineHeight={["normal", "normal", "normal", "normal"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                Community member
              </GTAmerica>
            </Flex>
          </Flex>
        </Flex>
      ],
      bottom: [<Flex bg={"white"} width={"100%"} height={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [<BackText />, <BackButton />],
      middle: [
        <Flex
          flexDirection={"column"}
          px={["18px", "18px", "18px", "5.1%"]}
          pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
          width={["auto", "auto", "auto", "52%"]}
        >
          <GTAmerica
            fontSize={["21px", "21px", "21px", "36px"]}
            lineHeight={["27px", "27px", "27px", "40px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            “We drink Daime, we do healing work, we pray for healing, we have
            the healing hymnal.”
          </GTAmerica>
          <GTAmerica
            pt={["30px"]}
            fontSize={["21px", "21px", "21px", "28px"]}
            lineHeight={["27px", "27px", "27px", "36px"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            –Francisca
          </GTAmerica>
          <GTAmerica
            pt={["16px"]}
            fontSize={["16px", "16px", "16px", "21px"]}
            lineHeight={["normal", "normal", "normal", "normal"]}
            fontStyle={["normal", "normal", "normal", "normal"]}
            textAlign={["left", "left", "left", "left"]}
            fontWeight={[400]}
            color={"white"}
          >
            Community Member
          </GTAmerica>
        </Flex>
      ],
      bottom: [<CoverImg src={daimeIMG} />]
    }
  ]
};
