import { bindActionCreators } from "redux";
import { connect } from "react-redux";

export const defaultState = {
  deltax: 0,
  deltay: 0
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "UPDATE_SWIPE_DELTAS":
      newState["deltax"] = action.deltax;
      newState["deltay"] = action.deltay;
      return newState;
    default:
      return state;
  }
};
