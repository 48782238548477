const message = "message";
export const defaultState = { message: {} };

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "IFRAME_MESSAGE":
      newState[message] = action[message];
      return newState;
    default:
      return state;
  }
};
