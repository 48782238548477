import React, { Component } from "react";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { connect } from "react-redux";
import VideoInteractive from "./video";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import IFrameWithLoading from "../components/iframeWithLoading";
import Iframe from "react-iframe";
import { RiverNextButton } from "./animals";
import { AnimalText, CoverImg, CoverImgResponsive } from "./components";
import river360Loading from "../images/river360loading.jpg";
import { cacheGlobeVideoBlob } from "../utils/cacheGlobeVideo.js";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const RiverNextText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Next Screen
    </GTAmerica>
  </Flex>
);

class HeroRiver360 extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.firstTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
    cacheGlobeVideoBlob();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenLite.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 100;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  render() {
    return (
      <>
        {this.props.global.debug ? (
          <IFrameWithLoading url="http://localhost:8081/index.html" id="river">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={river360Loading} />
          </IFrameWithLoading>
        ) : (
          <IFrameWithLoading url="https://wild-cacao.org/river360/index.html" id="river">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={river360Loading} />
          </IFrameWithLoading>
        )}
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <RiverNextButton mobileText={"Next Screen"} desktopText={"Next Screen"} />
          <AnimalText color={["black", "black", "black", "black"]}>
            ...to the banks of the Purus River in the heart of the Amazon.
          </AnimalText>
        </FullScreen>
      </>
    );
  }
}

export default (HeroRiver360 = connect(
  state => ({
    global: state.global,
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(HeroRiver360));
