import { bindActionCreators } from "redux";
import { connect } from "react-redux";

export const defaultState = {
  x: 0,
  y: 0,
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "UPDATE_MOUSE_XY":
      newState["x"] = action.x;
      newState["y"] = action.y;
      return newState;
    default:
      return state;
  }
};
