import React, { Component } from "react";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition, Transitio } from "react-transition-group";
import styled from "styled-components";
import { GTAmerica, GTAmericaStyled } from "../components/typography.js";

import YellowseedBackgroundIMG from "../images/yellowseed-sponsor.jpeg";
import ICRBackgroundIMG from "../images/icr-sponsor.jpeg";
import LandmarkBackgroundIMG from "../images/landmark-sponsor.png";
import YellowseedLogoIMG from "../images/yellowseed-sponsor-logo.png";
import ICRLogoIMG from "../images/icr-sponsor-logo.png";
import LandmarkLogoIMG from "../images/landmark-sponsor-logo.png";
import { useSwipeable, Swipeable } from "react-swipeable";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { CSSPlugin, AttrPlugin, TweenLite, Power2 } from "gsap/all";
import { ReactComponent as LeftArrowSVG } from "../images/left-arrow.svg";
import { ReactComponent as RightArrowSVG } from "../images/right-arrow.svg";
import { CoverImg, PrimaryText } from "./components";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];
const SwipeManager = styled.div`
  pointer-events: auto;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: -30px;
  bottom: -20px;
  z-index: 0;
  overflow: ${props => (props.overflowProp ? props.overflowProp : "scroll")};
`;

const LogoImage = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  background-image: url(${props => (props.src ? props.src : "")});
`;

const CTAText = styled(GTAmericaStyled)`
  letter-spacing: 0.15em;
  font-weight: bold;
  border-bottom: 2px solid #10cfab;
  transition: all 0.3s;
  &:hover {
    color: #10cfab;
  }
`;

export const Sponsor = ({ ...props }) => (
  <>
    <Flex height={"100%"} minWidth={"100vw"} bg={"blue"} className={"sponsor-component"}>
      <Flex
        flexDirection={["column", "row"]}
        position={"absolute"}
        top={"0px"}
        left={"0px"}
        right={"0px"}
        bottom={["0px", "-15px"]}
        bg={"white"}
      >
        <Flex width={["100%", "41.66%"]} minWidth={["100%", "41.66%"]} height={["32.7%", "100%"]}>
          <CoverImg objectPositionY={"20%"} src={props.backgroundImage} />
        </Flex>
        <Flex
          justifyContent={"center"}
          width={"100%"}
          alignItems={"center"}
          ml={["0%", "5%"]}
          mr={["0%", "12%"]}
          flexDirection={"column"}
        >
          <Flex pt={["25px"]} height={"7vh"} width={["85%", "100%"]}>
            <LogoImage src={props.logoImage} />
          </Flex>
          <GTAmerica
            py={["25px", "45px"]}
            width={["85%", "100%"]}
            fontSize={["24px", "40px"]}
            lineHeight={["30px", "50px"]}
          >
            {props.heading}
          </GTAmerica>
          <GTAmerica
            fontSize={["16px", "21px"]}
            width={["85%", "100%"]}
            lineHeight={["22px", "29px"]}
            mb={["20px", "25px"]}
          >
            {props.description}
          </GTAmerica>
          <Flex pointerEvents={"all"} width={["85%", "100%"]} mt={["15px", "25px"]} mb={["20px", "25px"]}>
            <a href={props.link} target="_blank">
              {props.heading ==
              "Yellow Seed connects consumers to the people and place at the heart of a product’s origin" ? (
                <CTAText fontSize={["11px", "14px"]} lineHeight={["17px", "21px"]}>
                  VISIT SPONSOR WEBSITE
                </CTAText>
              ) : (
                <CTAText fontSize={["11px", "14px"]} lineHeight={["17px", "21px"]}>
                  VISIT PARTNER WEBSITE
                </CTAText>
              )}
            </a>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </>
);

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  overflow: hidden;
  background-color: ${props => (props.bg ? props.bg : "gray")};
`;

const SponsorContainer = styled(Flex)`
  overflow-x: scroll;
`;

class SponsorsGroup extends Component {
  state = {
    index: 0
  };
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.gutter = React.createRef();
  }
  componentDidMount() {
    // hello
  }
  componentWillReceiveProps(nextProps) {
    // hello
    //<Sponsor backgroundImage={} logoImage={} heading={} description={} link={}/>
  }
  scrollToIndex(index) {
    this.container.current.scrollLeft = (index * this.container.current.scrollWidth) / 3;
  }
  transformToIndex(index) {
    //this.container.current.scrollLeft = (index * this.container.current.scrollWidth) / 3;

    TweenLite.to(this.gutter.current, 1, {
      x: -1 * index * this.container.current.offsetWidth + "px",
      ease: Power2.easeInOut,
      onComplete: () => {}
    });
  }
  render() {
    return (
      <Swipeable
        className={"mobile-swipeable"}
        onSwipedLeft={eventData => this.setState({ index: Math.max(this.state.index - 1, 0) })}
        onSwipedRight={eventData => this.setState({ index: Math.min(this.state.index + 1, 2) })}
      >
        <SponsorContainer
          onScroll={event => {
            if (
              event.currentTarget.scrollLeft > 0 &&
              event.currentTarget.scrollLeft <= this.container.current.offsetWidth * 1
            ) {
              this.setState({ index: 1 });
            } else if (event.currentTarget.scrollLeft > this.container.current.offsetWidth * 1) {
              this.setState({ index: 2 });
            } else {
              this.setState({ index: 0 });
            }
          }}
          pointerEvents={"all"}
          innerRef={this.container}
          className={"sponsor-container"}
          width={"100%"}
          height={"100%"}
        >
          <Flex className={"gutter"} innerRef={this.gutter} width={"300%"} height={"100%"}>
            <Sponsor
              logoImage={YellowseedLogoIMG}
              backgroundImage={YellowseedBackgroundIMG}
              link={"https://www.yellow-seed.org/"}
              heading={"Yellow Seed connects consumers to the people and place at the heart of a product’s origin"}
              description={"We create authentic storytelling experiences that communicate the true value of a product."}
            />
            <Sponsor
              logoImage={LandmarkLogoIMG}
              backgroundImage={LandmarkBackgroundIMG}
              link={"https://wildchocolate.org"}
              heading={"Landmark is creating an ecosystem for Wild Cacao to thrive"}
              description={
                "We increase awareness of the benefits of Wild Cacao to communities around the world, with the goal of preserving forests and helping people thrive."
              }
            />
            <Sponsor
              logoImage={ICRLogoIMG}
              backgroundImage={ICRBackgroundIMG}
              link={"https://business.gwu.edu/research/institute-corporate-responsibility"}
              heading={"The Institute for Corporate Social Responsiblity focuses on sustainable supply chains"}
              description={
                "We are devoted to the development and dissemination of scholarship, including research and teaching to improve the sustainability of supply chains."
              }
            />
          </Flex>
        </SponsorContainer>

        {this.props.browser.greaterThan.medium ? (
          <Flex
            position={"absolute"}
            bottom={"0px"}
            right={"0px"}
            height={"20.5vh"}
            width={"50%"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <ClickCursorFlex
              onClick={() => {
                const newIndex = Math.max(0, this.state.index - 1);
                this.transformToIndex(newIndex);
                this.setState({ index: newIndex });
              }}
              pl={"80px"}
              pr={"40px"}
              height={"60px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <LeftArrowSVG
                style={{
                  width: "19px",
                  fill: "#10CFAB"
                }}
              />
              <GTAmerica pl={"12px"} color={"#10CFAB"} fontSize={["18px"]} lineHeight={["23px"]}>
                Prev
              </GTAmerica>
            </ClickCursorFlex>
            <ClickCursorFlex
              onClick={() => {
                const newIndex = Math.min(2, this.state.index + 1);
                this.transformToIndex(newIndex);
                this.setState({ index: newIndex });
              }}
              pl={"40px"}
              pr={"80px"}
              height={"60px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <GTAmerica pr={"12px"} color={"#10CFAB"} fontSize={["18px"]} lineHeight={["23px"]}>
                Next
              </GTAmerica>
              <RightArrowSVG
                style={{
                  width: "19px",
                  fill: "#10CFAB"
                }}
              />
            </ClickCursorFlex>
          </Flex>
        ) : (
          <Flex
            position={"absolute"}
            width={"100%"}
            height={"42px"}
            bottom={"15px"}
            left={"0px"}
            justifyContent={"center"}
            align={"center"}
          >
            <ClickCursorFlex
              onClick={() => {
                const newIndex = 0;
                this.scrollToIndex(newIndex);
                this.setState({ index: newIndex });
              }}
              height={"42px"}
              width={"28px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Circle bg={this.state.index == 0 ? "#10CFAB" : "#E9E9E9"} />
            </ClickCursorFlex>
            <ClickCursorFlex
              onClick={() => {
                const newIndex = 1;
                this.scrollToIndex(newIndex);
                this.setState({ index: newIndex });
              }}
              height={"42px"}
              width={"28px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Circle bg={this.state.index == 1 ? "#10CFAB" : "#E9E9E9"} />
            </ClickCursorFlex>
            <ClickCursorFlex
              onClick={() => {
                const newIndex = 2;
                this.scrollToIndex(newIndex);
                this.setState({ index: newIndex });
              }}
              height={"42px"}
              width={"28px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Circle bg={this.state.index == 2 ? "#10CFAB" : "#E9E9E9"} />
            </ClickCursorFlex>
          </Flex>
        )}
      </Swipeable>
    );
  }
}

export default (SponsorsGroup = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(SponsorsGroup));
