const visibility = "visibility";
const chapterNumber = "chapterNumber";
export const defaultState = {
  visibility: true,
  chapterNumber: 0
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "SHOW_HEADER":
      newState[visibility] = true;
      return newState;
    case "HIDE_HEADER":
      newState[visibility] = false;
      return newState;
    case "UPDATE_CHAPTER_NUMBER":
      newState[chapterNumber] = action.chapterNumber;
      return newState;
    default:
      return state;
  }
};
