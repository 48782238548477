export const baseRoute = function(route) {
  let clonedRoute = Object.assign({}, route);
  clonedRoute.pathname = basePathname(clonedRoute.pathname);
  clonedRoute.key = Math.abs(clonedRoute.pathname.hashCode()) + "";
  return clonedRoute;
};

export const basePathname = function(pathname) {
  const re = /\//g;
  const count = ((pathname || "").match(re) || []).length;
  if (count == 2) {
    const cutoffIndex = pathname.lastIndexOf("/");
    return pathname.substring(0, cutoffIndex);
  }
  if (count == 3) {
    const cutoffIndex = pathname.lastIndexOf("/");
    let firstPop = pathname.substring(0, cutoffIndex);
    const secondCutoffIndex = firstPop.lastIndexOf("/");
    return firstPop.substring(0, secondCutoffIndex);
  }
  if (count == 4) {
    const cutoffIndex = pathname.lastIndexOf("/");
    let firstPop = pathname.substring(0, cutoffIndex);
    const secondCutoffIndex = firstPop.lastIndexOf("/");
    let secondPop = firstPop.substring(0, secondCutoffIndex);
    const thirdCutoffIndex = secondPop.lastIndexOf("/");
    return secondPop.substring(0, thirdCutoffIndex);
  }
  return pathname;
};

export const popPathname = function(pathname) {
  const re = /\//g;
  const count = ((pathname || "").match(re) || []).length;
  if (count > 3) {
    const cutoffIndex = pathname.lastIndexOf("/");
    return pathname.substring(0, cutoffIndex);
  }
  return pathname;
};

export const realPopPathname = function(pathname) {
  const re = /\//g;
  const count = ((pathname || "").match(re) || []).length;
  const cutoffIndex = pathname.lastIndexOf("/");
  return pathname.substring(0, cutoffIndex);
};

export const popRoute = function(route) {
  let clonedRoute = Object.assign({}, route);
  clonedRoute.pathname = popPathname(clonedRoute.pathname);
  clonedRoute.key = Math.abs(clonedRoute.pathname.hashCode()) + "";
  return clonedRoute;
};
export const passThroughRoute = function(route) {
  let clonedRoute = Object.assign({}, route);
  //clonedRoute.pathname = basePathname(clonedRoute.pathname);
  clonedRoute.key = Math.abs(clonedRoute.pathname.hashCode()) + "";
  return clonedRoute;
};
