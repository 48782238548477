import map from "lodash/map";

import layer1 from "../images/bridge/00.png";
import layer2 from "../images/bridge/01.png";
import layer3 from "../images/bridge/02.png";
import layer4 from "../images/bridge/03.png";
import layer5 from "../images/bridge/04.png";
import layer6 from "../images/bridge/05.png";
import layer7 from "../images/bridge/06.png";
import layer8 from "../images/bridge/07.png";
import layer9 from "../images/bridge/08.png";
import layer10 from "../images/bridge/09.png";
import layer11 from "../images/bridge/10.png";
import layer12 from "../images/bridge/11.png";
import layer13 from "../images/bridge/12.png";
import layer14 from "../images/bridge/13.png";
import layer15 from "../images/bridge/14.png";
import layer16 from "../images/bridge/15.png";
import layer17 from "../images/bridge/16.png";
import layer18 from "../images/bridge/17.png";
import layer19 from "../images/bridge/18.png";
import layer20 from "../images/bridge/19.png";
import layer21 from "../images/bridge/20.png";

const LAYER_WIDTH_HQ = 3000;
const LAYER_HEIGHT_HQ = 2319;

export const LAYER_WIDTH = 1000;
export const LAYER_HEIGHT = 773;

export const layers = [
  {
    autoScale: false,
    classNames: "",
    height: 733,
    image: layer21,
    name: "BACKGROUND",
    width: 1174,
    x: -87,
    y: 0,
    zOffset: -400
  },
  {
    autoScale: false,
    classNames: "glow",
    height: 392,
    image: layer20,
    name: "GLOW",
    width: 340,
    x: 319,
    y: 189,
    zOffset: -150
  },
  {
    autoScale: false,
    classNames: "",
    height: 238,
    image: layer19,
    name: "MISS",
    width: 221,
    x: 395,
    y: 249,
    zOffset: -100
  },
  {
    autoScale: false,
    classNames: "",
    height: 327,
    image: layer18,
    name: "left_back_leaves",
    width: 382,
    x: 197,
    y: 398,
    zOffset: -60
  },
  {
    autoScale: false,
    classNames: "",
    height: 232,
    image: layer17,
    name: "leaf",
    width: 157,
    x: 545,
    y: 408,
    zOffset: -50
  },
  {
    autoScale: false,
    classNames: "",
    height: 186,
    image: layer16,
    name: "plant_copy_3",
    width: 146,
    x: 626,
    y: 438,
    zOffset: -40
  },
  {
    autoScale: false,
    classNames: "",
    height: 181,
    image: layer15,
    name: "leaf-2",
    width: 165,
    x: 652,
    y: 499,
    zOffset: -30
  },
  {
    autoScale: false,
    classNames: "",
    height: 181,
    image: layer14,
    name: "leaf_copy",
    width: 165,
    x: 489,
    y: 390,
    zOffset: -20
  },
  {
    autoScale: false,
    classNames: "",
    height: 269,
    image: layer13,
    name: "leaf-3",
    width: 163,
    x: 397,
    y: 420,
    zOffset: -10
  },
  {
    autoScale: false,
    classNames: "",
    height: 194,
    image: layer12,
    name: "leaf-4",
    width: 170,
    x: 235,
    y: 534,
    zOffset: 10
  },
  {
    autoScale: false,
    classNames: "",
    height: 275,
    image: layer11,
    name: "plant",
    width: 218,
    x: 525,
    y: 427,
    zOffset: 20
  },
  {
    autoScale: false,
    classNames: "",
    height: 187,
    image: layer10,
    name: "leaf-5",
    width: 155,
    x: 445,
    y: 497,
    zOffset: 30
  },
  {
    autoScale: false,
    classNames: "",
    height: 176,
    image: layer9,
    name: "monstera",
    width: 160,
    x: 402,
    y: 520,
    zOffset: 40
  },
  {
    autoScale: false,
    classNames: "",
    height: 176,
    image: layer8,
    name: "monstera-2",
    width: 160,
    x: 549,
    y: 516,
    zOffset: 50
  },
  {
    autoScale: false,
    classNames: "",
    height: 166,
    image: layer7,
    name: "plant-2",
    width: 117,
    x: 312,
    y: 516,
    zOffset: 60
  },
  {
    autoScale: false,
    classNames: "",
    height: 85,
    image: layer6,
    name: "plant-3",
    width: 83,
    x: 496,
    y: 560,
    zOffset: 70
  },
  {
    autoScale: false,
    classNames: "",
    height: 278,
    image: layer5,
    name: "conection",
    width: 279,
    x: 356,
    y: 217,
    zOffset: 110
  },
  {
    autoScale: false,
    classNames: "",
    height: 168,
    image: layer4,
    name: "planting",
    width: 171,
    x: 512,
    y: 414,
    zOffset: 130
  },
  {
    autoScale: false,
    classNames: "",
    height: 166,
    image: layer3,
    name: "fisher",
    width: 171,
    x: 321,
    y: 417,
    zOffset: 130
  },
  {
    autoScale: false,
    classNames: "",
    height: 165,
    image: layer2,
    name: "village",
    width: 164,
    x: 514,
    y: 135,
    zOffset: 130
  },
  {
    autoScale: false,
    classNames: "",
    height: 176,
    image: layer1,
    name: "handshake",
    width: 206,
    x: 302,
    y: 134,
    zOffset: 130
  }
];
