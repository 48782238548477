import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GTAmerica } from "./typography.js";
import { Flex, Box, ClickCursorFlex, AbsoluteDiv } from "./layout.js";
import { CSSTransition } from "react-transition-group";
import LinkIMG from "../images/link.svg";
import LinkBlackIMG from "../images/link-black.svg";
import { ReactComponent as MenuSVG } from "../images/menu.svg";
import { ReactComponent as ShareBubbleSVG } from "../images/share-bubble.svg";

import styled from "styled-components";
import { toggle as toggleShareMenu } from "../actions/shareMenu";
import { toggle as toggleMenu } from "../actions/menu";
import { ShareBubble } from "./shareBubble";
import { chapters } from "../modules/chapters";

const HEADER_TRANSITION_TIMEOUT = 666;

const ShareLinkClickCursorFlex = styled(ClickCursorFlex)`
  background-image: url('${props => (props.isBlack ? LinkBlackIMG : LinkIMG)}');
  background-size:contain;
  background-position:center center;
  background-repeat:no-repeat;
`;
class Header extends Component {
  state = {
    showShareBubble: false,
    showHeader: false
  };
  constructor(props) {
    super(props);
  }
  _chapterAccessory() {
    return chapters[this.props.scrollPercent.chapterNumber].headerAccessory(this.props.scrollPercent.headerColor);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollPercent.headerVisibility !== this.props.scrollPercent.headerVisibility) {
      //Perform some operation
      this.setState({ showHeader: true });
      this.props.setTimeout(() => {
        this.setState({ showHeader: false });
      }, HEADER_TRANSITION_TIMEOUT);
    }
  }
  render() {
    const headerColor = this.props.scrollPercent.headerColor;
    return (
      <CSSTransition
        timeout={HEADER_TRANSITION_TIMEOUT}
        className="header"
        classNames="fade"
        in={this.props.scrollPercent.headerVisibility}
      >
        <Flex
          position={"relative"}
          alignItems={"center"}
          height={"28px"}
          width={["100%", "100%", "100%", "89.8%"]}
          pt={["23px", "23px", "23px", "28px"]}
        >
          <Flex alignItems={"center"} ml={["25px", "25px", "25px", "0px"]}>
            {this._chapterAccessory()}
          </Flex>
          <Box flex={"100"} />
          <ShareLinkClickCursorFlex
            width={"17.17px"}
            height={"20.83px"}
            position={"relative"}
            isBlack={headerColor === "black"}
            onClick={event => {
              event.stopPropagation();
              this.props.toggleShareMenu();
            }}
            className={"share-menu-button"}
          />
          <ShareBubble visible={this.props.shareMenu.isVisible} />
          <ClickCursorFlex
            width={"28px"}
            height={"18px"}
            ml={["20px", "20px", "20px", "41px"]}
            mr={["20px", "20px", "20px", "0px"]}
            onClick={event => {
              event.stopPropagation();
              this.props.toggleMenu();
            }}
          >
            <MenuSVG
              style={{
                fill: "none",
                stroke: headerColor,
                strokeWidth: 1,
                strokeOpacity: 1,
                strokeDasharray: "none"
              }}
            />
          </ClickCursorFlex>
        </Flex>
      </CSSTransition>
    );
  }
}

export default connect(
  state => ({
    shareMenu: state.shareMenu,
    scrollPercent: state.scrollPercent
  }),
  dispatch => ({
    toggleShareMenu: bindActionCreators(toggleShareMenu, dispatch),
    toggleMenu: bindActionCreators(toggleMenu, dispatch)
  })
)(Header);
