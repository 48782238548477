const debug = "debug";
export const defaultState = { debug: false };

export default (state = defaultState, action) => {
  let newState = Object.assign({ debug: false }, state);
  switch (action.type) {
    case "DEBUG":
      newState[debug] = false;
      return newState;
    default:
      return state;
  }
};
