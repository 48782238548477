import React, { Component } from "react";
import { updateMouseXY } from "../actions/mouseXY";
import { CoverImg } from "../modules/components";
import { withRouter } from "react-router-dom";
import ModuleManager from "../components/moduleManager";
import SecondaryLayout from "./secondary";
import SponsorLayout from "./sponsors";
import CreditLayout from "./credits";
import MotionLayout from "./motion";
import { educationModules } from "../modules/index";
import { spiritModules } from "../modules/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { hide as hideShareMenu } from "../actions/shareMenu";
import { hide as hideMenu } from "../actions/menu";
import { CloseButton } from "../components/closeButton";
import PhotoGallery from "../components/photoGallery";
import { harvestPhotoGallery } from "../components/photoGallery";
import { animalsPhotoGallery } from "../components/photoGallery";
import { peoplePhotoGallery } from "../components/photoGallery";
import { toggle as toggleSponsors } from "../actions/sponsors";
import { toggle as toggleMotion } from "../actions/motion";
import { Route } from "react-router-dom";
import Header from "../components/header";
import AudioButton from "../components/audioButton";
import { Flex, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import Iframe from "react-iframe";
import ScrollBar from "../components/scrollBar";
import Menu from "../components/menu.js";
import store, { history } from "../store";
import StandardVideo from "../components/standardVideo";
import SponsorsGroup from "../modules/sponsorsGroup";
import CreditsGroup from "../modules/creditsGroup";
import MotionGroup from "../modules/motionGroup";

import audio from "../images/bgAudio.mp3";
import { Media, Player, controls } from "react-media-player";

import { withMediaProps } from "react-media-player";
import YellowseedLogoIMG from "../images/yellowseed-sponsor-logo.png";
import ICRLogoIMG from "../images/icr-sponsor-logo.png";
import LandmarkLogoIMG from "../images/landmark-sponsor-logo.png";
//-rw-r--r--    1 adam  staff   8.4M May 27 12:44 motherNature2.mp4

import landOfTheFormersVIDEO from "../images/landOfFormers2.mp4";
import cacaoLivelihoodVIDEO from "../images/cacaoLivelihood2.mp4";
import camillosMessageVIDEO from "../images/camilosMessage2.mp4";
import moisesGuitarVIDEO from "../images/daimeHymn2.mp4";
import nageKitchenVIDEO from "../images/talk_about_education.mp4";

import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";
import { Box, ClickCursorFlex } from "../components/layout.js";
import DataSourceStandardButton from "../components/dataSourceButton";
import { datasources } from "../modules/datasources";
import { hideDatasource } from "../actions/datasource";
import { Provider } from "react-redux";
import styled from "styled-components";

import { forceToNextSubModule } from "../actions/forceScrollPercent.js";
import { iframeMessage } from "../actions/iframeLoading.js";
import { forceToPreviousSubModule } from "../actions/forceScrollPercent.js";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";
import { forceToPreviousLockin } from "../actions/forceScrollPercent.js";

const ProblemButton = styled(ClickCursorFlex)`
  font-family: Times New Roman;
  border: solid 2px red;
  font-size: 30px;
  background-color: transparent;
  color: red;
  font-weight: bold;
  @media screen and (max-width: 568px) {
    font-size: 24px;
  }
`;
const SponsorImg = styled(Flex)`
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;
const NextButtonComponent = ({ ...props }) => (
  <Box zIndex={5}>
    <ClickCursorFlex
      zIndex={5}
      width={["40px"]}
      height={["40px"]}
      borderRadius={["50%"]}
      bg={"white"}
      justifyContent={"center"}
      alignItems={"center"}
      pointerEvents={"auto"}
      position={"relative"}
      onClick={() => {
        props.forceToNextLockin();
      }}
    >
      <Box width={["10px"]} height={["18px"]} pointerEvents={"none"}>
        <DownArrowOldSVG
          style={{
            width: "100%",
            height: "100%",
            fill: "black"
          }}
        />
      </Box>
    </ClickCursorFlex>
  </Box>
);

const NextButton = connect(
  null,
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch)
  })
)(NextButtonComponent);

const PreviousButtonComponent = ({ ...props }) => (
  <Box zIndex={5}>
    <ClickCursorFlex
      zIndex={5}
      width={["40px"]}
      height={["40px"]}
      borderRadius={["50%"]}
      bg={"white"}
      justifyContent={"center"}
      alignItems={"center"}
      pointerEvents={"auto"}
      position={"relative"}
      onClick={() => {
        props.forceToPreviousLockin();
      }}
    >
      <Box width={["10px"]} height={["18px"]} pointerEvents={"none"}>
        <DownArrowOldSVG
          style={{
            transform: "rotate(180deg)",
            width: "100%",
            height: "100%",
            fill: "black"
          }}
        />
      </Box>
    </ClickCursorFlex>
  </Box>
);

const PreviousButton = connect(
  null,
  dispatch => ({
    forceToPreviousLockin: bindActionCreators(forceToPreviousLockin, dispatch)
  })
)(PreviousButtonComponent);

const OverflowYScroller = styled(Flex)`
  overflow-y: scroll;
  overflow-x: hidden;
`;
class PrimaryLayout extends Component {
  componentDidMount() {
    let width = this.props.browser.width;
    let height = this.props.browser.height;
    this.props.updateMouseXY(width / 2, height / 2);
  }
  render() {
    return (
      <Media>
        <div
          id="app-root"
          onClick={() => {
            this.props.hideShareMenu();
          }}
          onKeyDown={e => {
            if (e.keyCode == "38") {
              // up arrow
              this.props.forceToPreviousLockin();
            } else if (e.keyCode == "40") {
              // down arrow
              this.props.forceToNextLockin();
            } else if (e.keyCode == "37") {
              // left arrow
              this.props.forceToPreviousLockin();
            } else if (e.keyCode == "39") {
              // right arrotw
              this.props.forceToNextLockin();
            }
          }}
          tabIndex="0"
        >
          <Player loop={true} src={audio} />
          <Flex justifyContent="center" position="relative" zIndex={4000} id="header-root">
            <TransitionGroup component={null} appear={true} exit={true}>
              {this.props.scrollPercent.moduleIndex === 0 && this.props.scrollPercent.subModulePercent < 0.15 ? (
                <CSSTransition
                  timeout={SHORT_TRANSITION_TIMEOUT}
                  className="sponsorheader"
                  classNames="fade"
                  key={"menu"}
                >
                  <ClickCursorFlex
                    bg={"white"}
                    zIndex={10000}
                    position={"absolute"}
                    alignItems={"center"}
                    height={["51px", "51px", "51px", "56px"]}
                    width={["100%", "100%", "100%", "100%"]}
                    pointerEvents={"all"}
                    justifyContent={["center", "center"]}
                    alignItems={"center"}
                    onClick={e => {
                      this.props.toggleSponsors();
                      e.stopPropagation();
                    }}
                  >
                    <SponsorImg mx={["4%"]} src={YellowseedLogoIMG} width={["20%", "15%"]} height={["85%", "72%"]} />
                    <SponsorImg mx={["4%"]} src={LandmarkLogoIMG} width={["20%", "15%"]} height={["85%", "72%"]} />
                    <SponsorImg mx={["4%"]} src={ICRLogoIMG} width={["20%", "15%"]} height={["85%", "72%"]} />
                  </ClickCursorFlex>
                </CSSTransition>
              ) : (
                <Header />
              )}
            </TransitionGroup>
          </Flex>
          <AbsoluteDiv
            bottom={["15px", "15px", "15px", "40px"]}
            left={["15px", "15px", "15px", "5%"]}
            zIndex={
              this.props.location.pathname === "/riverjourney/journey" ||
              this.props.location.pathname === "/jungle/cacaolivelihood" ||
              this.props.location.pathname === "/saosebastiao/spiritual/ritual/360" ||
              this.props.location.pathname === "/saosebastiao/spiritual/brewing/360" ||
              this.props.location.pathname === "/saosebastiao/spiritual/brewing" ||
              this.props.location.pathname === "/saosebastiao/spiritual/moises/video" ||
              this.props.location.pathname === "/saosebastiao/education/hear/video" ||
              this.props.location.pathname === "/saosebastiao/landoftheformers"
                ? 100
                : 10000
            }
          >
            <AudioButton muted={false} />
          </AbsoluteDiv>
          {this.props.motion.showButton ? (
            <AbsoluteDiv
              bottom={["15px", "15px", "15px", "40px"]}
              right={["15px", "15px", "15px", "5%"]}
              zIndex={
                this.props.location.pathname === "/animals" ||
                this.props.location.pathname === "/deforestation" ||
                this.props.location.pathname === "/riverjourney" ||
                this.props.location.pathname === "/saosebastiao/spiritual/medicine"
                  ? 100
                  : 10000
              }
            >
              <ProblemButton
                onClick={() => {
                  this.props.toggleMotion();
                }}
                alignItems={"center"}
                justifyContent={"center"}
                height={["40px", "40px", "40px", "60px"]}
                width={["40px", "40px", "40px", "60px"]}
                borderRadius={["21px", "21px", "21px", "31px"]}
              >
                !
              </ProblemButton>
            </AbsoluteDiv>
          ) : null}
          <div id="primary-layout-children">{this.props.children}</div>
          <TransitionGroup component={null} appear={true} exit={true}>
            {this.props.menu.isVisible ? (
              <CSSTransition timeout={SHORT_TRANSITION_TIMEOUT} className="menu" classNames="fade" key={"menu"}>
                <Menu visible={this.props.menu.isVisible} />
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          <TransitionGroup component={null} appear={true} exit={true}>
            {this.props.menu.isVisible ? (
              <CSSTransition timeout={SHORT_TRANSITION_TIMEOUT} className="element" classNames="fade" key={"underlay"}>
                <Flex
                  pointerEvents={"auto"}
                  className="element"
                  bg={["rgba(0,0,0,1)", "rgba(0,0,0,1)", "rgba(0,0,0,1)", "rgba(0,0,0,0.6)"]}
                  position={"fixed"}
                  top={"0px"}
                  left={"0px"}
                  right={"0px"}
                  bottom={"0px"}
                  zIndex={80000}
                  onClick={() => this.props.hideMenu()}
                />
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          <ScrollBar
            zIndex={
              this.props.location.pathname.includes("/saosebastiao/spiritual") ||
              this.props.location.pathname.includes("/saosebastiao/education")
                ? 10
                : 10000
            }
          />
          {this.props.browser.greaterThan.small && this.props.location.pathname != "/" ? (
            <Flex
              position={"absolute"}
              top={"0px"}
              right={"4vw"}
              height={"100%"}
              width={"50px"}
              justifyContent={"center"}
              alignItems={"center"}
              zIndex={4000}
              flexDirection={"column"}
            >
              <PreviousButton />
              <Box height={"16px"} width={"100%"} />
              <NextButton />
            </Flex>
          ) : null}
          <TransitionGroup component={null} appear={true} exit={true}>
            {this.props.sponsors.isVisible ? (
              <CSSTransition timeout={SHORT_TRANSITION_TIMEOUT} className="sponsor" classNames="fade" key={"sponsor"}>
                <SponsorLayout>
                  <Flex
                    pointerEvents={"all"}
                    position={"absolute"}
                    top={"0px"}
                    left={"0px"}
                    bg={"white"}
                    height={"100%"}
                    width={"100%"}
                  >
                    <SponsorsGroup />
                  </Flex>
                </SponsorLayout>
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          <TransitionGroup component={null} appear={true} exit={true}>
            {this.props.credits.isVisible ? (
              <CSSTransition timeout={SHORT_TRANSITION_TIMEOUT} className="credit" classNames="fade" key={"credit"}>
                <CreditLayout>
                  <Flex
                    pointerEvents={"all"}
                    position={"absolute"}
                    top={"0px"}
                    left={"0px"}
                    bg={"white"}
                    height={"100%"}
                    width={"100%"}
                  >
                    <CreditsGroup />
                  </Flex>
                </CreditLayout>
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          <TransitionGroup component={null} appear={true} exit={true}>
            {this.props.motion.isVisible ? (
              <CSSTransition timeout={SHORT_TRANSITION_TIMEOUT} className="motion" classNames="fade" key={"motion"}>
                <MotionLayout>
                  <MotionGroup />
                </MotionLayout>
              </CSSTransition>
            ) : null}
          </TransitionGroup>
          <Route
            path="/saosebastiao/education"
            render={props => (
              <Flex
                pointerEvents={"auto"}
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <SecondaryLayout>
                  <ModuleManager modules={educationModules} isSecondary={true} id={"secondary"} />
                </SecondaryLayout>
              </Flex>
            )}
          />
          <Route
            path="/saosebastiao/spiritual"
            render={props => (
              <Flex
                pointerEvents={"auto"}
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <SecondaryLayout>
                  <ModuleManager modules={spiritModules} isSecondary={true} id={"secondary"} />
                </SecondaryLayout>
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/community"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <Iframe
                  url="https://wild-cacao.org/community/index.html"
                  width="100%"
                  height="100%"
                  id="explore-community"
                  className="explore-community"
                  display="initial"
                  position="absolute"
                  styles={{ pointerEvents: "auto" }}
                  allowFullScreen
                />
                <AbsoluteDiv
                  top={["15px", "15px", "15px", "40px"]}
                  right={["15px", "15px", "15px", "40px"]}
                  zIndex={1000}
                >
                  <CloseButton onClick={e => this.props.history.goBack()} />
                </AbsoluteDiv>
              </Flex>
            )}
          />
          <Route
            exact
            path="/riverjourney/journey"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <Iframe
                  url="https://wild-cacao.org/boat360/index.html"
                  width="100%"
                  height="100%"
                  id="explore-community"
                  className="explore-community"
                  display="initial"
                  position="absolute"
                  styles={{ pointerEvents: "auto" }}
                  allowFullScreen
                />
                <AbsoluteDiv
                  top={["15px", "15px", "15px", "40px"]}
                  right={["15px", "15px", "15px", "40px"]}
                  zIndex={1000}
                >
                  <CloseButton onClick={e => this.props.history.goBack()} />
                </AbsoluteDiv>
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/spiritual/ritual/360"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <Iframe
                  url="https://wild-cacao.org/ritual360/index.html"
                  width="100%"
                  height="100%"
                  id="explore-community"
                  className="explore-community"
                  display="initial"
                  position="absolute"
                  styles={{ pointerEvents: "auto" }}
                  allowFullScreen
                />
                <AbsoluteDiv
                  top={["15px", "15px", "15px", "40px"]}
                  right={["15px", "15px", "15px", "40px"]}
                  zIndex={1000}
                >
                  <CloseButton onClick={e => this.props.history.goBack()} />
                </AbsoluteDiv>
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/spiritual/brewing/360"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                bg={"#222222"}
              >
                <Iframe
                  url="https://wild-cacao.org/brewing/index.html"
                  width="100%"
                  height="100%"
                  id="explore-community"
                  className="explore-community"
                  display="initial"
                  position="absolute"
                  styles={{ pointerEvents: "auto" }}
                  allowFullScreen
                />
                <AbsoluteDiv
                  top={["15px", "15px", "15px", "40px"]}
                  right={["15px", "15px", "15px", "40px"]}
                  zIndex={1000}
                >
                  <CloseButton onClick={e => this.props.history.goBack()} />
                </AbsoluteDiv>
              </Flex>
            )}
          />
          <Route
            exact
            path="/riverjourney/harvest"
            render={props => (
              <Flex position={"absolute"} zIndex={5000} top={"0px"} bottom={"0px"} left={"0px"} right={"0px"}>
                <PhotoGallery photos={harvestPhotoGallery} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/animals/gallery"
            render={props => (
              <Flex position={"absolute"} zIndex={5000} top={"0px"} bottom={"0px"} left={"0px"} right={"0px"}>
                <PhotoGallery photos={animalsPhotoGallery} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/people"
            render={props => (
              <Flex position={"absolute"} zIndex={5000} top={"0px"} bottom={"0px"} left={"0px"} right={"0px"}>
                <PhotoGallery photos={peoplePhotoGallery} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/landoftheformers"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                pointerEvents={"auto"}
              >
                <StandardVideo src={landOfTheFormersVIDEO} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/jungle/cacaolivelihood"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                pointerEvents={"auto"}
              >
                <StandardVideo src={cacaoLivelihoodVIDEO} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/day/camillosmessage"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                pointerEvents={"auto"}
              >
                <StandardVideo src={camillosMessageVIDEO} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/spiritual/moises/video"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                pointerEvents={"auto"}
              >
                <StandardVideo src={moisesGuitarVIDEO} />
              </Flex>
            )}
          />
          <Route
            exact
            path="/saosebastiao/education/hear/video"
            render={props => (
              <Flex
                position={"absolute"}
                zIndex={5000}
                top={"0px"}
                bottom={"0px"}
                left={"0px"}
                right={"0px"}
                pointerEvents={"auto"}
              >
                <StandardVideo src={nageKitchenVIDEO} />
              </Flex>
            )}
          />
          <CSSTransition timeout={600} className="datasource-bg" classNames="fade" in={this.props.datasource.isVisible}>
            <Flex
              position={"absolute"}
              height={"200%"}
              top={"-50%"}
              width={"100%"}
              bg={"black"}
              pointerEvents={"auto"}
              onClick={() => {
                this.props.hideDatasource();
              }}
            />
          </CSSTransition>
          <CSSTransition timeout={600} className="datasource" classNames="fade" in={this.props.datasource.isVisible}>
            <Flex
              position={"absolute"}
              bg={"#EBEBEB"}
              width={"100%"}
              height={["100%", "100%", "100%", "67vh"]}
              bottom={["0px"]}
              justifyContent={"center"}
              pointerEvents={"auto"}
            >
              <OverflowYScroller
                position={"absolute"}
                left={"7.5%"}
                width={"85%"}
                top={"8.4vh"}
                bottom={"8.4vh"}
                overflow={"scroll"}
                pointerEvents={"auto"}
              >
                <Flex flexDirection={"column"} width={"100%"}>
                  {datasources[this.props.datasource.index]}
                </Flex>
              </OverflowYScroller>
              <DataSourceStandardButton color="black" index={this.props.datasource.index} />
            </Flex>
          </CSSTransition>
        </div>
      </Media>
    );
  }
}

window.addEventListener("message", receiveMessage, false);

function receiveMessage(event) {
  console.log(JSON.stringify(event.data));
  let action = iframeMessage(event.data);
  store.dispatch(action);
  // ...
}

export default connect(
  state => ({
    browser: state.browser,
    menu: state.menu,
    sponsors: state.sponsors,
    credits: state.credits,
    motion: state.motion,
    datasource: state.datasource,
    scrollPercent: state.scrollPercent
  }),
  dispatch => ({
    updateMouseXY: bindActionCreators(updateMouseXY, dispatch),
    forceToPreviousLockin: bindActionCreators(forceToPreviousLockin, dispatch),
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch),
    hideDatasource: bindActionCreators(hideDatasource, dispatch),
    hideShareMenu: bindActionCreators(hideShareMenu, dispatch),
    toggleSponsors: bindActionCreators(toggleSponsors, dispatch),
    toggleMotion: bindActionCreators(toggleMotion, dispatch),
    hideMenu: bindActionCreators(hideMenu, dispatch)
  })
)(withRouter(PrimaryLayout));
