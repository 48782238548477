import React, { Component } from "react";
import styled from "styled-components";
import { HeroHeadphoneButton } from "./hero";
import HeroComponent from "./heroMissCacao";
import ChocolateComponent from "./heroChocolateBar";
import BridgeComponent from "./heroBridge";
import { chocolateBarModule } from "./chocolateBar";
import { globeModule } from "./globe";
import { animalsModule } from "./animals";
import { purusModule } from "./purus";
import { saopeopleModule } from "./saopeople";
import { forestModule } from "./forest";
import { buyModule } from "./buy";
import { supportModule } from "./support";
import { educationModule } from "./education";
import { spiritModule } from "./spirit";
import { bridgeModule } from "./bridgeModules";
import { cacaoDayModule } from "./cacaoDay";
import { ID } from "../utils/id";
import Iframe from "react-iframe";

import { MissCacao } from "../modules/missCacaoInteractive";
import { ChocolateBar } from "../modules/chocolateBarInteractive";
import { Bridge } from "../modules/bridgeInteractive";

import HeroGlobeVideo from "../modules/heroGlobeVideo";
import HeroRiver360 from "../modules/heroRiver360";
import HeroAnimalGallery from "../modules/heroAnimalGallery";
import HeroRiverVideo from "../modules/heroRiverVideo";
import HeroMap from "../modules/heroMap";
import HeroCamillo from "../modules/heroCamilloSpeak";
import HeroSaoPeople from "../modules/heroSaoPeople";
import HeroJungle360 from "../modules/heroJungle360";
import HeroCacaoForest from "../modules/heroCacaoForest";
import HeroDeforestation from "../modules/heroDeforestation";
import HeroNeverMeant from "../modules/heroNeverMeant";
import HeroCacaoDay from "../modules/heroCacaoDay";

import SecondarySpiritualDaime from "../modules/secondarySpiritualDaime.js";
import SecondarySpiritualBrewing from "../modules/secondarySpiritualBrewing.js";
import SecondarySpiritualStart from "../modules/secondarySpiritualStart.js";
import SecondarySpiritualMedicine from "../modules/secondarySpiritualMedicine.js";
import SecondarySpiritualRitual from "../modules/secondarySpiritualRitual.js";
import SecondarySpiritualMoises from "../modules/secondarySpiritualMoises.js";
import SecondarySpiritualChurch from "../modules/secondarySpiritualChurch.js";
import SecondarySpiritualCommunity from "../modules/secondarySpiritualCommunity.js";

import SecondaryEducationStart from "../modules/secondaryEducationStart.js";
import SecondaryEducationCamillo from "../modules/secondaryEducationCamillo.js";
import SecondaryEducationCamila from "../modules/secondaryEducationCamila.js";
import SecondaryEducationHear from "../modules/secondaryEducationHear.js";
import SecondaryEducationMoises from "../modules/secondaryEducationMoises.js";
import SecondaryEducationMosquito from "../modules/secondaryEducationMosquito.js";
import SecondaryEducationBoat from "../modules/secondaryEducationBoat.js";
import SecondaryEducationHealth from "../modules/secondaryEducationHealth.js";
import SecondaryEducationFrancisca from "../modules/secondaryEducationFrancisca.js";
import SecondaryEducationHealing from "../modules/secondaryEducationHealing.js";

import map from "lodash/map";
import flatMap from "lodash/flatMap";

import { findIndex } from "lodash";
import { filter } from "lodash";
/*
 - Spoof the chocolate section 
 - Spoof the globe section
 - Build the animal section
 - 
 * */

const mapModule = function(module) {
  // Clone Module Object for its top level params.
  // Applied to all subModules unless they specify an override.
  //
  let moduleLevelParameters = Object.assign({}, module);
  delete moduleLevelParameters["scenes"];
  return map(module.scenes, (subModule, index) => {
    return { ...moduleLevelParameters, ...subModule };
  });
};

const allModules = [cacaoDayModule];

const modulesMapped = map(allModules, (module, index) => mapModule(module));
// export const modules = flatMap(modulesMapped, subModule => subModule);

const MovableDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const GradientDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: -webkit-radial-gradient(rgba(0, 0, 0, 0.5), rgba(1, 1, 1, 0)),
    -webkit-linear-gradient(top left, ${props => (props.gradientColorStart ? props.gradientColorStart : "white")}, ${props => (props.gradientColorEnd ? props.gradientColorEnd : "white")});
`;

const Red = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <GradientDiv gradientColorStart={"#F8D353"} gradientColorEnd={"#FF5C5A"} />
    </MovableDiv>
  </>
);

const TestVideoWrapped = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroGlobeVideo myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const River360 = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroRiver360 myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const AnimalGallery = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroAnimalGallery myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const RiverVideo = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroRiverVideo myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const MapWrapped = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroMap myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const CamilloWrapped = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroCamillo myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SaoPeopleWrapped = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroSaoPeople myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const Jungle360 = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroJungle360 myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const CacaoForest = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroCacaoForest myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const Deforestation = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroDeforestation myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const CacaoDayWrapped = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroCacaoDay myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const NeverMeant = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <HeroNeverMeant myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

export const modules = [
  {
    name: "miss cacao",
    percent: 100,
    routes: ["/"],
    id: ID(),
    headerVisibility: true,
    component: HeroComponent,
    chapterNumber: 0,
    lockinPercents: [0.075, 0.285, 0.571, 0.857]
  },
  {
    name: "chocolate bar",
    percent: 100,
    routes: ["/chocolate"],
    id: ID(),
    headerVisibility: true,
    component: ChocolateComponent,
    chapterNumber: 1,
    lockinPercents: [0.3, 0.6]
  },
  {
    name: "bridge",
    percent: 100,
    routes: ["/bridge"],
    id: ID(),
    headerVisibility: true,
    component: BridgeComponent,
    chapterNumber: 1,
    lockinPercents: [0.25, 0.75]
  },
  {
    name: "globe",
    percent: 100,
    routes: ["/globe"],
    id: ID(),
    headerVisibility: true,
    component: TestVideoWrapped,
    chapterNumber: 1,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "black",
    name: "river360",
    percent: 100,
    routes: ["/river"],
    id: ID(),
    headerVisibility: true,
    component: River360,
    chapterNumber: 2,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "black",
    name: "animals",
    percent: 100,
    routes: ["/animals", "/animals/gallery"],
    id: ID(),
    headerVisibility: true,
    component: AnimalGallery,
    chapterNumber: 2,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "white",
    name: "riverjourney",
    percent: 100,
    routes: ["/riverjourney", "/riverjourney/journey", "/riverjourney/harvest"],
    id: ID(),
    headerVisibility: true,
    component: RiverVideo,
    chapterNumber: 2,
    moduleAnimation: true,
    lockinPercents: [0.25, 0.75]
  },
  {
    headerColor: "white",
    name: "rivermap",
    percent: 100,
    routes: ["/rivermap"],
    id: ID(),
    headerVisibility: true,
    component: MapWrapped,
    chapterNumber: 2,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "black",
    name: "camillospeaks",
    percent: 100,
    routes: ["/camillospeaks"],
    id: ID(),
    headerVisibility: true,
    component: CamilloWrapped,
    chapterNumber: 2,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "white",
    name: "saopeople",
    percent: 100,
    routes: [
      "/saosebastiao",
      "/saosebastiao/people",
      "/saosebastiao/community",
      "/saosebastiao/spiritual",
      "/saosebastiao/spiritual/start",
      "/saosebastiao/spiritual/daime",
      "/saosebastiao/spiritual/brewing",
      "/saosebastiao/spiritual/medicine",
      "/saosebastiao/spiritual/ritual",
      "/saosebastiao/spiritual/moises",
      "/saosebastiao/spiritual/moises/video",
      "/saosebastiao/spiritual/church",
      "/saosebastiao/spiritual/community",
      "/saosebastiao/spiritual/ritual/360",
      "/saosebastiao/education",
      "/saosebastiao/education/start",
      "/saosebastiao/education/camillo",
      "/saosebastiao/education/hear",
      "/saosebastiao/education/hear/video",
      "/saosebastiao/education/camila",
      "/saosebastiao/education/moises",
      "/saosebastiao/education/mosquito",
      "/saosebastiao/education/boat",
      "/saosebastiao/education/health",
      "/saosebastiao/education/francisca",
      "/saosebastiao/education/healing",
      "/saosebastiao/landoftheformers",
      "/saosebastiao/education"
    ],
    id: ID(),
    headerVisibility: true,
    component: SaoPeopleWrapped,
    chapterNumber: 3,
    moduleAnimation: true,
    lockinPercents: [0.25, 0.5, 0.75]
  },
  {
    headerColor: "white",
    name: "jungle360",
    percent: 100,
    routes: ["/jungle", "/jungle/cacaolivelihood"],
    id: ID(),
    headerVisibility: true,
    component: Jungle360,
    chapterNumber: 3,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "white",
    name: "cacaoforest",
    percent: 100,
    routes: ["/cacaoforest"],
    id: ID(),
    headerVisibility: true,
    component: CacaoForest,
    chapterNumber: 4,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "white",
    name: "deforestation",
    percent: 100,
    routes: ["/deforestation"],
    id: ID(),
    headerVisibility: true,
    component: Deforestation,
    chapterNumber: 4,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    headerColor: "white",
    name: "neverMeant",
    percent: 100,
    routes: ["/nevermeant"],
    id: ID(),
    headerVisibility: true,
    component: NeverMeant,
    chapterNumber: 4,
    moduleAnimation: true,
    lockinPercents: [0.5]
  },
  {
    name: "cacao day",
    percent: 100,
    routes: ["/day"],
    id: ID(),
    headerVisibility: true,
    component: CacaoDayWrapped,
    moduleAnimation: true,
    chapterNumber: 4,
    lockinPercents: [0.25, 0.75]
  }
];

const SecondaryStart = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualStart myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryDaime = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualDaime myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryBrewing = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualBrewing myIndex={props.myIndex} />
    </MovableDiv>
  </>
);
const SecondaryMedicine = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualMedicine myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryRitual = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualRitual myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryMoises = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualMoises myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryChurch = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualChurch myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const SecondaryCommunity = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondarySpiritualCommunity myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

export const spiritModules = [
  {
    name: "spiritual start",
    percent: 100,
    routes: ["/saosebastiao/spiritual/start"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryStart,
    chapterNumber: 0,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual daime",
    percent: 100,
    routes: ["/saosebastiao/spiritual/daime"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryDaime,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual brewing",
    percent: 100,
    routes: ["/saosebastiao/spiritual/brewing"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryBrewing,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual medicine",
    percent: 100,
    routes: ["/saosebastiao/spiritual/medicine"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryMedicine,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual ritual",
    percent: 100,
    routes: ["/saosebastiao/spiritual/ritual", "/saosebastiao/spiritual/ritual/360"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryRitual,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual moises",
    percent: 100,
    routes: ["/saosebastiao/spiritual/moises", "/saosebastiao/spiritual/moises/video"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryMoises,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual church",
    percent: 100,
    routes: ["/saosebastiao/spiritual/church"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryChurch,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "spiritual community",
    percent: 100,
    routes: ["/saosebastiao/spiritual/community"],
    id: ID(),
    headerVisibility: true,
    component: SecondaryCommunity,
    chapterNumber: 1,
    lockinPercents: [0.5]
  }
];

const EducationStart = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationStart myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationCamillo = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationCamillo myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationHear = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationHear myIndex={props.myIndex} />
    </MovableDiv>
  </>
);
const EducationCamila = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationCamila myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationMoises = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationMoises myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationMosquito = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationMosquito myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationBoat = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationBoat myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationHealth = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationHealth myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationFrancisca = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationFrancisca myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

const EducationHealing = ({ ...props }) => (
  <>
    <MovableDiv className={"movable-div"}>
      <SecondaryEducationHealing myIndex={props.myIndex} />
    </MovableDiv>
  </>
);

export const educationModules = [
  {
    name: "education start",
    percent: 100,
    routes: ["/saosebastiao/education/start"],
    id: ID(),
    headerVisibility: true,
    component: EducationStart,
    chapterNumber: 0,
    lockinPercents: [0.5]
  },
  {
    name: "education camillo",
    percent: 100,
    routes: ["/saosebastiao/education/camillo"],
    id: ID(),
    headerVisibility: true,
    component: EducationCamillo,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education hear",
    percent: 100,
    routes: ["/saosebastiao/education/hear", "/saosebastiao/education/hear/video"],
    id: ID(),
    headerVisibility: true,
    component: EducationHear,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education camila",
    percent: 100,
    routes: ["/saosebastiao/education/camila"],
    id: ID(),
    headerVisibility: true,
    component: EducationCamila,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education moises",
    percent: 100,
    routes: ["/saosebastiao/education/moises"],
    id: ID(),
    headerVisibility: true,
    component: EducationMoises,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education mosquito",
    percent: 100,
    routes: ["/saosebastiao/education/mosquito"],
    id: ID(),
    headerVisibility: true,
    component: EducationMosquito,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education boat",
    percent: 100,
    routes: ["/saosebastiao/education/boat"],
    id: ID(),
    headerVisibility: true,
    component: EducationBoat,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education health",
    percent: 100,
    routes: ["/saosebastiao/education/health"],
    id: ID(),
    headerVisibility: true,
    component: EducationHealth,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education francisca",
    percent: 100,
    routes: ["/saosebastiao/education/francisca"],
    id: ID(),
    headerVisibility: true,
    component: EducationFrancisca,
    chapterNumber: 1,
    lockinPercents: [0.5]
  },
  {
    name: "education healing",
    percent: 100,
    routes: ["/saosebastiao/education/healing"],
    id: ID(),
    headerVisibility: true,
    component: EducationHealing,
    chapterNumber: 1,
    lockinPercents: [0.5]
  }
];

export const moduleAnimationIndexes = function(modules) {
  return filter(
    map(modules, function(module, index) {
      return module.moduleAnimation ? index : -1;
    }),
    function(index) {
      return index >= 0;
    }
  );
};

const allEducationModules = [educationModule];

export const educationModulesMapped = map(allEducationModules, (module, index) => mapModule(module));
//export const educationModules = flatMap(educationModulesMapped, subModule => subModule);

const allSpiritModules = [spiritModule];

export const allSpiritModulesMapped = map(allSpiritModules, (module, index) => mapModule(module));
//export const spiritModules = flatMap(allSpiritModulesMapped, subModule => subModule);

/*
   First lets establish the 3 other layers:
   Top - Touch Targets
   Middle - Text & Overlays
   Bottom - Images & 360 Embeds

   Then lets make the scroller only 300% the size of the screen
   Then test simple transitions by outputting items in a <TransitionGroup> 
   Then migrate the views into a data-structure 

   Modules = <Array> with N Items of type <Module>

   A <Mudoule> is a dictionary:

   {
   top:[Component],
   middle:[Component, Component, Component],
   bottom:[Component, Component],
   }

   Get the current module by dividing the number of modules into the scroll percent
   and converting that value to an integer by rounding down.
   This gives us a <Module> then we also need to compue the percent we are on a given module:
   and use the same math to pull individual componennts from top, middle, and bottom.

   this lets us easily add <Module>'s and add sub-sections within
   no need to specify numbers just add a remove Modules and subsections. 
 * */
