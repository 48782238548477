import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import { ProfilePic } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";

import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import camilloProfilePicIMG from "../images/camillo-profile-pic.jpg";
import jungle360ScreencapIMG from "../images/jungle-360-screencap.jpeg";
import riverBirdsEyeIMG from "../images/river-birds-eye.jpeg";
import riverMapIMG from "../images/river-map.jpeg";
import riverMapAnnotationsSVG from "../images/annotated-river-map-expanded.svg";
import fruitPealingIMG from "../images/fruit-pealing.jpeg";
import childIMG from "../images/child.jpeg";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);
const text1 = (
  <>
    <Text color={"white"}>
      The river is the lifeblood of the people. As cacao, I unite them as one
      family.
    </Text>
  </>
);
const text2 = (
  <>
    <Text color={"white"}>
      Each contribution is important to the whole.
      <br /> <br /> Some community members harvest cacao, others open pods or
      dry the beans, and some drive the boats.
    </Text>
  </>
);

const text3 = (
  <>
    <Text color={"white"}>
      Many will make a small income working with cacao from March to June.
      <br />
      <br /> When cacao season is over, they collect oleaginous fruits to
      process into oil like buriti, patua, murumuru, and brazil nuts.
    </Text>
  </>
);

const text4 = (
  <>
    <Text color={"white"}>
      I bring these communities together as the Purus Cocoa Cooperative, 600
      members strong, spread out over 1000km of the river.
    </Text>
  </>
);

const text5 = (
  <>
    <Text color={"white"}>
      This year my production was minimal and Camilo and the community asked
      why? <br />
      <br /> I chuckled and cautioned, “You are very eager, go slowly.” <br />
      <br />
      It was time for me to rest, regain strength and fertilize my plants.
    </Text>
  </>
);

const Jungle360Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 60.54%
  );
`;
const GalleryButtonComponent = ({ ...props }) => (
  <GalleryButton
    position={"absolute"}
    bottom={["17vh"]}
    clickHandler={() => props.history.push("/harvest/gallery")}
  />
);
const GalleryButtonWrapped = withRouter(GalleryButtonComponent);
export const purusModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 2,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [text4],
      bottom: [
        <CoverImg src={riverMapIMG} />,
        <Flex position={"absolute"} height={"100%"} width={"100%"}>
          <CoverImgStyled src={riverMapAnnotationsSVG} />
        </Flex>
      ]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <Play360Button
          position={"absolute"}
          bottom={["17vh"]}
          clickHandler={() => console.log("navigate")}
        />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            TAKE A JOURNEY
            <br />
            ALONG THE RIVER
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [text3],
      bottom: [<CoverImg src={riverBirdsEyeIMG} />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <GalleryButtonWrapped />,
        <DataSourceStandardButton color="white" index={0} />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            SEE PHOTOS
            <br />
            OF HARVEST
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [text2],
      bottom: [<CoverImg src={fruitPealingIMG} />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <PlayButton
          position={"absolute"}
          bottom={["17vh"]}
          clickHandler={() => console.log("navigate")}
        />,
        <DataSourceStandardButton color="white" index={0} />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            LEARN MORE ABOUT
            <br />
            INCOME & LIVELIHOOD
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [text1],
      bottom: [<CoverImg src={childIMG} />]
    },
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <PlayButton
          position={"absolute"}
          bottom={["17vh"]}
          clickHandler={() => console.log("navigate")}
        />,
        <AbsoluteDiv bottom={["10.3vh"]} width={["100%"]}>
          <GTAmerica
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            LEARN MORE ABOUT
            <br />
            INCOME LIVELIHOOD
          </GTAmerica>
        </AbsoluteDiv>
      ],
      middle: [text5],
      bottom: [<CoverImg src={jungle360ScreencapIMG} />, <Jungle360Gradient />]
    },
    {
      headerColor: "black",
      top_slide: [],
      top_fade: [],
      middle: [
        <Flex
          position={"absolute"}
          top={["14.6vh", "14.6vh", "14.6vh", "31vh"]}
          width={"100%"}
          justifyContent={"center"}
        >
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            width={["83.7%", "83.7%", "83.7%", "76%"]}
            justifyContent={"flex-start"}
          >
            <ProfilePic src={camilloProfilePicIMG} />
            <Flex
              flexDirection={"column"}
              pt={["23px", "23px", "23px", "0px"]}
              pl={["0px", "0px", "0px", "58px"]}
              flex={"1 1000 auto"}
            >
              <GTAmerica
                fontSize={["21px", "21px", "21px", "36px"]}
                lineHeight={["23px", "23px", "23px", "46px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                " First of all, we are protecting our forest. We harvest what
                the forest gives us, without having to destroy it. And we are
                giving work to people who have never had a chance to earn a
                penny."
              </GTAmerica>
              <GTAmerica
                pt={["30px"]}
                fontSize={["21px", "21px", "21px", "28px"]}
                lineHeight={["27px", "27px", "27px", "36px"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                –Camilo
              </GTAmerica>
              <GTAmerica
                pt={["16px"]}
                fontSize={["16px", "16px", "16px", "21px"]}
                lineHeight={["normal", "normal", "normal", "normal"]}
                fontStyle={["normal", "normal", "normal", "normal"]}
                textAlign={["left", "left", "left", "left"]}
                fontWeight={[400]}
              >
                Director of Production of the Mapiá and Middle <br /> Purus
                Agroextractivist Cooperative
              </GTAmerica>
            </Flex>
          </Flex>
        </Flex>
      ],
      bottom: [<Flex bg={"white"} width={"100%"} height={"100%"} />]
    }
  ]
};
