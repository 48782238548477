import React, { Component } from "react";
import { CSSPlugin, AttrPlugin, TweenLite } from "gsap/all";
import { connect } from "react-redux";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { bindActionCreators } from "redux";
import { CloseButton } from "../components/closeButton";
import { withRouter } from "react-router-dom";
import { basePathname, baseRoute, realPopPathname } from "../utils/routes";
import { ClickCursorFlex, Flex, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { disable as disableAudio } from "../actions/audio";
import { toggle as toggleAudio } from "../actions/audio";
import { withMediaProps } from "react-media-player";
const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
`;
const VideoContainer = styled.video`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  top: 0px;
  left: 0px;
`;
class StandardVideo extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.videoInnerRef = React.createRef();
  }

  componentDidMount() {
    // componentDidMount
  }

  _setInitialProgress() {
    this.videoInnerRef.play();
    //this.videoInnerRef.currentTime = "" + this.props.duration * this.props.progress;
  }

  _pauseVideo() {
    this.videoInnerRef.pause();
    this.videoInnerRef.play();
  }

  componentWillUnmount() {
    // componentWillMount
  }

  componentWillReceiveProps(nextProps) {
    // componentWillReceiveProps
  }

  render() {
    const { progress } = this.props;
    return (
      <VideoWrapper className={"video-wrapper"}>
        <VideoContainer
          controls
          onPlay={() => {
            if (this.props.audio.touched && this.props.media.isMuted !== true) {
              this.props.media.mute(true);
              this.props.disableAudio();
            }
          }}
          onCanPlay={() => this._pauseVideo()}
          onLoadedData={() => this._setInitialProgress()}
          innerRef={div => (this.videoInnerRef = div)}
        >
          <source src={this.props.src} type="video/mp4" />
        </VideoContainer>
        <Flex
          position={"absolute"}
          right={["15px", "15px", "15px", "20px"]}
          top={["15px", "15px", "15px", "20px"]}
          zIndex={10000}
        >
          <CloseButton
            onClick={e => {
              e.stopPropagation();
              this.props.history.push(realPopPathname(this.props.location.pathname));
            }}
          />
        </Flex>
      </VideoWrapper>
    );
  }
}

export default (StandardVideo = connect(
  state => ({
    browser: state.browser,
    audio: state.audio
  }),
  dispatch => ({
    disableAudio: bindActionCreators(disableAudio, dispatch),
    toggleAudio: bindActionCreators(toggleAudio, dispatch)
  })
)(withMediaProps(withRouter(StandardVideo))));
