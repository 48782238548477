import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Flex, Box, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { CSSTransition } from "react-transition-group";
import { TimelineMax, CSSPlugin, AttrPlugin, TweenLite } from "gsap/all";
import Timeout from "../utils/timeout";
import { FullScreen } from "../modules/3dComponents";
import Iframe from "react-iframe";

import styled from "styled-components";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const RightTriangleHack = styled(AbsoluteDiv)``;

class IFrameWithLoading extends Component {
  state = { showChapter: false };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
  }

  componentDidMount() {
    TweenLite.to(this.firstRef, 1, {
      autoAlpha: 1
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.iframeLoading.message &&
      nextProps.iframeLoading.message.widgetID == this.props.id &&
      nextProps.iframeLoading.message.message !== this.props.iframeLoading.message.message
    ) {
      const iframeStatus = nextProps.iframeLoading.message.message;

      if (iframeStatus == "videoset" || iframeStatus == "imageset") {
        TweenLite.to(this.firstRef, 0.5, {
          delay: 0.5,
          autoAlpha: 0
        });
      }
    }
  }

  render() {
    return (
      <>
        <Iframe
          url={this.props.url}
          width="100%"
          height="100%"
          id={this.props.id}
          className={this.props.id}
          display="initial"
          position="absolute"
          allowFullScreen
        />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <Flex bg={"transparent"} width={"100%"} height={"100%"}>
            {this.props.children}
          </Flex>
        </FullScreen>
      </>
    );
  }
}

export default connect(
  state => ({
    iframeLoading: state.iframeLoading
  }),
  null
)(IFrameWithLoading);
