import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { Flex, Box, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { chapters } from "../modules/chapters";
import { GTAmerica } from "../components/typography.js";
import { CSSTransition } from "react-transition-group";
import Timeout from "../utils/timeout";
import debounce from "lodash/debounce";

import styled from "styled-components";

class HorizontalScrollBar extends Component {
  render() {
    return (
      <FixedDiv
        zIndex={10000}
        right={"0px"}
        height={["3px"]}
        top={["0px"]}
        left={["0px"]}
        style={{
          transform:
            "translateX(-" +
            (1 - this.props.secondaryScrollPercent.percent) * 100 +
            "%)"
        }}
      >
        <AbsoluteDiv height={"100%"} width={"100%"} bg={"#02DCA1"} />
      </FixedDiv>
    );
  }
}

export default connect(
  state => ({
    secondaryScrollPercent: state.secondaryScrollPercent
  }),
  null
)(HorizontalScrollBar);
