import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import { forceToNextLockin, forceToPreviousLockin } from "../actions/forceScrollPercent.js";
import birdsEyeVillageIMG from "../images/birds-eye-village3.jpg";
import birdsEyeVillageMobileIMG from "../images/birds-eye-village-annotated-mobile-cropped2.jpg";
import birdsEyeVillageAnnotatedSVG from "../images/birds-eye-village-annotated-laptop-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentSVG } from "../images/birds-eye-village-annotated-laptop-hoverable.svg";
import birdsEyeVillageAnnotatedMobileSVG from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentMobileSVG } from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import ladyProfilePicIMG from "../images/lady.jpeg";
import kitchenProfilePicIMG from "../images/kitchen.jpeg";
import spiritualProfilePicIMG from "../images/spiritual.jpeg";
import educationProfilePicIMG from "../images/education.png";
import VideoInteractive from "./video";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import TWEEN from "@tweenjs/tween.js";
import Iframe from "react-iframe";
import { RiverNextButton } from "./animals";
import { AnimalText } from "./components";

import riverMapIMG from "../images/river-map.jpeg";
import riverMapAnnotationsSVG from "../images/annotated-river-map-expanded.svg";

import riverVideo from "../images/drone-boat-warp-flipped-fullspeed-v2-shrunk.mp4";
import fruitPeelerSmallIMG from "../images/fruit-pealing-small.jpg";
import { VideoWrapper, VideoContainer } from "./video";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
import throttle from "lodash/throttle";
const plugins = [CSSPlugin, AttrPlugin];

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const PersonButton = ({ ...props }) => (
  <Flex
    width={["72px", "72px", "72px", "90px"]}
    height={["72px", "72px", "72px", "90px"]}
    borderRadius={"50%"}
    overflow={"hidden"}
    bg={"#ffffff"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Flex
      width={["66px", "66px", "66px", "82px"]}
      height={["66px", "66px", "66px", "82px"]}
      borderRadius={"50%"}
      overflow={"hidden"}
    >
      <ImgStyled src={props.src} />
    </Flex>
  </Flex>
);

const TopFlexFit = styled.div`
  width: 1712px;
  height: 1980px;
  object-fit: cover;
`;

const throttledWheelHandler = throttle(
  (deltaY, forceToNextLockin, forceToPreviousLockin) => {
    if (deltaY > 0) {
      forceToNextLockin();
    } else if (deltaY < 0) {
      forceToPreviousLockin();
    }
  },
  2000,
  { leaading: true, trailing: false }
);
class BirdsEyeVillageAnnotatedComponentClass extends Component {
  _educationClick(event) {
    this.props.history.push("/saosebastiao/education/start");
  }
  _spiritClick(event) {
    this.props.history.push("/saosebastiao/spiritual/start");
  }
  _communityClick(event) {
    this.props.history.push("/saosebastiao/community");
  }
  _peopleClick(event) {
    this.props.history.push("/saosebastiao/people");
  }
  addClickEventListeners() {
    this.wheelCallback = event => {
      throttledWheelHandler(event.deltaY, this.props.forceToNextLockin, this.props.forceToPreviousLockin);
    };
    this.touchStartCallback = event => {
      var touchobj = event.changedTouches[0];
      this.starty = parseInt(touchobj.clientY);
    };
    this.touchEndCallback = event => {
      var touchobj = event.changedTouches[0];
      const endy = parseInt(touchobj.clientY);

      const diff = endy - this.starty;
      if (Math.abs(diff) < 50) {
        return;
      }
      if (diff > 0) {
        console.log("down");
        this.props.forceToPreviousLockin();
      } else {
        console.log("up");
        this.props.forceToNextLockin();
      }
      //debugger;
      /*
    if (deltaY > 0) {
      forceToNextLockin();
    } else if (deltaY < 0) {
      forceToPreviousLockin();
    }
    */
    };

    const educationButton = document.querySelector("#village-image-one");
    this.educationClickCallback = () => {
      this._educationClick();
    };
    educationButton.addEventListener("click", this.educationClickCallback);
    educationButton.addEventListener("wheel", this.wheelCallback);
    educationButton.addEventListener("touchstart", this.touchStartCallback);
    educationButton.addEventListener("touchend", this.touchEndCallback);

    const spiritButton = document.querySelector("#village-image-two");
    this.spiritClickCallback = () => {
      this._spiritClick();
    };
    spiritButton.addEventListener("click", this.spiritClickCallback);
    spiritButton.addEventListener("wheel", this.wheelCallback);
    spiritButton.addEventListener("touchstart", this.touchStartCallback);
    spiritButton.addEventListener("touchend", this.touchEndCallback);

    const communityButton = document.querySelector("#village-image-three");
    this.communityClickCallback = () => {
      this._communityClick();
    };
    communityButton.addEventListener("click", this.communityClickCallback);
    communityButton.addEventListener("wheel", this.wheelCallback);
    communityButton.addEventListener("touchstart", this.touchStartCallback);
    communityButton.addEventListener("touchend", this.touchEndCallback);

    const peopleButton = document.querySelector("#village-image-four");
    this.peopleClickCallback = () => {
      this._peopleClick();
    };
    peopleButton.addEventListener("click", this.peopleClickCallback);
    peopleButton.addEventListener("wheel", this.wheelCallback);
    peopleButton.addEventListener("touchstart", this.touchStartCallback);
    peopleButton.addEventListener("touchend", this.touchEndCallback);

    // G TAGS
    const educationGTag = document.querySelector("#village-gtag-one");
    if (educationGTag) {
      educationGTag.addEventListener("click", this.educationClickCallback);
      educationGTag.addEventListener("wheel", event => {
        throttledWheelHandler(event.deltaY, this.props.forceToNextLockin, this.props.forceToPreviousLockin);
      });
    }

    const spiritGTag = document.querySelector("#village-gtag-two");
    if (spiritGTag) {
      spiritGTag.addEventListener("click", this.spiritClickCallback);
      spiritGTag.addEventListener("wheel", event => {
        throttledWheelHandler(event.deltaY, this.props.forceToNextLockin, this.props.forceToPreviousLockin);
      });
    }

    const communityGTag = document.querySelector("#village-gtag-three");
    if (communityGTag) {
      communityGTag.addEventListener("click", this.communityClickCallback);
      communityGTag.addEventListener("wheel", event => {
        throttledWheelHandler(event.deltaY, this.props.forceToNextLockin, this.props.forceToPreviousLockin);
      });
    }

    const peopleGTag = document.querySelector("#village-gtag-four");
    if (peopleGTag) {
      peopleGTag.addEventListener("click", this.peopleClickCallback);
      peopleGTag.addEventListener("wheel", event => {
        throttledWheelHandler(event.deltaY, this.props.forceToNextLockin, this.props.forceToPreviousLockin);
      });
    }
  }
  removeClickEventListeners() {
    const educationButton = document.querySelector("#village-image-one");
    educationButton.removeEventListener("click", this._educationClickCalback);

    const spiritButton = document.querySelector("#village-image-two");
    spiritButton.removeEventListener("click", this._spiritClickCalback);

    const communityButton = document.querySelector("#village-image-three");
    communityButton.removeEventListener("click", this._communityClickCalback);

    const peopleButton = document.querySelector("#village-image-four");
    peopleButton.removeEventListener("click", this._peopleClickCalback);

    const educationGTag = document.querySelector("#village-gtag-one");
    if (educationGTag) {
      educationGTag.removeEventListener("click", this.educationClickCallback);
    }

    const spiritGTag = document.querySelector("#village-gtag-two");
    if (spiritGTag) {
      spiritGTag.removeEventListener("click", this.spiritClickCallback);
    }

    const communityGTag = document.querySelector("#village-gtag-three");
    if (communityGTag) {
      communityGTag.removeEventListener("click", this.communityClickCallback);
    }

    const peopleGTag = document.querySelector("#village-gtag-four");
    if (peopleGTag) {
      peopleGTag.removeEventListener("click", this.peopleClickCallback);
    }
  }
  componentDidMount() {
    this.addClickEventListeners();
  }
  componentWillUnmount() {
    this.removeClickEventListeners();
  }
  shouldComponentUpdate(nextProps, nextState) {
    // If we are about to switch to a new SVG remove the click event listeners
    if (nextProps.browser.greaterThan.medium !== this.props.browser.greaterThan.medium) {
      this.removeClickEventListeners();
    }
    if (nextProps.browser.width !== this.props.browser.width || nextProps.browser.height != this.props.browser.height) {
      return true;
    }
    return false;
  }
  componentDidUpdate(nextProps) {
    // If we just re-rendered after switchging to a diff SVG re-add click listeners
    if (nextProps.browser.greaterThan.medium !== this.props.browser.greaterThan.medium) {
      this.addClickEventListeners();
    }
  }
  render() {
    return (
      <>
        {this.props.browser.greaterThan.medium ? (
          <BirdsEyeVillageAnnotatedComponentSVG
            style={{
              width: "100vw",
              height: "100vh",
              pointerEvents: "none"
            }}
          />
        ) : (
          <Flex justifyContent={"center"} alignItems={"center"}>
            {this.props.browser.width > this.props.browser.height ? (
              <BirdsEyeVillageAnnotatedComponentMobileSVG
                preserveAspectRatio={"xMidYMid meet"}
                style={{
                  width: "100vw",
                  height: "100vh",
                  marginTop: "0vh",
                  pointerEvents: "none"
                }}
              />
            ) : (
              <BirdsEyeVillageAnnotatedComponentMobileSVG
                preserveAspectRatio={"xMidYMid slice"}
                style={{
                  width: "100vw",
                  height: "85vh",
                  pointerEvents: "none"
                }}
              />
            )}
          </Flex>
        )}
      </>
    );
  }
}
const BirdsEyeVillageAnnotatedComponent = connect(
  state => ({
    browser: state.browser
  }),
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch),
    forceToPreviousLockin: bindActionCreators(forceToPreviousLockin, dispatch)
  })
)(withRouter(BirdsEyeVillageAnnotatedComponentClass));

class HeroSaoPeople extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.videoInnerRef = React.createRef();
    this.firstTimeline = null;
    this.secondTimeline = null;
    this.thirdTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
    this.secondTimelineSetup();
    this.thirdTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.pause();
  }
  secondTimelineSetup() {
    this.secondTimeline = new TimelineMax();

    this.secondTimeline.add(
      TweenMax.set(this.secondRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 3, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 3, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.pause();
  }
  thirdTimelineSetup() {
    this.thirdTimeline = new TimelineMax();

    this.thirdTimeline.add(
      TweenMax.set(this.thirdRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.thirdTimeline.add(
      TweenMax.to(this.thirdRef, 5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.thirdTimeline.add(
      TweenMax.to(this.thirdRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.thirdTimeline.add(
      TweenMax.to(this.thirdRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.thirdTimeline.add(
      TweenMax.to(this.thirdRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.thirdTimeline.add(
      TweenMax.to(this.thirdRef, 1, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.thirdTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
    this.secondTimeline.progress(value);
    this.thirdTimeline.progress(value);
  }
  render() {
    return (
      <>
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <AbsoluteDiv>
            <Text color={"white"}>
              Camilo lives in the small community of São Sebastião. People settled this area 30 years ago in search of a
              deeper connection to nature, peace, and community.
            </Text>
          </AbsoluteDiv>
        </FullScreen>
        <FullScreen innerRef={div => (this.secondRef = div)}>
          <AbsoluteDiv>
            <Text color={"white"}>
              They describe themselves as a “land of the formers:” former fisherman, former miners, cowboys, farmers and
              taxi drivers.
            </Text>
          </AbsoluteDiv>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "15.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <PlayButton clickHandler={() => this.props.history.push("/saosebastiao/landoftheformers")} />,
            <GTAmerica
              letterSpacing={"0.15em"}
              fontWeight={700}
              fontSize={["11px", "11px", "11px", "14px"]}
              lineHeight={["17px", "17px", "17px", "20px"]}
              color={"white"}
              textAlign={["center"]}
            >
              CHECK OUT THE VIDEO
            </GTAmerica>
          </Flex>
        </FullScreen>
        <FullScreen innerRef={div => (this.thirdRef = div)}>
          <AbsoluteDiv width={"100%"} height={"100%"}>
            <Text color={"white"}>
              Explore the São Sebastião
              <br />
              community
            </Text>
          </AbsoluteDiv>
          <AbsoluteDiv top={"-5%"}>
            <Flex height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"} pointerEvents={"none"}>
              <BirdsEyeVillageAnnotatedComponent />
            </Flex>
          </AbsoluteDiv>
        </FullScreen>
        <CoverImgResponsive
          objectPositionY={"55%"}
          desktopSrc={birdsEyeVillageIMG}
          mobileSrc={birdsEyeVillageMobileIMG}
        />
      </>
    );
  }
}

export default (HeroSaoPeople = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(withRouter(HeroSaoPeople)));
