import React, { Component } from "react";
import styled from "styled-components";

import {
  height,
  minHeight,
  minWidth,
  alignSelf,
  space,
  width,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  justifySelf,
  maxWidth,
  maxHeight,
  color,
  position,
  zIndex,
  borderRadius,
  order,
  fontSize,
  display,
  opacity,
  top,
  left,
  bottom,
  right,
  borders,
  backgroundImage,
  backgroundSize
} from "styled-system";

export const Box = styled.div`
  ${borders}
  ${space}
  ${width}
  ${height}
  ${alignSelf}
  ${flex}
  ${justifySelf}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${order}
  ${display}
  ${opacity}
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : "none")}
`;

export const Flex = styled.div`
  ${top}
  ${bottom}
  ${right}
  ${left}
  ${space}
  ${width}
  ${height}
  ${minHeight}
  ${minWidth}
  ${alignItems}
  ${justifyContent}
  ${flexWrap}
  ${alignSelf}
  ${flex}
  ${justifySelf}
  ${flexDirection}
  ${maxWidth}
  ${maxHeight}
  ${color}
  ${position}
  ${zIndex}
  ${borderRadius}
  ${order}
  ${display}
  ${backgroundImage}
  ${backgroundSize}
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : "none")}
  display:flex;
  cursor:${props => (props.cursor ? props.cursor : "inherit")}
  overflow:${props => (props.overflow ? props.overflow : "visible")};
`;

export const ClickCursorFlex = styled(Flex)`
  cursor: pointer !important;
  pointer-events: auto;
`;

export const FlexGrow = styled(Flex)`
  flex-grow: 100;
`;

export const OverflowHiddenBox = styled(Box)`
  overflow: hidden;
`;

export const AbsoluteDiv = styled.div`
  ${width}
  ${height}
  ${maxWidth}
  ${minWidth}
  ${space}
  ${color}
  ${fontSize}
  ${zIndex}
  ${top}
  ${borderRadius}
  ${bottom}
  ${left}
  ${right}
  ${alignItems}
  ${justifyContent}
  ${display}
  ${opacity}
  pointer-events: ${props => (props.pointerEvents ? props.pointerEvents : "none")}
  cursor:${props => (props.cursor ? props.cursor : "inherit")}
  overflow:${props => (props.overflow ? props.overflow : "visible")};
  position:absolute;
`;

export const FixedDiv = styled(AbsoluteDiv)`
  position: fixed;
`;
