import React, { Component } from "react";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition, Transitio } from "react-transition-group";
import styled from "styled-components";
import { GTAmerica, GTAmericaStyled } from "../components/typography.js";

import { useSwipeable, Swipeable } from "react-swipeable";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { CSSPlugin, AttrPlugin, TweenLite, Power2 } from "gsap/all";
import { ReactComponent as LeftArrowSVG } from "../images/left-arrow.svg";
import { CoverImg, PrimaryText } from "./components";
import { ReactComponent as RightArrowSVG } from "../images/right-arrow.svg";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const LocationText = styled(GTAmericaStyled)`
  letter-spacing: 0.15em;
  font-weight: bold;
  transition: all 0.3s;
  text-transform: UPPERCASE;
  &:hover {
    color: #10cfab;
  }
`;
const TitleText = styled(GTAmericaStyled)`
  display: inline-block;
  font-weight: bold;
`;
export const CreditInnards = ({ ...props }) => (
  <>
    <Flex flexDirection={["column"]} bg={"white"} width={["100%"]}>
      <Box position={"relative"} mb={["12px"]} width={["100%", "100%", "100%", "85%"]}>
        <Box pointerEvents={"all"} position={"relative"} display={"inline-block"}>
          <TitleText
            className={"credit-title-text"}
            fontWeight={"bold"}
            fontSize={["18px", "21px"]}
            lineHeight={["21px", "29px"]}
          >
            {props.name}
          </TitleText>
          <AbsoluteDiv bottom={"-4px"} height={["2px"]} width={"100%"} bg={"#10cfab"} />
          {props.link.length > 3 ? (
            <AbsoluteDiv top={"0px"} right={"0px"} width={"0px"} height={["100%"]}>
              <AbsoluteDiv top={"0px"} left={"5px"} width={"30px"} height={["100%"]}>
                <Flex height={"80%"} justifyContent={"center"} alignItems={"center"}>
                  <Box height={"14px"} className={"credits-arrow-holder"}>
                    <RightArrowSVG
                      style={{
                        height: "100%",
                        fill: "#10CFAB"
                      }}
                    />
                  </Box>
                </Flex>
              </AbsoluteDiv>
            </AbsoluteDiv>
          ) : null}
        </Box>
      </Box>
      <GTAmerica fontSize={["16px", "21px"]} pb={["4px"]} lineHeight={["21px", "29px"]}>
        {props.position}
      </GTAmerica>
      <LocationText fontSize={["11px", "14px"]} lineHeight={["17px", "21px"]}>
        {props.location}
      </LocationText>
    </Flex>
  </>
);
export const Credit = ({ ...props }) => (
  <>
    <Flex
      pointerEvents={"all"}
      bg={"white"}
      className={"credit-component"}
      pt={["23px", "23px"]}
      width={["100%", "50%", "50%", "27%"]}
      pr={["0%", "0%", "0%", "5%"]}
    >
      {props.link.length > 3 ? (
        <a href={props.link} style={{ width: "100%" }} target="_blank">
          <CreditInnards {...props} />
        </a>
      ) : (
        <CreditInnards {...props} />
      )}
    </Flex>
  </>
);

const OverflowYScroll = styled(Flex)`
  overflow-y: scroll;
  overflow-x: hidden;
`;

class CreditsGroup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <OverflowYScroll className={"credits-scroll-wrapper"} width={"100%"} height={"100%"} pointerEvents={"all"}>
        <Flex
          position={"relative"}
          className="credits-container"
          flexDirection={"column"}
          width={"100%"}
          mx={[(20 / 375) * 100 + "%", (70 / 1440) * 100 + "%"]}
          my={[(53 / 667) * 100 + "%", (60 / 1024) * 100 + "%"]}
        >
          <AbsoluteDiv bottom={"0px"} right={"0px"} left={"0px"} top={"0px"}>
            <GTAmerica
              fontSize={["24px", "40px"]}
              width={["100%"]}
              lineHeight={["30px", "50px"]}
              pt={["53px", "0px"]}
              pb={["10px", "15px"]}
            >
              Our global team of contributors made this experience possible
            </GTAmerica>
            <Flex
              width={"100%"}
              flexDirection={["column", "row"]}
              flexWrap={["nowrap", "wrap"]}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              pb={"53px"}
            >
              <Credit
                name="Nancy Zamierowski"
                link="http://www.zam.earth/"
                position="Co-Director, Co-Producer"
                location="PORTLAND, OR"
              />
              <Credit
                name="Erika Logie "
                link="http://www.erikalogie.com"
                position="UX Design and Co-Director"
                location="Budapest, Hungary"
              />
              <Credit name="Rowan Oloman" link="" position="Story Editor, Co-Producer" location="Vancouver, Canada" />
              <Credit
                name="Mark Christian"
                link="https://wildchocolate.org/"
                position="Advisor, Partner"
                location="NEW YORK, NY"
              />
              <Credit
                name="Ju Sting"
                link="http://behance.net/ju_sting"
                position="Illustrator"
                location="Curitiba, Brazil"
              />
              <Credit
                name="Ga Ma"
                link="http://gama.live/"
                position="Music Producer"
                location="Amatlan de Quetzalcoatl, Mexico"
              />
              <Credit name="Do Good Better" link="" position="Advisor, Editor" location="Washington, DC" />
              <Credit
                name="Ruben DeLuna"
                link="http://www.rubendeluna.com"
                position="Advisor, Creative Direction"
                location="Austin, TX"
              />
              <Credit name="Evo Heyning" link="" position="Interactive Media Advisor" location="Oakland, CA " />
              <Credit
                name="Adam Mork"
                link="http://www.therealadammork.com"
                position="Software Developer"
                location="SAN FRANCISCO, CA"
              />
              <Credit
                name="Dillon Morris"
                link="http://www.dillonmorris.com"
                position="Director of Photography - 360, Editor"
                location="SAN FRANCISCO, CA"
              />
              <Credit
                name="Leo Plunkett"
                link="http://www.leoplunkett.com"
                position="Director of Photography - Video"
                location="Bristol, UK"
              />
              <Credit
                name="Luiz Eduardo Guedes"
                link="https://www.eddieguedes.com"
                position="Translator in Brazil"
                location="Rio Branco, Brazil"
              />
              <Credit
                name="Raoni Arraes"
                link="http://www.raoniarraes.com"
                position="Written Translator"
                location="Belém, Brazil"
              />
              <Credit
                name="SOS Amazonia"
                link="https://doe.sosamazonia.org.br/en"
                position="Coordination and technical support"
                location="Rio Branco, Brazil"
              />
              <Credit
                name="Mapiá and Middle Purus Agroextractivist Cooperative (COOPERAR)"
                link=""
                position="Wild Cacao Cooperative"
                location="BRAZIL"
              />
            </Flex>
          </AbsoluteDiv>
        </Flex>
      </OverflowYScroll>
    );
  }
}

export default (CreditsGroup = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(CreditsGroup));
