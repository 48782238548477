const isVisible = "isVisible";
export const defaultState = { isVisible: false, toggle: true };

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "TOGGLE_SHARE":
      newState[isVisible] = !state[isVisible];
      return newState;
    case "HIDE_SHARE":
      newState[isVisible] = false;
      newState["toggle"] = !state["toggle"];
      return newState;
    default:
      return state;
  }
};
