import { modules } from "../modules";
import { chapters } from "../modules/chapters";
import { moduleArrayIndex, subModulePercent } from "../utils/scroll";
import { showHeader, hideHeader, updateChapterNumber } from "../actions/header";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const percent = "percent";
const chapterNumber = "chapterNumber";
const headerVisibility = "headerVisibility";
const headerColor = "headerColor";
const headerColor_default = "white";
const lastModuleIndex = "lastModuleIndex";
const touched = "touched";
export const defaultState = {
  touched: false,
  moduleIndex: 0,
  percent: 0,
  subModulePercent: 0,
  chapterNumber: 0,
  lastModuleIndex: -1,
  headerVisibility: true,
  headerColor: headerColor_default
};

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "UPDATE_SCROLL_PERCENT":
      let moduleIndex = moduleArrayIndex(action.percent, modules);
      let newChapterNumber = modules[moduleIndex][chapterNumber];
      if (modules[moduleIndex][headerVisibility]) {
        //store.dispatch(showHeader());
      } else {
        //store.dispatch(hideHeader());
      }

      if (newState[touched] && moduleIndex !== newState["moduleIndex"]) {
        newState[lastModuleIndex] = newState["moduleIndex"];
      }
      newState["moduleIndex"] = moduleIndex;

      newState[headerVisibility] = modules[moduleIndex][headerVisibility];
      newState[headerColor] = modules[moduleIndex][headerColor]
        ? modules[moduleIndex][headerColor]
        : headerColor_default;

      newState[chapterNumber] = newChapterNumber;
      newState[touched] = true;
      newState[percent] = action.percent;
      let boundedScrollPercent = Math.max(Math.min(action.percent, 1), 0);
      newState["subModulePercent"] = subModulePercent(boundedScrollPercent, modules);
      return newState;
    default:
      return state;
  }
};
