import React, { Component } from "react";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition, Transitio } from "react-transition-group";
import styled from "styled-components";
import { GTAmerica, GTAmericaStyled } from "../components/typography.js";

import { useSwipeable, Swipeable } from "react-swipeable";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { CSSPlugin, AttrPlugin, TweenLite, Power2 } from "gsap/all";
import { ReactComponent as LeftArrowSVG } from "../images/left-arrow.svg";
import { CoverImg, PrimaryText } from "./components";
import { ReactComponent as RightArrowSVG } from "../images/right-arrow.svg";
import motionSVG from "../images/enable-motion.svg";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

class MotionsGroup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Flex
        className={"motions-scroll-wrapper"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <img src={motionSVG} style={{ width: "100%", maxWidth: "375px" }} />
      </Flex>
    );
  }
}

export default (MotionsGroup = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(MotionsGroup));
