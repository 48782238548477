import map from "lodash/map";

export const breakpoints_em = [24, 48, 64, 96];
export const em = 16;

export const breakpoints_px = map(breakpoints_em, value => {
  return value * em;
});

export const breakpointStrings_em = map(breakpoints_em, value => {
  return value + "em";
});

export const WIDTH_0 = breakpoints_px[0];
export const WIDTH_1 = breakpoints_px[1];
export const WIDTH_2 = breakpoints_px[2];
export const WIDTH_3 = breakpoints_px[3];
