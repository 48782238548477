const isVisible = "isVisible";
export const defaultState = { isVisible: false };

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "TOGGLE_SPONSORS":
      newState[isVisible] = !state[isVisible];
      return newState;
    case "HIDE_SPONSORS":
      newState[isVisible] = false;
      return newState;
    default:
      return state;
  }
};
