import React, { Component } from "react";
import { connect } from "react-redux";
import ChocolateBar from "./chocolateBarInteractive";

import TWEEN from "@tweenjs/tween.js";
import { layers as bridgeLayers } from "./bridgeInteractiveImageImports";
import map from "lodash/map";

import styled from "styled-components";
import { FullScreenNoPointer, UpperLeftText, BorderAnimatedComponent } from "./3dComponents";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
import { layers as cacaoLayers } from "./missCacaoInteractiveImageImports";

//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

class ChocolateComponent extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.secondRef = React.createRef();
    this.firstTimeline = null;
    this.secondTimeline = null;
    this.timelines = [this.firstTimeline, this.secondTimeline];
  }
  componentDidMount() {
    this.firstTimelineSetup();
    this.secondTimelineSetup();
    let bridges = bridgeLayers;
    map(bridges, function(layer) {
      new Image().src = layer.image;
    });

    let cacaos = cacaoLayers;
    map(cacaos, function(layer) {
      new Image().src = layer.image;
    });
  }
  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenLite.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 2, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  secondTimelineSetup() {
    this.secondTimeline = new TimelineMax();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);

      const myIndex = this.props.myIndex;
      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }
      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this.firstTimeline.progress(obj.value);
          this.secondTimeline.progress(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  render() {
    return (
      <>
        <ChocolateBar showSupplemental={this.props.scrollPercent.subModulePercent > 0.5} />
        <FullScreenNoPointer innerRef={div => (this.firstRef = div)}>
          <UpperLeftText>
            Unlike 99.9% of cacao, I am not cultivated. I grow deep in the Amazon, reachable only by boat and unmapped
            foot trails.
          </UpperLeftText>
        </FullScreenNoPointer>
      </>
    );
  }
}

export default (ChocolateComponent = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(ChocolateComponent));
