import React, { Component } from "react";
import styled from "styled-components";
import { PrimaryText } from "./components";

import { ReactComponent as CloseSVG } from "../images/close.svg";
import {
  height,
  minHeight,
  width,
  position,
  zIndex,
  borderRadius,
  borders,
  top,
  bottom,
  left,
  right
} from "styled-system";

import { GTAmerica } from "../components/typography";
import { Flex, AbsoluteDiv } from "../components/layout";

export const ShadowText = styled(GTAmerica)`
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
  position:absolute
  ${left} ${top} ${bottom}
  transform: translateX(-50%) translateY(-50%);
  text-align:center;
`;

const OverlayBox = styled.div`
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
`;

const OverlayWrapper = styled.div`
  ${height} ${width} ${left} ${right} ${top} ${bottom} ${minHeight}
  position: absolute;
  transform:translateX(-50%);
  @media screen and (min-width: 1920px) {
    width:602px;
  }
`;

export const ChocolateDeepDiveOverlay = ({ ...props }) => (
  <OverlayWrapper
    left={props.left ? props.left : ""}
    right={props.right ? props.right : ""}
    bottom={props.bottom ? props.bottom : ""}
    top={props.top ? props.top : ""}
    width={props.isDesktop ? "402px" : "312px"}
  >
    <OverlayBox>
      <Flex
        height={"100%"}
        flexDirection={"column"}
        pt={props.isDesktop ? "20px" : "15px"}
        pl={props.isDesktop ? "25px" : "20px"}
        pr={props.isDesktop ? "25px" : "20px"}
        pb={props.isDesktop ? props.paddingBottom : props.paddingBottomMobile}
        position={"relative"}
      >
        <GTAmerica
          fontSize={props.isDesktop ? "24px" : "18px"}
          fontWeight={500}
          lineHeight={props.isDesktop ? "39px" : "32px"}
          pb={props.isDesktop ? "6px" : "3px"}
        >
          {props.title}
        </GTAmerica>
        <GTAmerica fontSize={props.isDesktop ? "14px" : "12px"} lineHeight={props.isDesktop ? "24px" : "20px"}>
          {props.body}
        </GTAmerica>
        <Flex
          cursor={"pointer"}
          pointerEvents={"all"}
          position={"absolute"}
          justifyContent={"center"}
          alignItems={"center"}
          top={"0px"}
          right={"0px"}
          height={props.isDesktop ? "60px" : "60px"}
          width={props.isDesktop ? "60px" : "60px"}
        >
          <CloseSVG
            style={{
              width: "15px",
              height: "15px",
              fill: "black",
              stroke: "none"
            }}
          />
        </Flex>
      </Flex>
    </OverlayBox>
  </OverlayWrapper>
);

const BorderAnimatedWrapper = styled.div`
  ${width} ${height};
  position: absolute;
`;

const BorderAnimated = styled.div`
  width: 33%;
  height: 33%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

export const BorderAnimatedComponent = ({ width, height, onClickHandler }) => (
  <BorderAnimatedWrapper width={width} height={height} id={"genetic-pulse"} className={"border-animated-wrapper"}>
    <BorderAnimated className={"pulse-one-border pulse-border"} />
    <BorderAnimated className={"pulse-two-border pulse-border"} />
    <BorderAnimated className={"pulse-three-border pulse-border"} />
    <BorderAnimated className={"pulse-four-border pulse-border"} />
    <BorderAnimated className={"pulse-five-border pulse-border"} />
    <BorderAnimated className={"pulse-six-border pulse-border"} />
    <BorderAnimated className={"pulse-seven-border pulse-border"} />
    <BorderAnimated className={"pulse-eight-border pulse-border"} />
    <BorderAnimated className={"outline-border"} onClick={onClickHandler} />
  </BorderAnimatedWrapper>
);
export const FullScreenFill = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
`;
export const BackgroundWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
`;
export const Background = styled.div`
  position: absolute;
  @media (max-width: ${props => (props.layerWidth / props.layerHeight) * 100}vh) {
    height: 100vh;
    width: calc((100vh - var(--vh, 0px)) * ${props => props.layerWidth / props.layerHeight});
  }
  @media (min-width: ${props => (props.layerWidth / props.layerHeight) * 100}vh) {
    width: 100vw;
    height: calc(100vw * ${props => props.layerHeight / props.layerWidth});
  }
  transform-style: preserve-3d;
  transform: ${props => (props.initialTransform ? props.initialTransform : "none")};

  top: ${props => (props.topOffset ? props.topOffset : "0%")};
  left: ${props => (props.leftOffset ? props.leftOffset : "0%")};
`;

export const AbsoluteZOffsetPositioned = styled.img`
  position: absolute;
  width: ${props => (props.width ? (props.width / props.layerWidth) * 100 + "%" : "auto")};
  height: auto;
  top: ${props => (props.y ? (props.y / props.layerHeight) * 100 + "%" : "0px")};
  left: ${props => (props.x ? (props.x / props.layerWidth) * 100 + "%" : "0px")};
  transform: translateZ(${props => (props.zOffset ? props.zOffset : 0)}px);
`;

export const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 35.36%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.51%, rgba(0, 0, 0, 0.6) 100%);
`;

export const FullScreen = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
export const FullScreenNoPointer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export const UpperLeftText = ({ ...props }) => (
  <PrimaryText
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
  >
    {props.children}
  </PrimaryText>
);
