import React, { Component } from "react";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { connect } from "react-redux";
import VideoInteractive from "./video";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import Iframe from "react-iframe";
import { withRouter } from "react-router-dom";

import { PlayButton } from "./components";
import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
import IFrameWithLoading from "../components/iframeWithLoading";
import { CoverImg, CoverImgResponsive } from "./components";
import jungleLoading from "../images/jungle.jpg";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

export const AnimalText = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "50px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "17.4vh"]}
    width={["auto", "auto", "auto", "60%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    fontStyle={props.fontStyle ? props.fontStyle : []}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

class HeroJungle360 extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.firstTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenLite.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 100;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  render() {
    return (
      <>
        {this.props.global.debug ? (
          <IFrameWithLoading url="http://localhost:8081/index.html" id="jungle">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={jungleLoading} />
          </IFrameWithLoading>
        ) : (
          <IFrameWithLoading url="https://wild-cacao.org/jungle/index.html" id="jungle">
            <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={jungleLoading} />
          </IFrameWithLoading>
        )}
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <AnimalText color={["white", "white", "white", "white"]}>
            The São Sebastião people make a small income working with cacao from March to June.
            <br />
            <br /> When cacao season ends they collect oleaginous fruits to process into oil like buriti, patua,
            murumuru, and brazil nuts.
          </AnimalText>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "15.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <PlayButton clickHandler={() => this.props.history.push("/jungle/cacaolivelihood")} />,
            <GTAmerica
              letterSpacing={"0.15em"}
              fontWeight={700}
              fontSize={["11px", "11px", "11px", "14px"]}
              lineHeight={["17px", "17px", "17px", "20px"]}
              color={"white"}
              textAlign={["center"]}
            >
              CLICK FOR VIDEO
              <br />
              ABOUT INCOME & LIVELIHOOD
            </GTAmerica>
          </Flex>
        </FullScreen>
      </>
    );
  }
}

export default (HeroJungle360 = connect(
  state => ({
    global: state.global,
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(withRouter(HeroJungle360)));
