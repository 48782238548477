import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PulseCircles } from "./components";
import { ProfilePic } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import { forceToNextSecondarySubModule } from "../actions/forceSecondaryScrollPercent.js";
import { WhitePrimarySmallButton } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";

import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { LargePlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";

import spiritHeroIMG from "../images/spirit-hero.png";
import ayahuascaIMG from "../images/ayahuasca.png";
import ayahuascaCeremonyIMG from "../images/ayahuasca-ceremony.png";
import guitarIMG from "../images/guitar.jpeg";
import builderIMG from "../images/builder.png";
import { spiritModules } from "./index";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "52%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const SpiritGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const BuilderGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 71.06%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const GuitarGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
`;

const MoisesGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 35.33%,
    rgba(0, 0, 0, 0.9) 100%
  );
`;
const NextButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <ClickCursorFlex
      pointerEvents={"auto"}
      onClick={() => {
        props.forceToNextSecondarySubModule(
          props.secondaryScrollPercent.percent,
          spiritModules.length
        );
      }}
    >
      <WhitePrimarySmallButton>
        <PulseCircles smallSize={"40px"} largeSize={"60px"} />
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          width={["9px", "9px", "9px", "12px"]}
          height={["13px", "13px", "13px", "22px"]}
        >
          <DownArrowOldSVG
            style={{
              width: "100%",
              height: "100%",
              stroke: "black",
              strokeWidth: "1px",
              fill: "none"
            }}
          />
        </Flex>
      </WhitePrimarySmallButton>
    </ClickCursorFlex>
  </Flex>
);

const NextButton = connect(
  state => ({
    secondaryScrollPercent: state.secondaryScrollPercent
  }),
  dispatch => ({
    forceToNextSecondarySubModule: bindActionCreators(
      forceToNextSecondarySubModule,
      dispatch
    )
  })
)(NextButtonComponent);

const BackButtonComponent = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <Link to={{ pathname: "/", startPercent: props.scrollPercent.percent }}>
      <ClickCursorFlex pointerEvents={"auto"}>
        <PulseCircles smallSize={"40px"} largeSize={"60px"} />
        <WhitePrimarySmallButton>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            width={["9px", "9px", "9px", "12px"]}
            height={["13px", "13px", "13px", "22px"]}
          >
            <DownArrowOldSVG
              style={{
                width: "100%",
                height: "100%",
                fill: "black"
              }}
            />
          </Flex>
        </WhitePrimarySmallButton>
      </ClickCursorFlex>
    </Link>
  </Flex>
);

const BackButton = connect(
  state => ({
    scrollPercent: state.scrollPercent
  }),
  null
)(BackButtonComponent);

const BackText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Back to Community Map
    </GTAmerica>
  </Flex>
);

const NextText = ({ ...props }) => (
  <Flex
    position={"absolute"}
    bottom={["13.7vh", "13.7vh", "13.7vh", "22.2vh"]}
    left={"0px"}
    right={"0px"}
    pointerEvents={"none"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <GTAmerica
      fontSize={["16px", "16px", "16px", "21px"]}
      lineHeight={["21px", "21px", "21px", "27px"]}
      color={"white"}
      textAlign={"center"}
    >
      Next Screen
    </GTAmerica>
  </Flex>
);

const CamillosDaughterCoverImgStyled = styled(CoverImgStyled)`
  @media screen and (min-width: 768px) {
    transform: translateX(25%);
  }
`;
const CamillosDaughterCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <CamillosDaughterCoverImgStyled src={props.src} />
  </Flex>
);

const MoisesCoverImgStyled = styled(CoverImgStyled)`
  object-position: 70% 50%;
`;
const MoisesCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <MoisesCoverImgStyled src={props.src} />
  </Flex>
);

const NageCoverImgStyled = styled(CoverImgStyled)`
  @media screen and (min-width: 768px) {
    transform: translateX(30%);
  }
`;
const NageCoverImg = ({ ...props }) => (
  <Flex bg={"black"} width={"100%"} height={"100%"}>
    <NageCoverImgStyled src={props.src} />
  </Flex>
);

export const spiritModule = {
  id: ID(),
  scenes: [
    {
      top_slide: [],
      top_fade: [<NextText />, <NextButton />],
      middle: [
        <Text color={"white"}>
          The local school goes up to the fourth grade. Classrooms are
          essentially bare, except for desks. Government funded teachers come
          and go, leaving the community with few options to educate their kids.
        </Text>
      ],
      bottom: [<CoverImg src={spiritHeroIMG} />, <SpiritGradient />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"black"}>
          Daime is made by brewing a mixture of Amazonian plants. It induces an
          altered state of consciousness and has the power to expand the
          physical and spiritual senses.
        </Text>
      ],
      bottom: [<Flex bg={"white"} height={"100%"} width={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <LargePlayButton />
          <GTAmerica
            pt={"17px"}
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            EXPLORE THE AYAHUASCA
            <br />
            MAKING FACILITY
          </GTAmerica>
        </Flex>
      ],
      middle: [],
      bottom: [<CoverImg src={ayahuascaIMG} />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"black"}>
          As a traditional medicine, Ayahuasca has been used across the Amazon
          river basin for hundreds, and likely thousands of years.
          <br /> <br />
          When the Portuguese and Spanish came in the 16th century, the existing
          native beliefs and practices became integrated with Roman Catholicism.
        </Text>
      ],
      bottom: [<Flex bg={"white"} height={"100%"} width={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <LargePlayButton />
          <GTAmerica
            pt={"17px"}
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            EXPLORE THE AYAHUASCA
            <br />
            MAKING FACILITY
          </GTAmerica>
        </Flex>,
        <NextText />,
        <NextButton />
      ],
      middle: [],
      bottom: [<CoverImg src={ayahuascaCeremonyIMG} />]
    },
    {
      top_slide: [],
      top_fade: [
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          width={"100%"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <LargePlayButton />
          <GTAmerica
            pt={"17px"}
            fontWeight={700}
            fontSize={"11px"}
            lineHeight={"15px"}
            color={"white"}
            textAlign={["center"]}
          >
            LISTEN TO MOISES SING A <br />
            DAIME HYMN
          </GTAmerica>
        </Flex>
      ],
      middle: [],
      bottom: [<CoverImg src={guitarIMG} />, <GuitarGradient />]
    },
    {
      top_slide: [],
      top_fade: [],
      middle: [
        <Text color={"black"}>
          The Santo Diame church also provides clothes, food, and materials for
          building houses, which helps to sustain the people of the community.
          <br />
          <br />
          The community is currently constructing a new church.
        </Text>
      ],
      bottom: [<Flex bg={"white"} height={"100%"} width={"100%"} />]
    },
    {
      top_slide: [],
      top_fade: [<BackText />, <BackButton />],
      middle: [],
      bottom: [<CoverImg src={builderIMG} />, <BuilderGradient />]
    }
  ]
};
