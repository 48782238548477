import React, { Component } from "react";
import { CSSPlugin, AttrPlugin, TweenLite } from "gsap/all";
import { connect } from "react-redux";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { bindActionCreators } from "redux";

export const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;
export const VideoContainer = styled.video`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0px;
  left: 0px;
`;
class VideoInteractive extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.videoInnerRef = React.createRef();
  }

  componentDidMount() {
    // componentDidMount
  }

  _setInitialProgress() {
    //this.videoInnerRef.playbackRate = this.props.playbackRate;
    this.videoInnerRef.playbackRate = 1;
    this.videoInnerRef.currentTime = "" + this.props.duration * this.props.progress;
  }

  _pauseVideo() {
    //this.videoInnerRef.pause();
  }

  _playVideo() {
    this.videoInnerRef.play();
  }

  componentWillUnmount() {
    // componentWillMount
  }

  componentWillReceiveProps(nextProps) {
    // componentWillReceiveProps
    if (this.props.progress != nextProps.progress) {
      //this.videoInnerRef.currentTime = "" + this.props.duration * nextProps.progress;
    }
  }

  render() {
    const { progress } = this.props;
    return (
      <VideoWrapper className={"video-wrapper"}>
        <VideoContainer
          className={"interactive-video"}
          muted={"muted"}
          playsInline
          autoPlay
          onCanPlay={() => this._playVideo()}
          onLoadedData={() => this._setInitialProgress()}
          innerRef={div => (this.videoInnerRef = div)}
        >
          <source src={this.props.src} type="video/mp4" />
        </VideoContainer>
      </VideoWrapper>
    );
  }
}

export default (VideoInteractive = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(VideoInteractive));
