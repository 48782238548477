import React, { Component } from "react";
import Interactive from "./Interactive";

import {
  layers,
  LAYER_WIDTH,
  LAYER_HEIGHT
} from "./missCacaoInteractiveImageImports";
import styled from "styled-components";

const PERSPECTIVE_START = 1000;
const PERSPECTIVE_END = 4000;
const BG_SCALE_END = 1.8;
const BG_SCALE_START = 2.8;
const ROTATION_ADJUST = 90;
const BG_TRANSLATE_Z = -400;
const BACKGROUND_WRAPPER_ID = "miss-cacao-background-wrapper";
const BACKGROUND_ID = "miss-cacao-background-rotater";

const shorterLayers = layers.slice(1, 99);

const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 35.36%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.51%, rgba(0, 0, 0, 0.6) 100%);
`;

export const MissCacao = ({ ...props }) => (
  <Interactive
    bgLayer={layers[0]}
    layers={shorterLayers}
    layerWidth={LAYER_WIDTH}
    layerHeight={LAYER_HEIGHT}
    perspectiveStart={PERSPECTIVE_START}
    perspectiveEnd={PERSPECTIVE_END}
    bgScaleEnd={BG_SCALE_END}
    bgScaleStart={BG_SCALE_START}
    rotationAdjust={ROTATION_ADJUST}
    bgTranslateZ={BG_TRANSLATE_Z}
    backgroundWrapperId={BACKGROUND_WRAPPER_ID}
    backgroundId={BACKGROUND_ID}
    gradientComponent={<HeroGradient className="hero-gradient" />}
  />
);
