import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, ClickCursorFlex } from "./layout.js";
import { ReactComponent as AudioMutedSVG } from "../images/audio-muted.svg";
import { ReactComponent as AudioEnabledSVG } from "../images/audio-enabled.svg";
import audio from "../images/bgAudio.mp3";
import { Media, Player, controls } from "react-media-player";
import { withMediaProps } from "react-media-player";
import { disable as disableAudio } from "../actions/audio";
import { enable as enableAudio } from "../actions/audio";
import { toggle as toggleAudio } from "../actions/audio";
import { bindActionCreators } from "redux";

class AudioButton extends Component {
  render() {
    return (
      <>
        <ClickCursorFlex
          onClick={e => {
            this.props.media.play();
            if (this.props.audio.touched == false) {
              this.props.media.volume = 0.35;
              this.props.media.mute(false);
              this.props.enableAudio();
            } else {
              if (this.props.media.isMuted) {
                this.props.media.mute(false);
                this.props.enableAudio();
              } else {
                this.props.disableAudio();
                this.props.media.mute(true);
              }
            }
            e.stopPropagation();
          }}
          alignItems={"center"}
          justifyContent={"center"}
          height={["40px", "40px", "40px", "60px"]}
          width={["40px", "40px", "40px", "60px"]}
          bg={"rgba(0, 0, 0, 0.2)"}
          borderRadius={["20px", "20px", "20px", "30px"]}
        >
          {this.props.audio.isEnabled || this.props.audio.touched == false ? (
            <Box width={["18px", "18px", "18px", "25px"]} height={["18px", "18px", "18px", "25px"]}>
              <AudioEnabledSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "white",
                  stroke: "none"
                }}
              />
            </Box>
          ) : (
            <Box width={["18px", "18px", "18px", "25px"]} height={["18px", "18px", "18px", "25px"]}>
              <AudioMutedSVG
                style={{
                  width: "100%",
                  height: "100%",
                  fill: "white",
                  stroke: "none"
                }}
              />
            </Box>
          )}
        </ClickCursorFlex>
      </>
    );
  }
}

export default connect(
  state => ({
    audio: state.audio
  }),
  dispatch => ({
    disableAudio: bindActionCreators(disableAudio, dispatch),
    enableAudio: bindActionCreators(enableAudio, dispatch),
    toggleAudio: bindActionCreators(toggleAudio, dispatch)
  })
)(withMediaProps(AudioButton));
