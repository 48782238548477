import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { hide as hideShareMenu } from "../actions/shareMenu";
import { hide as hideMenu } from "../actions/menu";
import { Route } from "react-router-dom";
import Header from "../components/header";
import AudioButton from "../components/audioButton";
import { CloseButton } from "../components/closeButton";
import { Flex, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { ModuleManager } from "../components/moduleManager.js";
import HorizontalScrollBar from "../components/horizontalScrollBar.js";
import Menu from "../components/menu.js";
import store, { history } from "../store";
import { basePathname, realPopPathname } from "../utils/routes";

import { Box, ClickCursorFlex } from "../components/layout.js";
import { Provider } from "react-redux";

import { forceToNextSubModule } from "../actions/forceScrollPercent.js";
import { forceToPreviousSubModule } from "../actions/forceScrollPercent.js";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";
import { forceToPreviousLockin } from "../actions/forceScrollPercent.js";

import { forceToSecondaryNextLockin } from "../actions/forceSecondaryScrollPercent.js";
import { forceToSecondaryPreviousLockin } from "../actions/forceSecondaryScrollPercent.js";

import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";

const NextButtonComponent = ({ ...props }) => (
  <Box zIndex={5}>
    <ClickCursorFlex
      zIndex={5}
      width={["40px"]}
      height={["40px"]}
      borderRadius={["50%"]}
      bg={"white"}
      justifyContent={"center"}
      alignItems={"center"}
      pointerEvents={"auto"}
      position={"relative"}
      onClick={() => {
        props.forceToSecondaryNextLockin();
      }}
    >
      <Box width={["10px"]} height={["18px"]} pointerEvents={"none"}>
        <DownArrowOldSVG
          style={{
            width: "100%",
            height: "100%",
            fill: "black"
          }}
        />
      </Box>
    </ClickCursorFlex>
  </Box>
);

const NextButton = connect(
  null,
  dispatch => ({
    forceToSecondaryNextLockin: bindActionCreators(forceToSecondaryNextLockin, dispatch)
  })
)(NextButtonComponent);

const PreviousButtonComponent = ({ ...props }) => (
  <Box zIndex={5}>
    <ClickCursorFlex
      zIndex={5}
      width={["40px"]}
      height={["40px"]}
      borderRadius={["50%"]}
      bg={"white"}
      justifyContent={"center"}
      alignItems={"center"}
      pointerEvents={"auto"}
      position={"relative"}
      onClick={() => {
        props.forceToSecondaryPreviousLockin();
      }}
    >
      <Box width={["10px"]} height={["18px"]} pointerEvents={"none"}>
        <DownArrowOldSVG
          style={{
            transform: "rotate(180deg)",
            width: "100%",
            height: "100%",
            fill: "black"
          }}
        />
      </Box>
    </ClickCursorFlex>
  </Box>
);

const PreviousButton = connect(
  null,
  dispatch => ({
    forceToSecondaryPreviousLockin: bindActionCreators(forceToSecondaryPreviousLockin, dispatch)
  })
)(PreviousButtonComponent);

class SecondaryLayout extends Component {
  render() {
    return (
      <div
        id="secondary-root"
        onKeyDown={e => {
          if (e.keyCode == "38") {
            // up arrow
            this.props.forceToSecondaryPreviousLockin();
          } else if (e.keyCode == "40") {
            // down arrow
            this.props.forceToSecondaryNextLockin();
          } else if (e.keyCode == "37") {
            // left arrow
            this.props.forceToSecondaryPreviousLockin();
          } else if (e.keyCode == "39") {
            // right arrotw
            this.props.forceToSecondaryNextLockin();
          }
        }}
        tabIndex="0"
      >
        <AbsoluteDiv top={["15px", "15px", "15px", "40px"]} right={["15px", "15px", "15px", "6.1%"]} zIndex={1000}>
          <CloseButton
            onClick={e => {
              e.stopPropagation();
              this.props.history.push(basePathname(this.props.location.pathname));
            }}
          />
        </AbsoluteDiv>
        <div id="secondary-layout-children">{this.props.children}</div>
        <HorizontalScrollBar />
        {this.props.browser.greaterThan.small ? (
          <Flex
            position={"absolute"}
            right={"5vw"}
            height={"100%"}
            width={"50px"}
            justifyContent={"center"}
            alignItems={"center"}
            zIndex={10000}
            flexDirection={"column"}
          >
            <PreviousButton />
            <Box height={"16px"} width={"100%"} />
            <NextButton />
          </Flex>
        ) : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  dispatch => ({
    forceToSecondaryNextLockin: bindActionCreators(forceToSecondaryNextLockin, dispatch),
    forceToSecondaryPreviousLockin: bindActionCreators(forceToSecondaryPreviousLockin, dispatch)
  })
)(withRouter(SecondaryLayout));
