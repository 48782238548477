import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";
import { hide as hideShareMenu } from "../actions/shareMenu";
import { hide as hideMenu } from "../actions/menu";
import { Route } from "react-router-dom";
import Header from "../components/header";
import AudioButton from "../components/audioButton";
import { CloseButton } from "../components/closeButton";
import { Flex, AbsoluteDiv, FixedDiv } from "../components/layout.js";
import { ModuleManager } from "../components/moduleManager.js";
import HorizontalScrollBar from "../components/horizontalScrollBar.js";
import Menu from "../components/menu.js";
import store, { history } from "../store";
import { basePathname, realPopPathname } from "../utils/routes";
import { hide as hideSponsors } from "../actions/sponsors";

import { Box, ClickCursorFlex } from "../components/layout.js";
import { Provider } from "react-redux";

import { forceToNextSubModule } from "../actions/forceScrollPercent.js";
import { forceToPreviousSubModule } from "../actions/forceScrollPercent.js";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";
import { forceToPreviousLockin } from "../actions/forceScrollPercent.js";

import { forceToSecondaryNextLockin } from "../actions/forceSecondaryScrollPercent.js";
import { forceToSecondaryPreviousLockin } from "../actions/forceSecondaryScrollPercent.js";

import { ReactComponent as DownArrowOldSVG } from "../images/down-arrow-old.svg";

class SponsorLayout extends Component {
  render() {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        position={"absolute"}
        top={"0px"}
        left={"0px"}
        zIndex={100000}
        id="sponsor-root"
        onKeyDown={e => {
          if (e.keyCode == "38") {
            // up arrow

            alert(" previous sponsor ");
          } else if (e.keyCode == "40") {
            // down arrow
            console.log(" next sponsor ");
            //this.props.forceToSecondaryNextLockin();
          } else if (e.keyCode == "37") {
            // left arrow
            console.log(" previous sponsor ");
            //this.props.forceToSecondaryPreviousLockin();
          } else if (e.keyCode == "39") {
            // right arrotw
            console.log(" next sponsor ");
            //this.props.forceToSecondaryNextLockin();
          }
        }}
        tabIndex="0"
      >
        <AbsoluteDiv top={["15px", "15px", "15px", "40px"]} right={["15px", "15px", "15px", "6.1%"]} zIndex={1000}>
          <CloseButton
            onClick={e => {
              this.props.hideSponsors();
              e.stopPropagation();
            }}
          />
        </AbsoluteDiv>
        <Flex height={"100%"} width={"100%"} position={"absolute"} top={"0px"} left={"0px"}>
          id="sponsor-layout-children">
          {this.props.children}
        </Flex>
      </Flex>
    );
  }
}

export default connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  dispatch => ({
    hideSponsors: bindActionCreators(hideSponsors, dispatch)
  })
)(withRouter(SponsorLayout));
