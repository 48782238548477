import React, { Component } from "react";
import { AbsoluteDiv, Flex, Box } from "../components/layout.js";
import { GTAmerica } from "../components/typography.js";
import { ReactComponent as HomeSVG } from "../images/home.svg";
import { ReactComponent as HeartSVG } from "../images/heart.svg";
import { ReactComponent as BasketSVG } from "../images/basket.svg";

import map from "lodash/map";

export const ChapterAccessory = ({ ...props }) => (
  <Flex position={"relative"}>
    <GTAmerica color={props.color} fontSize={["12px"]}>
      {props.index}/{props.total}
    </GTAmerica>
    <AbsoluteDiv bottom={"-1px"} left={"1px"} width={"calc(100% - 2px)"} height={"1px"} bg={"#01C993"} />
  </Flex>
);

const SupportAccessory = ({ ...props }) => (
  <>
    <Flex
      width={["11px", "11px", "11px", "12.69px"]}
      height={["13px", "13px", "13px", "15.6px"]}
      mr={["13px", "13px", "13px", "18px"]}
    >
      <HeartSVG
        style={{
          width: "100%",
          height: "100%",
          fill: "none",
          stroke: "#02DCA1",
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeDasharray: "none"
        }}
      />
    </Flex>
    <GTAmerica
      lineHeight={[1]}
      mr={["13px"]}
      size={["16px", "16px", "16px", "21px"]}
      fontWeight={500}
      color={props.color}
    >
      Support this project
    </GTAmerica>
  </>
);

const BuyAccessory = ({ ...props }) => (
  <>
    <Flex
      width={["11px", "11px", "11px", "12.69px"]}
      height={["13px", "13px", "13px", "15.6px"]}
      mr={["13px", "13px", "13px", "18px"]}
    >
      <BasketSVG
        style={{
          width: "100%",
          height: "100%",
          fill: "none",
          stroke: "#02DCA1",
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeDasharray: "none"
        }}
      />
    </Flex>
    <GTAmerica
      lineHeight={[1]}
      mr={["13px"]}
      size={["16px", "16px", "16px", "21px"]}
      fontWeight={500}
      color={props.color}
    >
      Buy Wild Chocolate
    </GTAmerica>
  </>
);

const HomeAccessory = ({ ...props }) => (
  <>
    <Flex
      width={["11px", "11px", "11px", "12.69px"]}
      height={["13px", "13px", "13px", "15.6px"]}
      mr={["13px", "13px", "13px", "18px"]}
    >
      <HomeSVG
        style={{
          width: "100%",
          height: "100%",
          fill: "#10CFAB"
        }}
      />
    </Flex>
    <GTAmerica
      lineHeight={[1]}
      mr={["13px"]}
      size={["16px", "16px", "16px", "21px"]}
      fontWeight={500}
      color={props.color}
    >
      Home
    </GTAmerica>
  </>
);

const HeaderChapterAccessory = ({ ...props }) => (
  <>
    <Flex pb={"2px"} mr={["13px", "13px", "13px", "18px"]}>
      <ChapterAccessory color={props.color} index={props.chapter} total={props.total} />
    </Flex>
    <GTAmerica
      lineHeight={[1]}
      mr={["13px"]}
      size={["16px", "16px", "16px", "21px"]}
      fontWeight={500}
      color={props.color}
    >
      {props.title}
    </GTAmerica>
  </>
);

export const chapters = map(
  [
    {
      title: "Home",
      scrollTitle: "",
      headerAccessory: color => <HomeAccessory color={color} />
    },
    {
      title: "Wild Chocolate bar",
      scrollTitle: "Chapter 1",
      headerAccessory: color => (
        <HeaderChapterAccessory title={"Wild Chocolate bar"} chapter={1} total={4} color={color} />
      )
    },
    {
      title: "Purus River region",
      scrollTitle: "Chapter 2",
      headerAccessory: color => (
        <HeaderChapterAccessory title={"Purus River region"} chapter={2} total={4} color={color} />
      )
    },
    {
      title: "São Sebastião community",
      scrollTitle: "Chapter 3",
      headerAccessory: color => (
        <HeaderChapterAccessory title={"São Sebastião community"} chapter={3} total={4} color={color} />
      )
    },
    {
      title: "Cacao Forest",
      scrollTitle: "Chapter 4",
      headerAccessory: color => <HeaderChapterAccessory title={"Cacao Forest"} chapter={4} total={4} color={color} />
    },
    {
      title: "Buy Wild Chocolate",
      scrollTitle: "",
      headerAccessory: color => <BuyAccessory color={color} />
    },
    {
      title: "Support this project",
      scrollTitle: "",
      headerAccessory: color => <SupportAccessory color={color} />
    }
  ],
  chapter => Object.assign({}, { url: ("" + chapter.title + "").replace(/ /g, "-").toLowerCase() }, { ...chapter })
);
