import React, { Component } from "react";
import { connect } from "react-redux";
import { Bridge } from "./bridgeInteractive";
import { modules } from "./index";
import { ID } from "../utils/id";

import { bindActionCreators } from "redux";

export const bridgeModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 0,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [],
      middle: [],
      bottom: [<Bridge />]
    }
  ]
};
