import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import birdsEyeVillageIMG from "../images/birds-eye-village.png";
import birdsEyeVillageMobileIMG from "../images/birds-eye-village-annotated-mobile-cropped.png";
import birdsEyeVillageAnnotatedSVG from "../images/birds-eye-village-annotated-laptop-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentSVG } from "../images/birds-eye-village-annotated-laptop-01.svg";
import birdsEyeVillageAnnotatedMobileSVG from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import { ReactComponent as BirdsEyeVillageAnnotatedComponentMobileSVG } from "../images/birds-eye-village-annotated-mobile-hitboxed-01.svg";
import ladyProfilePicIMG from "../images/lady.jpeg";
import kitchenProfilePicIMG from "../images/kitchen.jpeg";
import spiritualProfilePicIMG from "../images/spiritual.jpeg";
import { toggle as toggleShareMenu } from "../actions/shareMenu";
import educationProfilePicIMG from "../images/education.png";
import VideoInteractive from "./video";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen, UpperLeftText } from "./3dComponents";
import TWEEN from "@tweenjs/tween.js";
import Iframe from "react-iframe";
import { RiverNextButton } from "./animals";
import { AnimalText } from "./components";

import { Swipeable } from "react-swipeable";
import { CacaoDay } from "../modules/cacaoDayInteractive";
import riverMapIMG from "../images/river-map.jpeg";
import riverMapAnnotationsSVG from "../images/annotated-river-map-expanded.svg";

import riverVideo from "../images/drone-boat-warp-flipped-fullspeed-v2-shrunk.mp4";
import camillosMessageIMG from "../images/camillosMessageCover.jpg";
import { VideoWrapper, VideoContainer } from "./video";

import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";
import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
import { forceToNextLockin, forceToPreviousLockin } from "../actions/forceScrollPercent.js";
import blackLinkIMG from "../images/link-black.svg";
import throttle from "lodash/throttle";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const ShadowedFlex = styled(Flex)`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  overflow: hidden;
`;

const throttledWheelHandler = throttle(
  (deltaY, forceToNextLockin, forceToPreviousLockin) => {
    if (deltaY > 0) {
      forceToNextLockin();
    } else if (deltaY < 0) {
      forceToPreviousLockin();
    }
  },
  2000,
  { leaading: true, trailing: false }
);
const ShadowedFlexComponent = ({ ...props }) => (
  <ShadowedFlex
    bg={"black"}
    mt={["40px"]}
    height={["auto"]}
    width={["100%", "50%", "50%", "50%"]}
    justifyContent={"center"}
    position={"relative"}
    alignItems={"center"}
    onClick={() => props.history.push("/day/camillosmessage")}
    onWheel={event => {
      throttledWheelHandler(event.deltaY, props.forceToNextLockin, props.forceToPreviousLockin);
    }}
    pointerEvents={"auto"}
  >
    <Swipeable
      onSwipedUp={eventData => props.forceToNextLockin()}
      onSwipedDown={eventData => props.forceToPreviousLockin()}
    >
      {props.children}
    </Swipeable>
  </ShadowedFlex>
);
export const ShadowedFlexWrapped = connect(
  null,
  dispatch => ({
    forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch),
    forceToPreviousLockin: bindActionCreators(forceToPreviousLockin, dispatch)
  })
)(withRouter(ShadowedFlexComponent));

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    px={["18px", "18px", "18px", "5.1%"]}
    pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
    width={["auto", "auto", "auto", "40%"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["left", "left", "left", "left"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(106, 93, 90, 0.7) 0%,
    rgba(51, 35, 31, 0.7) 0.01%,
    rgba(51, 35, 31, 0) 55.8%
  );
`;
const ShareLinkFlex = styled(Flex)`
  background-image: url('${blackLinkIMG}');
  background-size:contain;
  background-position:center center;
  background-repeat:no-repeat;
`;
const ShareButton = ({ ...props }) => (
  <ClickCursorFlex
    position={"relative"}
    justifyContent={"center"}
    alignItems={"center"}
    height={["45px", "45px", "45px", "70px"]}
    borderRadius={["22.5px", "22.5px", "22.5px", "35px"]}
    bg={"#FFFFFF"}
    pointerEvents={"auto"}
    px={["22px", "22px", "22px", "35px"]}
    onClick={event => {
      if (props.clickCallback) {
        props.clickCallback(event);
      }
      event.stopPropagation();
    }}
  >
    <Flex height={"100%"} justifyContent={"center"} alignItems={"center"}>
      <ShareLinkFlex
        mr={"10px"}
        height={["14px", "14px", "14px", "14px", "18px"]}
        width={["14px", "14px", "14px", "18px"]}
      />
      <GTAmerica
        letterSpacing={"0.15em"}
        fontWeight={700}
        fontSize={["11px", "11px", "11px", "18px"]}
        lineHeight={["17px", "17px", "17px", "27px"]}
        color={"black"}
        textAlign={["center"]}
      >
        SHARE THIS ORIGIN STORY
      </GTAmerica>
    </Flex>
  </ClickCursorFlex>
);

const SupportButton = ({ ...props }) => (
  <ClickCursorFlex
    position={"relative"}
    justifyContent={"center"}
    alignItems={"center"}
    height={["45px", "45px", "45px", "70px"]}
    borderRadius={["22.5px", "22.5px", "22.5px", "35px"]}
    bg={"#02DCA1"}
    pointerEvents={"all"}
    px={["22px", "22px", "22px", "35px"]}
  >
    <button
      style={{
        border: "0px solid transparent",
        background: "transparent",
        cursor: "pointer"
      }}
      type="submit"
      border="0"
      name="submit"
      title="PayPal - The safer, easier way to pay online!"
      alt="Donate with PayPal button"
    >
      <Flex height={"100%"} justifyContent={"center"} alignItems={"center"} pointerEvents={"all"}>
        <GTAmerica
          letterSpacing={"0.15em"}
          fontWeight={700}
          fontSize={["11px", "11px", "11px", "18px"]}
          lineHeight={["17px", "17px", "17px", "27px"]}
          color={"black"}
          textAlign={["center"]}
        >
          SUPPORT WILD
        </GTAmerica>
      </Flex>
    </button>
  </ClickCursorFlex>
);

class HeroCacaoDay extends Component {
  state = {
    progress: 0
  };

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.secondRef = React.createRef();
    this.firstTimeline = null;
    this.secondTimeline = null;
  }

  componentDidMount() {
    this.firstTimelineSetup();
    this.secondTimelineSetup();
  }

  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenMax.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.add(
      TweenMax.to(this.firstRef, 2.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.firstTimeline.pause();
  }
  secondTimelineSetup() {
    this.secondTimeline = new TimelineMax();

    this.secondTimeline.add(
      TweenMax.set(this.secondRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 2.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1,
        display: "block"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.add(
      TweenMax.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0,
        display: "none"
      })
    );
    this.secondTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
    this.secondTimeline.progress(value);
  }
  render() {
    return (
      <>
        <CacaoDay />
        <Gradient />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <Flex
            pt={["12.9vh", "12.9vh", "12.9vh", "21.4vh"]}
            width={["auto", "auto", "auto", "40%"]}
            position={"absolute"}
            top={"0px"}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            flexDirection={["column", "column", "column", "column"]}
            alignItems={"flex-start"}
            px={["18px", "18px", "18px", "5.1%"]}
          >
            <GTAmerica
              fontSize={["21px", "21px", "21px", "36px"]}
              lineHeight={["23px", "23px", "23px", "46px"]}
              fontStyle={["normal", "normal", "normal", "normal"]}
              textAlign={["left", "left", "left", "left"]}
              fontWeight={[400]}
              color={"white"}
            >
              “Those who live from nature feel inside their own blood what runs in the blood of the planet.”
            </GTAmerica>
            <GTAmerica
              color={"white"}
              pt={["30px"]}
              fontSize={["21px", "21px", "21px", "28px"]}
              lineHeight={["27px", "27px", "27px", "36px"]}
              fontStyle={["normal", "normal", "normal", "normal"]}
              textAlign={["left", "left", "left", "left"]}
              fontWeight={[400]}
            >
              –Camilo
            </GTAmerica>
            <GTAmerica
              pt={["16px"]}
              color={"white"}
              fontSize={["16px", "16px", "16px", "21px"]}
              lineHeight={["normal", "normal", "normal", "normal"]}
              fontStyle={["normal", "normal", "normal", "normal"]}
              textAlign={["left", "left", "left", "left"]}
              fontWeight={[400]}
            >
              Director of Production of the Mapiá and Middle <br /> Purus Agroextractivist Cooperative
            </GTAmerica>
            <ShadowedFlexWrapped>
              <CoverImg objectPositionY={"50%"} objectPositionX={"50%"} src={camillosMessageIMG} />
              <Flex
                flexDirection={"column"}
                pointerEvents={"all"}
                top={"0px"}
                left={"0px"}
                width={"100%"}
                height={"100%"}
                position={"absolute"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PlayButton mb={"20px"} clickHandler={() => this.props.history.push("/day/camillosmessage")} />
                <GTAmerica
                  fontWeight={700}
                  fontSize={["11px", "11px", "11px", "14px"]}
                  lineHeight={["17px", "17px", "17px", "20px"]}
                  letterSpacing={"0.15em"}
                  color={"white"}
                  textAlign={["center"]}
                >
                  CAMILLO'S MESSAGE
                </GTAmerica>
              </Flex>
            </ShadowedFlexWrapped>
          </Flex>
        </FullScreen>
        <FullScreen innerRef={div => (this.secondRef = div)}>
          <AnimalText color={["white", "white", "white", "white"]}>
            I am wild. Wild by nature. Some say magical...
          </AnimalText>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "15.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"auto"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <ShareButton
              clickCallback={event => {
                this.props.toggleShareMenu();
              }}
            />
            ,
            <Flex mt={["15px", "15px", "15px", "22px"]} pointerEvents={"all"}>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="29L5D2CDPA468" />
                <input
                  style={{ visibility: "hidden", position: "absolute" }}
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <SupportButton />,
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </Flex>
          </Flex>
        </FullScreen>
      </>
    );
  }
}

export default (HeroCacaoDay = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  dispatch => ({
    toggleShareMenu: bindActionCreators(toggleShareMenu, dispatch)
  })
)(withRouter(HeroCacaoDay)));
