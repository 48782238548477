import React, { Component } from "react";
import { connect } from "react-redux";
import { MissCacao } from "./missCacaoInteractive";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen } from "./3dComponents";
import { enable as enableAudio } from "../actions/audio";
import { toggle as toggleAudio } from "../actions/audio";
import { bindActionCreators } from "redux";
import { Media, Player, controls } from "react-media-player";
import { withMediaProps } from "react-media-player";
import {
  HeroLanding,
  SecondHeroText,
  ThirdHeroText,
  HeroNextButton,
  FourthHeroText,
  HeroHeadphoneButton
} from "./hero";
import { layers as chocoLayers } from "./chocolateBarInteractiveImageImports";
import styled from "styled-components";
import map from "lodash/map";
import TWEEN from "@tweenjs/tween.js";

import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...
const plugins = [CSSPlugin, AttrPlugin];

const Fragment = React.Fragment;
class HeroComponent extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.secondRef = React.createRef();
    this.thirdRef = React.createRef();
    this.fourthRef = React.createRef();
    this.firstTimeline = null;
    this.secondTimeline = null;
    this.thirdTimeline = null;
    this.fourthTimeline = null;
    this.timelines = [this.firstTimeline, this.secondTimeline, this.thirdTimeline, this.fourthTimeline];
  }
  componentDidMount() {
    this.firstTimelineSetup();
    this.secondTimelineSetup();
    this.thirdTimelineSetup();
    this.fourthTimelineSetup();
    let chocos = chocoLayers;
    map(chocos, function(layer) {
      new Image().src = layer.image;
    });
  }
  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenLite.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.25, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.25, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 6, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  secondTimelineSetup() {
    this.secondTimeline = new TimelineMax();

    this.secondTimeline.add(
      TweenLite.set(this.secondRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.secondTimeline.add(
      TweenLite.to(this.secondRef, 1, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.secondTimeline.add(
      TweenLite.to(this.secondRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.secondTimeline.add(
      TweenLite.to(this.secondRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.secondTimeline.add(
      TweenLite.to(this.secondRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.secondTimeline.add(
      TweenLite.to(this.secondRef, 4, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.secondTimeline.pause();
  }
  thirdTimelineSetup() {
    this.thirdTimeline = new TimelineMax();

    this.thirdTimeline.add(
      TweenLite.set(this.thirdRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.thirdTimeline.add(
      TweenLite.to(this.thirdRef, 3, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.thirdTimeline.add(
      TweenLite.to(this.thirdRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.thirdTimeline.add(
      TweenLite.to(this.thirdRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.thirdTimeline.add(
      TweenLite.to(this.thirdRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.thirdTimeline.add(
      TweenLite.to(this.thirdRef, 2, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.thirdTimeline.pause();
  }
  fourthTimelineSetup() {
    this.fourthTimeline = new TimelineMax();

    this.fourthTimeline.add(
      TweenLite.set(this.fourthRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.fourthTimeline.add(
      TweenLite.to(this.fourthRef, 5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.fourthTimeline.add(
      TweenLite.to(this.fourthRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.fourthTimeline.add(
      TweenLite.to(this.fourthRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.fourthTimeline.add(
      TweenLite.to(this.fourthRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.fourthTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;
      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let delay = 0;
      let duration = 1000;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 1000;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this.firstTimeline.progress(obj.value);
          this.secondTimeline.progress(obj.value);
          this.thirdTimeline.progress(obj.value);
          this.fourthTimeline.progress(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  render() {
    return (
      <>
        <MissCacao />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <HeroLanding />
            <HeroNextButton mobileText={"START SCROLLING"} desktopText={"START SCROLLING"} />
          </Flex>
        </FullScreen>
        <FullScreen innerRef={div => (this.secondRef = div)}>
          <Flex
            position={"absolute"}
            bottom={["5.3vh", "5.3vh", "5.3vh", "13.2vh"]}
            left={"0px"}
            right={"0px"}
            pointerEvents={"none"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <SecondHeroText />
            <HeroNextButton
              mobileText={"KEEP SCROLLING"}
              desktopText={
                <Fragment>
                  KEEP SCROLLING
                  <br />
                  OR USE ARROW KEYS
                </Fragment>
              }
            />
          </Flex>
        </FullScreen>
        <FullScreen innerRef={div => (this.thirdRef = div)}>
          <ThirdHeroText />
        </FullScreen>
        <FullScreen innerRef={div => (this.fourthRef = div)}>
          <HeroHeadphoneButton
            clickCallback={() => {
              this.props.media.play();
              if (this.props.audio.touched) {
                this.props.toggleAudio();
                this.props.media.muteUnmute();
              }
            }}
          />
          <FourthHeroText />
        </FullScreen>
      </>
    );
  }
}

export default (HeroComponent = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    audio: state.audio,
    browser: state.browser
  }),
  dispatch => ({
    enableAudio: bindActionCreators(enableAudio, dispatch),
    toggleAudio: bindActionCreators(toggleAudio, dispatch)
  })
)(withMediaProps(HeroComponent)));
