const isVisible = "isVisible";
const index = "index";
export const defaultState = { isVisible: false, index: 0 };

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "TOGGLE_DATASOURCE":
      newState[isVisible] = !state[isVisible];
      newState[index] = action.index;
      return newState;
    case "HIDE_DATASOURCE":
      newState[isVisible] = false;
      newState[index] = state.index;
      return newState;
    default:
      return state;
  }
};
