import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica, GTAmericaStyled } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import forestIMG from "../images/forest-360.jpeg";
import buyChocolateIMG from "../images/buy-chocolate.png";
import missCacaoIMG from "../images/misscacao.jpeg";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["center", "center", "center", "center"]}
    py={["3vh", "3vh", "3vh", "3vh"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const MissCacao360Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #6a5d5a 0%,
    #33231f 0.01%,
    rgba(51, 35, 31, 0) 50.28%
  );
`;

const CTAText = styled(GTAmericaStyled)`
  letter-spacing: 0.15em;
  font-weight: bold;
`;
const CTAButton = ({ ...props }) => (
  <Flex
    height={["45px", "45px", "45px", "70px"]}
    width={["250px", "250px", "250px", "400px"]}
    borderRadius={["45px", "45px", "45px", "50px"]}
    bg={["#10CFAB"]}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <CTAText
      fontWeight={700}
      fontSize={["14px", "14px", "14px", "18px"]}
      lineHeight={["20px", "20px", "20px", "25px"]}
      fontStyle={["normal"]}
      textAlign={["center"]}
      fontWeight={[400]}
      color={"#292121"}
    >
      {props.children}
    </CTAText>
  </Flex>
);
export const buyModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 5,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <Flex
            width={["84.8vw", "84.8vw", "84.8vw", "52vw"]}
            height={["28.3vh", "28.3vh", "28.3vh", "43vh"]}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ImgStyled src={buyChocolateIMG} />
          </Flex>
          <Text color={"white"}>Support my home and the planet we share</Text>
          <ClickCursorFlex flexDirection={"column"} pointerEvents={"auto"}>
            <CTAButton>BUY WILD CHOCOLATE</CTAButton>
          </ClickCursorFlex>
        </Flex>
      ],
      middle: [],
      bottom: [
        <ClickCursorFlex bg={"##202020"} width={"100%"} height={"100%"} />
      ]
    }
  ]
};
