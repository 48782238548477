import React, { Component } from "react";
import styled from "styled-components";
import { Flex, Box, ClickCursorFlex } from "./layout.js";
import { ReactComponent as CloseSVG } from "../images/close.svg";

const CloseFlex = styled(ClickCursorFlex)`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;
export const CloseButton = ({ ...props }) => (
  <CloseFlex
    alignItems={"center"}
    justifyContent={"center"}
    height={["50px", "50px", "50px", "70px"]}
    width={["50px", "50px", "50px", "70px"]}
    bg={"#10CFAB"}
    borderRadius={["50%"]}
    {...props}
  >
    <Box
      width={["14px", "14px", "14px", "16px"]}
      height={["14px", "14px", "14px", "16px"]}
    >
      <CloseSVG
        style={{
          width: "100%",
          height: "100%",
          fill: "white",
          stroke: "none"
        }}
      />
    </Box>
  </CloseFlex>
);
