const isEnabled = "isEnabled";
const touched = "touched";
export const defaultState = { isEnabled: false, touched: false };

export default (state = defaultState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "TOGGLE_AUDIO":
      newState[isEnabled] = !state[isEnabled];
      newState[touched] = true;
      return newState;
    case "ENABLE_AUDIO":
      newState[isEnabled] = true;
      newState[touched] = true;
      return newState;
    case "DISABLE_AUDIO":
      newState[isEnabled] = false;
      newState[touched] = true;
      return newState;
    default:
      return state;
  }
};
