import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleDatasource } from "../actions/datasource";
import { DataSourceButton } from "../modules/components";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";

class DataSourceStandardButton extends Component {
  render() {
    return (
      <Flex
        position={"absolute"}
        right={["2.6%", "2.6%", "2.6%", "2.6%"]}
        bottom={["1.5%", "1.5%", "1.5%", "1.5%"]}
      >
        <DataSourceButton
          color={this.props.color}
          flipped={this.props.datasource.isVisible}
          clickHandler={() => {
            this.props.toggleDatasource(this.props.index);
          }}
        />
      </Flex>
    );
  }
}

export default connect(
  state => ({
    datasource: state.datasource
  }),
  dispatch => ({
    toggleDatasource: bindActionCreators(toggleDatasource, dispatch)
  })
)(DataSourceStandardButton);
