import React, { Component } from "react";
import { Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import Header from "./components/header";
import { AudioButton } from "./components/audioButton";
import { Flex, AbsoluteDiv } from "./components/layout.js";
import ModuleManager from "./components/moduleManager.js";
import "./App.scss";
import store, { history } from "./store";

import MissCacao from "./modules/missCacaoInteractive";
import { modules } from "./modules/index";
import { educationModules } from "./modules/index";
import { Provider } from "react-redux";
import PrimaryLayout from "./layouts/primary";
import SecondaryLayout from "./layouts/secondary";
//import { Router } from "react-router-dom";
//import routes from "./routes";

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        React.createElement(layout, rest, React.createElement(component, rest))
      }
    />
  );
};

export const router = (
  <div>
    <RouteWithLayout
      layout={PrimaryLayout}
      component={ModuleManager}
      modules={modules}
      id="primary"
      path="/"
    />
  </div>
);

export default router;
