import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateScrollPercent } from "../actions/scrollPercent";
import { updateMouseXY } from "../actions/mouseXY";
import { updateDeltas } from "../actions/deltas";
import { updateSecondaryScrollPercent } from "../actions/secondaryScrollPercent";
import { hideDatasource } from "../actions/datasource";
import { forceToNextLockin } from "../actions/forceScrollPercent.js";

import { Flex, ClickCursorFlex } from "./layout.js";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { moduleAnimationIndexes } from "../modules/index";

import { Swipeable } from "react-swipeable";

import { moduleArrayIndex, subModulePercent, totalScrollPercentFor } from "../utils/scroll";

import { findIndex } from "lodash";
import { popRoute, popPathname, basePathname, baseRoute } from "../utils/routes";

import map from "lodash/map";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import throttle from "lodash/throttle";
import includes from "lodash/includes";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";

// Setup the animation loop.
function animate(time) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}
requestAnimationFrame(animate);

String.prototype.hashCode = function() {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const infiniteStartSubPercent = 0.05;
const scrollDiffIgnore = 0.0025;
const StateVisualizer = styled.div`
  min-width: 500px;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : "white")};
  padding: 10px;
  text-align: left;
  color: black;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  pointer-events: none;
`;
const ModuleManagerRoot = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0);
  z-index: 500;
  overflow: hidden;
`;

const SwipeManager = styled.div`
  pointer-events: auto;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: -30px;
  bottom: -20px;
  z-index: 0;
  overflow: ${props => (props.overflowProp ? props.overflowProp : "scroll")};
`;

const ScrollManager = styled.div`
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: -20px;
  z-index: 0;
  overflow: ${props => (props.overflowProp ? props.overflowProp : "scroll")};
`;

const Scroller = styled(Flex)`
  width: 100%;
`;

/*
const modules = [
  {
    name: "miss cacao",
    percent: 100,
    route: "/"
  },
  {
    name: "chocolate bar",
    percent: 100,
    route: "/chocolate"
  }
];
*/

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

class ModuleManager extends Component {
  state = {
    moduleIndex: 0,
    lastModuleIndex: 0,
    scrollTop: 0,
    scrollPercent: 0,
    scrollDirectionUp: false,
    scrollDirectionDown: true,
    scrollMultiplierConstant: 5,
    isMobileTablet: false,
    swipeTop: 0,
    swipeMax: 1,
    scrollOverflow: "scroll",
    swiperScrollTopMidPoint: 0
  };
  swipeDebounceTimeout = 1200;
  constructor(props) {
    super(props);
    this.root = React.createRef();
    this.scroller = React.createRef();
    this.swiper = React.createRef();
    this.swiperScroller = React.createRef();
    this.state.isMobileTablet = window.mobileAndTabletcheck();
    this.state.scrollMultiplier =
      reduce(
        this.props.modules,
        function(sum, n) {
          return sum + n.percent / 100;
        },
        0
      ) * this.state.scrollMultiplierConstant;

    this._swipeThrottled = throttle(
      (wheelAmount, direction) => {
        this._handleSwipeEvent(wheelAmount, direction);
      },
      this.swipeDebounceTimeout,
      { leading: true, trailing: false }
    );
    this.scrollLock = false;
    this.moduleLock = false;
    this._throttledUpdateScrollPercent = throttle(scrollPercent => {
      this._updateScrollPercent(scrollPercent, true);
    }, 100);

    this.leavingInfinite = false;

    // Use different route popping functions for secondary vs. primary module management
    if (this.props.isSecondary) {
      this.basePathname = popPathname;
      this.baseRoute = popRoute;
    } else {
      this.basePathname = basePathname;
      this.baseRoute = baseRoute;
    }
  }
  _updateScrollPercent(scrollPercent, fromThrottler) {
    if (fromThrottler && this.leavingInfinite) {
      return;
    }
    let subPercentRaw = subModulePercent(scrollPercent, this.props.modules);
    if (this.props.id === "primary") {
      this.props.updateScrollPercent(scrollPercent);
    } else if (this.props.id === "secondary") {
      this.props.updateSecondaryScrollPercent(scrollPercent, this.props.modules);
    }
  }

  _swipeUnthrottled(wheelAmount) {
    console.log("swipe " + wheelAmount);
    if (this.state.swipeOverflow == "hidden") {
      return;
    }
    this._swipeThrottled(wheelAmount, wheelAmount > 0 ? "down" : "up");
  }
  componentWillMount() {}
  componentDidMount() {
    const { modules } = this.props;

    this.resetSwiper();
    // Update scrollPosition for initial route
    let path = this.props.location.pathname;
    this.updateLocalStateForRoute(path);

    // TODO
    /*
    if (this.props.startingPercent && this.props.startingPercent > 0) {
      this._updateScrollPercent(this.props.startingPercent);
    } else {
      this._updateScrollPercent(0);
    }
    */
  }
  updateLocalStateForRoute(path) {
    // Update scrollPosition for initial route
    const { modules } = this.props;

    let indexForRoute = findIndex(modules, o => {
      return includes(o.routes, path);
    });
    if (indexForRoute == -1) {
      this.props.history.push("/");
      this.props.forceToNextLockin();
      return;
    }
    if (indexForRoute >= 1) {
      let firstLockin = modules[indexForRoute].lockinPercents[0];
      let subPercent = firstLockin !== undefined ? firstLockin : 0.1;
      let percentForIndex = totalScrollPercentFor(indexForRoute, subPercent, modules);
      this._updateScrollPercent(percentForIndex, false);
      this._forceUpdateScrollPercent(percentForIndex);
    } else {
      let percentForIndex = totalScrollPercentFor(indexForRoute, modules[0].lockinPercents[0], modules);
      this._updateScrollPercent(percentForIndex, false);
      this._forceUpdateScrollPercent(percentForIndex);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.forceScrollPercent.timestamp !== this.props.forceScrollPercent.timestamp) {
      let scrollPercent = nextProps.forceScrollPercent.percent;
      this._handleProgrammaticScrollSingleLocking(scrollPercent);
      setTimeout(() => {
        this._updateScrollPercent(scrollPercent, false);
      }, 0);
    } else if (nextProps.forceScrollPercent.lockinTimestamp !== this.props.forceScrollPercent.lockinTimestamp) {
      this._nextLockin(nextProps.forceScrollPercent.nextLockinDirectionUp);
    } else if (this.basePathname(nextProps.location.pathname) !== this.basePathname(this.props.location.pathname)) {
      let path = nextProps.location.pathname;
      //this.updateLocalStateForRoute(path);
    } else if (
      nextProps.forceSecondaryScrollPercent.lockinTimestamp !== this.props.forceSecondaryScrollPercent.lockinTimestamp
    ) {
      if (this.props.id == "secondary") {
        this._nextLockin(nextProps.forceSecondaryScrollPercent.nextLockinDirectionUp);
      }
    }
  }

  _forceUpdateScrollPercent(scrollPercent) {
    //Perform some operation
    let newScrollTop =
      scrollPercent *
      (this.root && this.root.current ? this.root.current.clientHeight * (this.state.scrollMultiplier - 1) : 1);

    if (this.scroller && this.scroller.current) {
      this.scroller.current.scrollTop = newScrollTop;
    }

    this._setLocalScrollStateFromPercent(scrollPercent);
  }
  _forceUpdateScrollPercentReduxOnly(scrollPercent) {
    //Perform some operation
  }
  _unthrottledUpdateScrollPercent(scrollPercent) {
    if (this.props.id === "primary") {
      this.props.updateScrollPercent(scrollPercent);
    } else if (this.props.id === "secondary") {
      this.props.updateSecondaryScrollPercent(scrollPercent, this.props.modules);
    }
  }
  _mobileSwipeDown(eventData) {
    // This plugin's "up" is down in my terminology
    //
    if (eventData.velocity < 0.1) {
      return;
    }
    let swipeDirectionUp = true;
    this.setState({
      scrollDirectionUp: swipeDirectionUp,
      scrollDirectionDown: !swipeDirectionUp,
      scrollPercent: swipeDirectionUp
        ? this.state.scrollPercent - (scrollDiffIgnore * 2 + 0.00001)
        : this.state.scrollPercent + (scrollDiffIgnore * 2 + 0.00001)
    });

    this._nextLockin(swipeDirectionUp);
    setTimeout(() => {
      this.props.updateDeltas(0, 0);
    }, 700);
  }
  _mobileSwipeUp(eventData) {
    // This plugin's "up" is down in my terminology
    //
    if (eventData.velocity < 0.1) {
      return;
    }
    let swipeDirectionUp = false;
    this.setState({
      scrollDirectionUp: swipeDirectionUp,
      scrollDirectionDown: !swipeDirectionUp,
      scrollPercent: swipeDirectionUp
        ? this.state.scrollPercent - (scrollDiffIgnore * 2 + 0.00001)
        : this.state.scrollPercent + (scrollDiffIgnore * 2 + 0.00001)
    });

    this._nextLockin(swipeDirectionUp);
    setTimeout(() => {
      this.props.updateDeltas(0, 0);
    }, 700);
  }
  _handleSwipeEvent(scrollTop, direction) {
    this._swipeThrottled.cancel();
    if (this.scrollLock == true || this.state.swipeOverflow == "hidden") {
      return;
    }

    let swipeDirectionUp = direction == "up";
    //if (scrollTop < this.state.swiperScrollTopMidPoint) {
    //swipeDirectionUp = true;
    //} else if (scrollTop >= this.state.swiperScrollTopMidPoint) {
    //swipeDirectionUp = false;
    //}

    let newScrollPercent = swipeDirectionUp
      ? this.state.scrollPercent - (scrollDiffIgnore * 2 + 0.00001)
      : this.state.scrollPercent + (scrollDiffIgnore * 2 + 0.00001);

    // Return early if we are moduleLocked (dont want to fuck up the animation)
    let boundedScrollPercent = Math.max(Math.min(newScrollPercent, 0.99), 0.01);
    let moduleIndex = moduleArrayIndex(boundedScrollPercent, this.props.modules);
    if (
      this._willSwipeToANewModule(swipeDirectionUp, newScrollPercent) &&
      this.moduleLock &&
      (includes(moduleAnimationIndexes(this.props.modules), moduleIndex) ||
        includes(moduleAnimationIndexes(this.props.modules), this.state.lastModuleIndex))
    ) {
      return;
    }

    let swipeMax = scrollTop >= this.state.swipeMax ? scrollTop : this.state.swipeMax;
    this.setState({
      swipeOverflow: "hidden",
      subPercentRaw: subModulePercent(newScrollPercent, this.props.modules),
      swipeTop: scrollTop,
      swipeMax: swipeMax,
      scrollDirectionUp: swipeDirectionUp,
      scrollDirectionDown: !swipeDirectionUp,
      scrollPercent: newScrollPercent
    });

    // Might not be necc.
    this.swipeLock = true;
    if (this.swiper && this.swiper.current) {
      //this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
      setTimeout(() => {
        console.log("swipe zerod");
        if (this.swiper && this.swiper.current) {
          this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
        }
        setTimeout(() => {
          this.setState({
            swipeOverflow: "scroll"
          });
          this.swipeLock = false;
          setTimeout(() => {
            if (this.swiper && this.swiper.current) {
              //this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
              this.swipeLock = false;
            }
          }, 50);
        }, 50);
      }, this.swipeDebounceTimeout + 100);
    }

    console.log("swipe " + (swipeDirectionUp ? " up " : " down ") + scrollTop);

    this._nextLockin(swipeDirectionUp);
    return true;
  }
  resetSwiper() {
    this.setState({
      swipeTop: this.state.swiperScrollTopMidPoint,
      lastSwipeTop: this.state.swiperScrollTopMidPoint,
      swipeOverflow: "hidden"
    });

    // Might not be necc.
    if (this.swiper && this.swiper.current) {
      //this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
      setTimeout(() => {
        console.log("swipe zerod");
        if (this.swiper && this.swiper.current) {
          this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
        }
        setTimeout(() => {
          this.setState({
            swipeOverflow: "scroll"
          });
          setTimeout(() => {
            if (this.swiper && this.swiper.current) {
              //this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
              //this.scrollLock = false;
            }
          }, 50);
        }, 50);
      }, this.swipeDebounceTimeout + 100);
    }
  }
  // Handle user-driven scroll event
  //
  _handleScrollEvent(event) {
    let hasLockinsToLockFor = this._currentModuleHasLockins();
    if (this.scrollLock == true && hasLockinsToLockFor) {
      return;
    }

    let scrollTop = event.currentTarget.scrollTop;
    this.leavingInfinite = false;
    this._handleScroll(scrollTop);

    return true;
  }
  _handleScroll(scrollTop) {
    const { modules } = this.props;
    let scrollPercent =
      scrollTop /
      (this.root && this.root.current ? this.root.current.clientHeight * (this.state.scrollMultiplier - 1) : 1);

    const scrollDirectionDown = this.state.scrollPercent < scrollPercent;
    const scrollDirectionUp = !scrollDirectionDown;

    let boundedScrollPercent = Math.max(Math.min(scrollPercent, 0.99), 0.01);
    let moduleIndex = moduleArrayIndex(boundedScrollPercent, modules);
    let lastModuleIndex = this.state.moduleIndex;

    this.setState({
      subPercentRaw: subModulePercent(scrollPercent, modules),
      lastModuleIndex: lastModuleIndex,
      moduleIndex: moduleIndex,
      scrollTop: scrollTop,
      scrollPercent: scrollPercent,
      scrollDirectionDown: scrollDirectionDown,
      scrollDirectionUp: !scrollDirectionDown
    });

    this._updateRoute(moduleIndex, modules);

    //console.log(scrollPercent);
    /*
    if (this.scrollLock == false) {
      this._lockinDebouncer();
    }
    */

    if (lastModuleIndex != moduleIndex) {
      this.setState({
        scrollOverflow: "hidden"
      });
      // Might not be necc.
      if (this.scroller && this.scroller.current) {
        this.scrollLock = true;
        setTimeout(() => {
          this.scrollLock = false;
          this.setState({
            scrollOverflow: "scroll"
          });
        }, 1000);
      }

      if (this._moduleHasLockins(moduleIndex)) {
        this.setState({
          swipeOverflow: "hidden",
          swipeTop: this.state.swiperScrollTopMidPoint,
          lastSwipeTop: this.state.swiperScrollTopMidPoint
        });
        if (this.swiper && this.swiper.current) {
          this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
        }
        setTimeout(() => {
          this.setState({
            swipeOverflow: "scroll"
          });
          setTimeout(() => {
            if (this.swiper && this.swiper.current) {
              //this.swiper.current.scrollTop = this.state.swiperScrollTopMidPoint;
            }
            this.swipeLock = false;
          }, 50);
        }, 800);

        this.leavingInfinite = true;
        this._nextLockinWithPercent(scrollDirectionUp, scrollPercent);
        return;
      }
    }

    this._throttledUpdateScrollPercent(scrollPercent);
  }
  _setLocalScrollStateFromPercent(scrollPercent) {
    const { modules } = this.props;

    let boundedScrollPercent = Math.max(Math.min(scrollPercent, 0.99), 0.01);
    let moduleIndex = moduleArrayIndex(boundedScrollPercent, modules);
    let scrollDirectionDown = this.state.scrollPercent < scrollPercent;

    //Perform some operation
    let newScrollTop =
      scrollPercent *
      (this.root && this.root.current ? this.root.current.clientHeight * (this.state.scrollMultiplier - 1) : 1);

    this.setState({
      subPercentRaw: subModulePercent(scrollPercent, modules),
      lastModuleIndex: this.state.moduleIndex,
      moduleIndex: moduleIndex,
      scrollTop: newScrollTop,
      scrollPercent: scrollPercent,
      scrollDirectionDown: scrollDirectionDown,
      scrollDirectionUp: !scrollDirectionDown
    });
  }
  _updateRoute(moduleIndex, modules) {
    // Update route based on scroll position
    let module = modules[moduleIndex];
    if (module) {
      let routeForIndex = module.routes[0];
      if (includes(module.routes, this.props.location.pathname) === false) {
        this.props.history.replace(routeForIndex);
      }
    }
  }
  _handleProgrammaticScrollSingleLocking(scrollPercent) {
    // Enable scroll lock, set all the state and redux
    //
    this.scrollLock = true;
    this._handleProgrammaticScroll(scrollPercent, false);
    this._setScrollTop(scrollPercent);
    setTimeout(() => {
      this.scrollLock = false;
    }, 100);
  }
  _handleProgrammaticScroll(scrollPercent, throttled) {
    const { modules } = this.props;

    this._setLocalScrollStateFromPercent(scrollPercent);

    let boundedScrollPercent = Math.max(Math.min(scrollPercent, 0.99), 0.01);
    let moduleIndex = moduleArrayIndex(boundedScrollPercent, modules);
    this._updateRoute(moduleIndex, modules);
    // Update redux store
    if (throttled) {
      this._throttledUpdateScrollPercent(scrollPercent);
    } else {
      window.requestAnimationFrame(() => this._updateScrollPercent(scrollPercent, false));
    }
  }
  _setScrollTop(scrollPercent) {
    let newScrollTop =
      scrollPercent *
      (this.root && this.root.current ? this.root.current.clientHeight * (this.state.scrollMultiplier - 1) : 1);

    if (this.scroller && this.scroller.current) {
      this.scroller.current.scrollTop = newScrollTop;
    }
  }
  _willSwipeToANewModule(swipeDirectionUp, scrollPercent) {
    const { modules } = this.props;
    let module = this._currentModule(scrollPercent);
    let moduleIndex = moduleArrayIndex(scrollPercent, modules);
    let lockinPercents = module.lockinPercents;
    let subPercentRaw = subModulePercent(scrollPercent, modules);

    let subPercent = swipeDirectionUp ? subPercentRaw - 0.01 : subPercentRaw + 0.01;

    let matchingLockins = filter(lockinPercents, e => {
      if (swipeDirectionUp) {
        return e < subPercent;
      } else {
        return e > subPercent;
      }
    });

    // If at the top module, and no matching lockins && moving up, then return OR scroll to first lockin in module.
    if (matchingLockins.length === 0 && moduleIndex === 0 && swipeDirectionUp === true) {
      return false;
    }

    if (matchingLockins.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  _nextLockin(swipeDirectionUp) {
    this._nextLockinWithPercent(swipeDirectionUp, this.state.scrollPercent);
  }
  _nextLockinWithPercent(swipeDirectionUp, scrollPercent) {
    const { modules } = this.props;
    let module = this._currentModule(scrollPercent);
    let moduleIndex = moduleArrayIndex(scrollPercent, modules);
    let lockinPercents = module.lockinPercents;
    let subPercentRaw = subModulePercent(scrollPercent, this.props.modules);

    let subPercent = swipeDirectionUp ? subPercentRaw - 0.01 : subPercentRaw + 0.01;

    // Exit early for the last lockin
    if (moduleIndex === modules.length - 1 && subPercentRaw > 0.5 && swipeDirectionUp == false) {
      return;
    }
    let matchingLockins = filter(lockinPercents, e => {
      if (swipeDirectionUp) {
        return e < subPercent;
      } else {
        return e > subPercent;
      }
    });

    // If at the top module, and no matching lockins && moving up, then return OR scroll to first lockin in module.
    if (matchingLockins.length === 0 && moduleIndex === 0 && swipeDirectionUp === true) {
      return;
    }
    if (matchingLockins.length > 0) {
      if (swipeDirectionUp) {
        let lockinSubPercent = matchingLockins[matchingLockins.length - 1];
        this._scrollToSubPercent(lockinSubPercent, moduleIndex, false);
      } else {
        let lockinSubPercent = matchingLockins[0];
        this._scrollToSubPercent(lockinSubPercent, moduleIndex, false);
      }
    } else {
      if (swipeDirectionUp) {
        if (this.moduleLock == true) {
          return;
        }
        let newModuleIndex = Math.max(moduleIndex - 1, 0);
        let newModule = modules[newModuleIndex];

        let lockinSubPercent = 0;
        if (this._moduleHasLockins(newModuleIndex)) {
          lockinSubPercent = newModule.lockinPercents[newModule.lockinPercents.length - 1];
          this._scrollToSubPercent(lockinSubPercent, newModuleIndex, false);
        } else {
          lockinSubPercent = 1 - infiniteStartSubPercent;
          this._scrollToSubPercent(lockinSubPercent, newModuleIndex, true);
        }
      } else {
        if (this.moduleLock == true) {
          return;
        }
        let newModuleIndex = Math.min(moduleIndex + 1, this.props.modules.length - 1);
        let newModule = modules[newModuleIndex];

        let lockinSubPercent = 0;

        if (this._moduleHasLockins(newModuleIndex)) {
          lockinSubPercent = newModule.lockinPercents[0];
          this._scrollToSubPercent(lockinSubPercent, newModuleIndex, false);
        } else {
          lockinSubPercent = 0 + infiniteStartSubPercent;
          this._scrollToSubPercent(lockinSubPercent, newModuleIndex, true);
        }
      }
    }
  }
  _scrollToSubPercent(subPercent, moduleIndex, setScrollTop) {
    let isThrottled = false;
    if (setScrollTop) {
      let percent = totalScrollPercentFor(moduleIndex, subPercent, this.props.modules);
      this._handleProgrammaticScrollSingleLocking(percent, isThrottled);
      this._setScrollTop(percent);
    } else {
      this._handleProgrammaticScroll(totalScrollPercentFor(moduleIndex, subPercent, this.props.modules), isThrottled);
    }
  }
  _currentModule(scrollPercent) {
    let boundedScrollPercent = Math.max(Math.min(scrollPercent, 0.99), 0.01);
    let moduleIndex = moduleArrayIndex(boundedScrollPercent, this.props.modules);
    return this.props.modules[moduleIndex];
  }

  _tweenScrollTo() {
    console.log("callback");
  }
  _onMouseMove(e) {
    if (this.state.isMobileTablet == false) {
      this.props.updateMouseXY(e.clientX, e.clientY);
    }
  }
  _currentModuleHasLockins() {
    return this._moduleHasLockins(this.state.moduleIndex);
  }
  _moduleHasLockins(index) {
    return this.props.modules[index].lockinPercents.length > 0;
  }
  render() {
    const { modules } = this.props;
    let moduleIndex = this.state.moduleIndex;
    let useSwipeHandler = this._currentModuleHasLockins();
    return (
      <ModuleManagerRoot innerRef={this.root} onMouseMove={this._onMouseMove.bind(this)}>
        {useSwipeHandler && this.state.isMobileTablet ? null : (
          <ScrollManager
            overflowProp={this.state.scrollOverflow}
            innerRef={this.scroller}
            ref={() => {
              if (this.state.isMobileTablet && this.props.location.pathname == "/globe") {
                this.scroller.current.scrollTop = this.state.scrollTop;
              }
            }}
            className="scroll-manager"
            onScroll={event => this._handleScrollEvent(event)}
          >
            <Scroller height={this.state.scrollMultiplier * 100 + "%"} />
          </ScrollManager>
        )}
        {useSwipeHandler ? (
          <>
            {this.state.isMobileTablet ? (
              <Swipeable
                className={"mobile-swipeable"}
                onSwipedUp={eventData => this._mobileSwipeUp(eventData)}
                onSwipedDown={eventData => this._mobileSwipeDown(eventData)}
                onSwiping={eventData => this.props.updateDeltas(eventData.deltaX, eventData.deltaY)}
              />
            ) : (
              <SwipeManager
                onWheel={event => {
                  this._swipeUnthrottled(event.deltaY);
                  event.stopPropagation();
                }}
                overflowProp={"hidden"}
                innerRef={this.swiper}
                className="swipe-manager"
              >
                <Scroller innerRef={this.swiperScroller} height={"100%"} py={"10px"} />
              </SwipeManager>
            )}
          </>
        ) : null}
        <TransitionGroup
          className={`scroll-modules ${this.state.scrollDirectionUp ? "scrolling-up" : "scrolling-down"}`}
        >
          <CSSTransition
            key={this.baseRoute(this.props.location).key}
            classNames="fade"
            timeout={1500}
            unmountOnExit={true}
            onEnter={(node, isAppearing) => {
              this.moduleLock = true;
              console.log("moduleLock onEnter");
              console.log("moduleLock " + this.moduleLock);
              console.log("moduleLock onEnter");
            }}
            onExited={node => {
              this.moduleLock = false;
              this.setState({ lastModuleIndex: this.state.moduleIndex });
              console.log("moduleLock onExit");
              console.log("moduleLock " + this.moduleLock);
              console.log("moduleLock onExit");
            }}
          >
            <Switch location={this.baseRoute(this.props.location)}>
              {map(modules, (module, index) => {
                return (
                  <PropsRoute
                    key={module.id}
                    exact={module.routes[0] === "/"}
                    path={module.routes[0]}
                    component={module.component}
                    myIndex={index}
                  />
                );
              })}
              <Route render={() => <div className={`basic red`} />} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        {false ? (
          <StateVisualizer backgroundColor={this.state.swipeOverflow == "hidden" ? "red" : "white"}>
            {Object.keys(this.state).map(key => {
              return (
                <div>
                  {" "}
                  {key} :{" "}
                  {key == "scrollDirectionUp" || key == "scrollDirectionDown"
                    ? this.state[key]
                      ? "true"
                      : "false"
                    : this.state[key]}{" "}
                </div>
              );
            })}
            <div>{this.scrollLock ? "scrollLock: ON" : "scrollLock: OFF"}</div>
            <div>
              {this.scroller
                ? this.scroller.current
                  ? "realScrollTop: " + this.scroller.current.scrollTop
                  : "realScrollTop:N/A"
                : "realScrollTop:N/A"}
            </div>
            <div>{"======================================="}</div>
            {Object.keys(this.props.scrollPercent).map(key => {
              return (
                <div>
                  {" "}
                  {key} :{" "}
                  {key == "scrollDirectionUp" || key == "scrollDirectionDown"
                    ? this.props.scrollPercent[key]
                      ? "true"
                      : "false"
                    : this.props.scrollPercent[key]}{" "}
                </div>
              );
            })}
          </StateVisualizer>
        ) : null}
      </ModuleManagerRoot>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      scrollPercent: state.scrollPercent,
      secondaryScrollPercent: state.secondaryScrollPercent,
      datasource: state.datasource,
      forceScrollPercent: state.forceScrollPercent,
      eventSupportHas: state.eventSupportHas,
      forceSecondaryScrollPercent: state.forceSecondaryScrollPercent
    }),
    dispatch => ({
      updateMouseXY: bindActionCreators(updateMouseXY, dispatch),
      updateDeltas: bindActionCreators(updateDeltas, dispatch),
      hideDatasource: bindActionCreators(hideDatasource, dispatch),
      updateScrollPercent: bindActionCreators(updateScrollPercent, dispatch),
      updateSecondaryScrollPercent: bindActionCreators(updateSecondaryScrollPercent, dispatch),
      forceToNextLockin: bindActionCreators(forceToNextLockin, dispatch)
    })
  )(ModuleManager)
);
