import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GTAmerica } from "./typography.js";
import styled from "styled-components";
import { Flex, Box, ClickCursorFlex, AbsoluteDiv } from "./layout.js";
import { forceNewScrollPercent } from "../actions/forceScrollPercent";
import { ReactComponent as HeartSVG } from "../images/heart.svg";
import { ReactComponent as BasketSVG } from "../images/basket.svg";
import { ReactComponent as HomeSVG } from "../images/home.svg";
import { ReactComponent as CloseSVG } from "../images/close.svg";

import { toggle as toggleCredits } from "../actions/credits";
import { toggle as toggleSponsors } from "../actions/sponsors";
import { toggle as toggleMenu } from "../actions/menu";
import { modulePercentForChapter } from "../utils/scroll";

import { modules } from "../modules/index";

const OpacityFadeAbsoluteDiv = styled(AbsoluteDiv)`
  transition: opacity 300ms ease-in-out;
`;

class MenuRowComponent extends Component {
  state = { hover: false };

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {this.props.link ? (
          <span
            onClick={() => {
              this.props.toggleMenu();
            }}
          >
            <a href="https://wildchocolate.org/shop/chocolate/" target="_blank">
              <ClickCursorFlex
                height={["9.7vh", "9.7vh", "9.7vh", "11.3vh"]}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"flex-start"}
                position={"relative"}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseOut={() => this.setState({ hover: false })}
              >
                <Flex position={"relative"} ml={["21px", "21px", "21px", "42px"]} flexDirection={"row"}>
                  {this.props.children}
                  <OpacityFadeAbsoluteDiv
                    opacity={this.state.hover ? 1 : 0}
                    bottom={"-6px"}
                    left={"1px"}
                    width={"calc(100% - 2px)"}
                    height={"3px"}
                    bg={"#01C993"}
                  />
                </Flex>
                {this.props.hideBottomBorder === true ? null : (
                  <AbsoluteDiv bottom={"0px"} left={"0px"} width={"100%"} height={"1px"} bg={"#e5e5e5"} />
                )}
              </ClickCursorFlex>
            </a>
          </span>
        ) : (
          <span
            onClick={() => {
              this.props.onClickProp();
              this.props.toggleMenu();
            }}
          >
            <ClickCursorFlex
              height={["9.7vh", "9.7vh", "9.7vh", "11.3vh"]}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              position={"relative"}
              pointerEvents={"all"}
              onMouseOver={() => this.setState({ hover: true })}
              onMouseOut={() => this.setState({ hover: false })}
            >
              <Flex
                position={"relative"}
                ml={["21px", "21px", "21px", "42px"]}
                flexDirection={"row"}
                pointerEvents={"all"}
              >
                {this.props.children}
                <OpacityFadeAbsoluteDiv
                  opacity={this.state.hover ? 1 : 0}
                  bottom={"-6px"}
                  left={"1px"}
                  width={"calc(100% - 2px)"}
                  height={"3px"}
                  bg={"#01C993"}
                />
              </Flex>
              {this.props.hideBottomBorder === true ? null : (
                <AbsoluteDiv bottom={"0px"} left={"0px"} width={"100%"} height={"1px"} bg={"#e5e5e5"} />
              )}
            </ClickCursorFlex>
          </span>
        )}
      </>
    );
  }
}
const MenuRow = connect(
  null,
  dispatch => ({
    toggleMenu: bindActionCreators(toggleMenu, dispatch)
  })
)(MenuRowComponent);

class Menu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Flex
        className="menu"
        flexDirection={"column"}
        position={"fixed"}
        top={"0px"}
        right={"0px"}
        height={"100%"}
        width={["320px", "320px", "320px", "574px"]}
        zIndex={90000}
        bg={"white"}
      >
        <MenuRow
          onClickProp={() => {
            this.props.forceNewScrollPercent(modulePercentForChapter(0, modules));
          }}
        >
          <Box
            pt={["1px"]}
            width={["13px", "13px", "13px", "20px"]}
            height={["13px", "13px", "13px", "20px"]}
            mr={["7px", "7px", "7px", "17px"]}
          >
            <HomeSVG
              style={{
                height: "100%",
                width: "100%",
                fill: "#10CFAB"
              }}
            />
          </Box>
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            Home
          </GTAmerica>
        </MenuRow>
        <MenuRow
          onClickProp={() => {
            this.props.forceNewScrollPercent(modulePercentForChapter(1, modules));
          }}
        >
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            1.&nbsp;&nbsp;Wild Chocolate bar
          </GTAmerica>
        </MenuRow>
        <MenuRow
          onClickProp={() => {
            this.props.forceNewScrollPercent(modulePercentForChapter(2, modules));
          }}
        >
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            2.&nbsp;&nbsp;Purus River region
          </GTAmerica>
        </MenuRow>
        <MenuRow
          onClickProp={() => {
            this.props.forceNewScrollPercent(modulePercentForChapter(3, modules));
          }}
        >
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            3.&nbsp;&nbsp;São Sebastião community
          </GTAmerica>
        </MenuRow>
        <MenuRow
          onClickProp={() => {
            this.props.forceNewScrollPercent(modulePercentForChapter(4, modules));
          }}
        >
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            4.&nbsp;&nbsp;Cacao forest
          </GTAmerica>
        </MenuRow>
        <MenuRow link={"https://wildchocolate.org/shop/chocolate/"}>
          <Box
            pt={["2px"]}
            width={["16px", "16px", "16px", "21px"]}
            height={["16px", "16px", "16px", "21px"]}
            pr={["7px", "7px", "7px", "17px"]}
          >
            <BasketSVG
              style={{
                height: "100%",
                width: "100%",
                fill: "#10CFAB"
              }}
            />
          </Box>
          <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
            Buy Wild Chocolate
          </GTAmerica>
        </MenuRow>
        <MenuRow hideBottomBorder={true} onClickProp={() => {}}>
          <Flex
            pt={["1px"]}
            width={["15px", "15px", "15px", "21px"]}
            height={["15px", "15px", "15px", "21px"]}
            pr={["7px", "7px", "7px", "17px"]}
            pointerEvents={"all"}
          >
            <HeartSVG
              style={{
                height: "100%",
                width: "100%",
                fill: "#10CFAB"
              }}
            />
          </Flex>
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="29L5D2CDPA468" />
            <input
              style={{ visibility: "hidden", position: "absolute" }}
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />

            <button
              style={{
                border: "0px solid transparent",
                background: "transparent",
                cursor: "pointer",
                padding: "0px",
                margin: "0px"
              }}
              type="submit"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            >
              <GTAmerica lineHeight={1} fontSize={["18px", "18px", "18px", "21px"]}>
                Support this project
              </GTAmerica>
            </button>
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </MenuRow>
        <AbsoluteDiv
          bottom={["25px", "25px", "25px", "5.3vh"]}
          left={["21px", "21px", "21px", "42px"]}
          right={["21px", "21px", "21px", "42px"]}
        >
          <ClickCursorFlex flexDirection={"row"} justifyContent={"space-between"}>
            <GTAmerica
              onClick={() => {
                this.props.toggleSponsors();
              }}
              fontSize={"14px"}
            >
              Sponsors & Partners
            </GTAmerica>
            <GTAmerica
              onClick={() => {
                this.props.toggleCredits();
              }}
              fontSize={"14px"}
            >
              Credits
            </GTAmerica>
          </ClickCursorFlex>
        </AbsoluteDiv>
        <AbsoluteDiv
          bottom={["25px", "25px", "25px", "5.3vh"]}
          left={["21px", "21px", "21px", "42px"]}
          right={["21px", "21px", "21px", "42px"]}
        />
        <AbsoluteDiv top={["0px"]} right={["0px"]} height={["9.7vh", "9.7vh", "9.7vh", "11.3vh"]}>
          <Flex height={"100%"} justifyContent={"center"} alignItems={"center"}>
            <ClickCursorFlex
              onClick={event => {
                this.props.toggleMenu();
                event.stopPropagation();
              }}
              p={["20px", "20px", "20px", "30px"]}
              width={["15px", "15px", "15px", "15px"]}
              height={["15px", "15px", "15px", "15px"]}
            >
              <CloseSVG
                style={{
                  width: "100%",
                  stroke: "#000000",
                  strokeWidth: "1px",
                  fill: "none"
                }}
              />
            </ClickCursorFlex>
          </Flex>
        </AbsoluteDiv>
      </Flex>
    );
  }
}

export default connect(
  state => ({
    menu: state.menu
  }),
  dispatch => ({
    toggleMenu: bindActionCreators(toggleMenu, dispatch),
    forceNewScrollPercent: bindActionCreators(forceNewScrollPercent, dispatch),
    toggleSponsors: bindActionCreators(toggleSponsors, dispatch),
    toggleCredits: bindActionCreators(toggleCredits, dispatch)
  })
)(Menu);
