import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { GTAmerica, GTAmericaStyled } from "../components/typography.js";
import { CoverImg, CoverImgStyled } from "./components";
import { PrimaryButton, PrimaryText } from "./components";
import DataSourceStandardButton from "../components/dataSourceButton";
import {
  FixedDiv,
  AbsoluteDiv,
  Flex,
  Box,
  ClickCursorFlex
} from "../components/layout.js";
import { ReactComponent as DownArrowSVG } from "../images/down-arrow.svg";
import { ID } from "../utils/id";
import { Play360Button } from "./components";
import { PlayButton } from "./components";
import { GalleryButton } from "./components";
import { ImgStyled } from "./components";
import { CoverImgResponsive } from "./components";
import { ReactComponent as LinkSVG } from "../images/link.svg";
import { toggle as toggleShareMenu } from "../actions/shareMenu";
import seedProjectIMG from "../images/seed-project.jpeg";

export const Text = ({ ...props }) => (
  <GTAmerica
    fontSize={["21px", "21px", "21px", "36px"]}
    lineHeight={["27px", "27px", "27px", "46px"]}
    fontStyle={["normal", "normal", "normal", "normal"]}
    textAlign={["center", "center", "center", "center"]}
    py={["3vh", "3vh", "3vh", "3vh"]}
    fontWeight={[400]}
    {...props}
  >
    {props.children}
  </GTAmerica>
);

const CTAText = styled(GTAmericaStyled)`
  letter-spacing: 0.15em;
  font-weight: bold;
`;
const CTAButton = ({ ...props }) => (
  <Flex
    height={["45px", "45px", "45px", "70px"]}
    width={["192px", "192px", "192px", "294px"]}
    borderRadius={["45px", "45px", "45px", "50px"]}
    bg={["#10CFAB"]}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <CTAText
      fontWeight={700}
      fontSize={["14px", "14px", "14px", "18px"]}
      lineHeight={["20px", "20px", "20px", "25px"]}
      fontStyle={["normal"]}
      textAlign={["center"]}
      fontWeight={[400]}
      color={"#292121"}
    >
      {props.children}
    </CTAText>
  </Flex>
);

const SupportByShareTaglineComponent = ({ ...props }) => (
  <ClickCursorFlex
    pt={["17px", "17px", "17px", "17px"]}
    position={"relative"}
    flexDirection={"row"}
    pointerEvents={"auto"}
    onClick={event => {
      event.stopPropagation();
      props.toggleShareMenu();
    }}
  >
    <Flex
      height={["12px", "12px", "12px", "12px"]}
      style={{ transform: "translateY(20%)" }}
    >
      <LinkSVG style={{ fill: "white" }} height="100%" />
    </Flex>
    <CTAText
      fontSize={["11px", "11px", "11px", "11px"]}
      lineHeight={["15px", "15px", "15px", "15px"]}
      color={"white"}
    >
      SHARE THIS ORIGIN STORY
    </CTAText>
    <AbsoluteDiv
      opacity={1}
      bottom={"-6px"}
      left={"1px"}
      width={"calc(100% - 2px)"}
      height={"3px"}
      bg={"#01C993"}
    />
  </ClickCursorFlex>
);
const SupportByShareTagline = connect(
  null,
  dispatch => ({
    toggleShareMenu: bindActionCreators(toggleShareMenu, dispatch)
  })
)(SupportByShareTaglineComponent);

export const supportModule = {
  id: ID(),
  headerVisibility: true,
  chapterNumber: 6,
  scenes: [
    {
      headerColor: "white",
      top_slide: [],
      top_fade: [
        <Flex
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          pointerEvents={"none"}
        >
          <Flex
            width={["84.8vw", "84.8vw", "84.8vw", "52vw"]}
            height={["28.3vh", "28.3vh", "28.3vh", "43vh"]}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ImgStyled src={seedProjectIMG} />
          </Flex>
          <Text color={"white"}>
            Help seed the next Wild Cacao community project{" "}
          </Text>
          <ClickCursorFlex flexDirection={"column"} pointerEvents={"auto"}>
            <CTAButton>LEARN MORE</CTAButton>
          </ClickCursorFlex>
          <SupportByShareTagline />
        </Flex>
      ],
      middle: [],
      bottom: [
        <ClickCursorFlex bg={"##202020"} width={"100%"} height={"100%"} />
      ]
    }
  ]
};
