import React, { Component } from "react";
import { DataSourceButton } from "../modules/components";
import { GTAmerica } from "../components/typography.js";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import styled from "styled-components";
const GTAmericaWrapped = styled(GTAmerica)`
  word-break: break-all;
`;

export const StandardDatasource = ({ ...props }) => (
  <>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      "“The Purus eco-region is flat tropical rainforest, dissected by large rivers, frequent oxbows and thousands of
      tiny streams, all of which flood annually. The high canopy forest is super-rich in biodiversity, with over 171
      mammals recorded and tree richness up to 250 species per hectare. ”"
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      {props.citation}
    </GTAmerica>
    <a href={props.link}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        {props.link}
      </GTAmericaWrapped>
    </a>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      {props.quote}
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      {props.citation}
    </GTAmerica>
    <a href={props.link}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        {props.link}
      </GTAmericaWrapped>
    </a>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      {props.quote}
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      {props.citation}
    </GTAmerica>
    <a href={props.link}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        {props.link}
      </GTAmericaWrapped>
    </a>
  </>
);

const antEater = (
  <>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      “The Purus eco-region is flat tropical rainforest, dissected by large rivers, frequent oxbows and thousands of
      tiny streams, all of which flood annually. The high canopy forest is super-rich in biodiversity, with over 171
      mammals recorded and tree richness up to 250 species per hectare. ”
    </GTAmerica>
    <a href={"https://www.worldwildlife.org/ecoregions/nt0133"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        https://www.worldwildlife.org/ecoregions/nt0133
      </GTAmericaWrapped>
    </a>
    <br />
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Silky Anteater
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Photo Credit:
    </GTAmerica>
    <a href={"https://en.wikipedia.org/wiki/Silky_anteater#/media/File:Silky_Anteater.jpg"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        Quinten Questel, Wikimedia Commons
      </GTAmericaWrapped>
    </a>
    <br />
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Jaguar
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Photo Credit:
    </GTAmerica>
    <a
      href={
        "https://en.wikipedia.org/wiki/Jaguar#/media/File:Jaguar_(Panthera_onca_palustris)_female_Piquiri_River.JPG"
      }
    >
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        Charles J Sharp, Wikimedia Commons
      </GTAmericaWrapped>
    </a>
    <br />
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Pygmy Marmoset
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Photo Credit:
    </GTAmerica>
    <a href={"https://upload.wikimedia.org/wikipedia/commons/1/1a/Pygmy_Marmoset_%285336773146%29.jpg"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        Wikimedia Commons
      </GTAmericaWrapped>
    </a>
    <br />
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Brazil Nut Tree
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Photo Credit:
    </GTAmerica>
    <a href={"https://pt.wikipedia.org/wiki/Ficheiro:A_imponência_da_castanheira.jpg"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        Edson Grandisoli, Wikimedia Commons
      </GTAmericaWrapped>
    </a>
  </>
);

const deforestation = (
  <>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      “Global forest loss caused by cocoa production was roughly 2-3 million hectares from 1988 to 2008, which equaled
      approximately 1% of total forest loss.[i] Cocoa is spreading, and as it does, it threatens new forests. “From 2000
      to 2014, the global production of cocoa beans increased by 32 per cent – from 3.4 to 4.5 million tonnes – while
      the land-use footprint of cocoa plantations grew by 37 per cent – from 7.6 to 10.4 million hectares.” [iii]
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      For more information, updates and new company commitments on the issue see
    </GTAmerica>
    <a href={"http://www.mightyearth.org/kissed-by-deforestation/"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        http://www.mightyearth.org/kissed-by-deforestation/
      </GTAmericaWrapped>
    </a>
    <br />
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      <a href={"http://www.mightyearth.org/kissed-by-deforestation/#_ednref1"}>
        <GTAmericaWrapped
          fontSize={["14px", "14px", "14px", "16px"]}
          lineHeight={["22px", "22px", "22px", "26px"]}
          color={"#02DCA1"}
        >
          [i]
        </GTAmericaWrapped>
      </a>
      Kroeger, A. et al. (2017) Eliminating Deforestation from the Cocoa Supply Chain. World Bank Group, March 2017.
    </GTAmerica>
    <br />
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      <a href={"http://www.mightyearth.org/kissed-by-deforestation/#_ednref1"}>
        <GTAmericaWrapped
          fontSize={["14px", "14px", "14px", "16px"]}
          lineHeight={["22px", "22px", "22px", "26px"]}
          color={"#02DCA1"}
        >
          [ii]
        </GTAmericaWrapped>
      </a>
      <a href={"http://www.mightyearth.org/kissed-by-deforestation/#_ednref2"}>
        <GTAmericaWrapped
          fontSize={["14px", "14px", "14px", "16px"]}
          lineHeight={["22px", "22px", "22px", "26px"]}
          color={"#02DCA1"}
        >
          http://ec.europa.eu/environment/forests/pdf/1.%20Report%20analysis%20of%20impact.pdf
        </GTAmericaWrapped>
      </a>
    </GTAmerica>
    <br />
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      <a href={"http://www.mightyearth.org/kissed-by-deforestation/#_ednref3"}>
        <GTAmericaWrapped
          fontSize={["14px", "14px", "14px", "16px"]}
          lineHeight={["22px", "22px", "22px", "26px"]}
          color={"#02DCA1"}
        >
          [iii]
        </GTAmericaWrapped>
      </a>
      <a href={"https://resourcetrade.earth/stories/cocoa-trade-climate-change-and-deforestation#section-171"}>
        <GTAmericaWrapped
          fontSize={["14px", "14px", "14px", "16px"]}
          lineHeight={["22px", "22px", "22px", "26px"]}
          color={"#02DCA1"}
        >
          https://resourcetrade.earth/stories/cocoa-trade-climate-change-and-deforestation#section-171
        </GTAmericaWrapped>
      </a>
    </GTAmerica>
    <br />
    <br />
    <br />
  </>
);

const riverHarvest = (
  <>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Photo credit for harvest photos
    </GTAmerica>
    <GTAmerica pt={["15px"]} fontSize={["14px", "14px", "14px", "16px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      Kin Dias
    </GTAmerica>
    <a href={"http://www.luisaabram.com.br/"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        Luisa Abram Chocolate
      </GTAmericaWrapped>
    </a>
  </>
);

const ayahuasca = (
  <>
    <GTAmerica fontSize={["16px", "16px", "16px", "21px"]} lineHeight={["22px", "22px", "22px", "26px"]}>
      The psychedelic effects of ayahuasca include visual and auditory stimulation, the mixing of sensory modalities,
      and psychological introspection that may lead to great elation, fear, or illumination. Its purgative properties
      (including vomiting and diarrhea) are used to clear the body of worms and other tropical parasites.
    </GTAmerica>
    <GTAmerica
      pt={["15px"]}
      fontSize={["14px", "14px", "14px", "16px"]}
      lineHeight={["22px", "22px", "22px", "26px"]}
    />
    <a href={"https://en.wikipedia.org/wiki/Ayahuasca"}>
      <GTAmericaWrapped
        fontSize={["14px", "14px", "14px", "16px"]}
        lineHeight={["22px", "22px", "22px", "26px"]}
        color={"#02DCA1"}
      >
        https://en.wikipedia.org/wiki/Ayahuasca
      </GTAmericaWrapped>
    </a>
  </>
);

export const datasources = [antEater, deforestation, riverHarvest, ayahuasca];
