import React, { Component } from "react";
import { connect } from "react-redux";
import { MissCacao } from "./missCacaoInteractive";
import { TransitionGroup, CSSTransition, Transition } from "react-transition-group";
import { FullScreen } from "./3dComponents";
import { HeroLanding, SecondHeroText, ThirdHeroText, HeroNextButton } from "./hero";
import styled from "styled-components";
import TWEEN from "@tweenjs/tween.js";
import { AnimalText } from "./components";
import { FixedDiv, AbsoluteDiv, Flex, Box, ClickCursorFlex } from "../components/layout.js";

import { TimelineMax, TweenMax, CSSPlugin, Power0, AttrPlugin, TweenLite } from "gsap/all";
//without this line, CSSPlugin and AttrPlugin may get dropped by your bundler...

import Interactive from "./Interactive";

import { layers, LAYER_WIDTH, LAYER_HEIGHT } from "./missCacaoInteractiveImageImports";
const plugins = [CSSPlugin, AttrPlugin];

const PERSPECTIVE_START = 2000;
const PERSPECTIVE_END = 4000;
const BG_SCALE_END = 1.8;
const BG_SCALE_START = 2.8;
const ROTATION_ADJUST = 90;
const BG_TRANSLATE_Z = -400;
const BACKGROUND_WRAPPER_ID = "miss-cacao-background-wrapper";
const BACKGROUND_ID = "miss-cacao-background-rotater";

const shorterLayers = layers.slice(1, 99);

const HeroGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 35.36%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.51%, rgba(0, 0, 0, 0.6) 100%);
`;

class HeroCacaoForest extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.firstRef = React.createRef();
    this.firstTimeline = null;
  }
  componentDidMount() {
    this.firstTimelineSetup();
  }
  firstTimelineSetup() {
    this.firstTimeline = new TimelineMax();

    this.firstTimeline.add(
      TweenLite.set(this.firstRef, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 1, {
        y: 0,
        ease: Power0.easeNone,
        autoAlpha: 1
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.add(
      TweenLite.to(this.firstRef, 0.5, {
        y: 50,
        ease: Power0.easeNone,
        autoAlpha: 0
      })
    );
    this.firstTimeline.pause();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.scrollPercent.subModulePercent !== this.props.scrollPercent.subModulePercent ||
      nextProps.scrollPercent.moduleIndex !== this.props.scrollPercent.moduleIndex
    ) {
      let boundedScroll = Math.min(Math.max(nextProps.scrollPercent.subModulePercent, 0), 1);
      const myIndex = this.props.myIndex;

      if (this.props.scrollPercent.moduleIndex !== myIndex && nextProps.scrollPercent.moduleIndex !== myIndex) {
        return;
      }

      let isEntering =
        nextProps.scrollPercent.moduleIndex == myIndex &&
        (this.props.scrollPercent.moduleIndex < myIndex || this.props.scrollPercent.moduleIndex > myIndex);
      let isExiting =
        this.props.scrollPercent.moduleIndex == myIndex &&
        (nextProps.scrollPercent.moduleIndex > myIndex || nextProps.scrollPercent.moduleIndex < myIndex);

      let directionUp = this.props.scrollPercent.moduleIndex > nextProps.scrollPercent.moduleIndex;

      let delay = 0;
      let duration = 100;
      let startSubPercent = this.props.scrollPercent.subModulePercent;
      let endSubPercent = boundedScroll;

      if (isEntering) {
        delay = 666;
        duration = 1000;
        startSubPercent = this.props.scrollPercent.moduleIndex < myIndex ? 0 : 0.99;
      } else if (isExiting) {
        endSubPercent = nextProps.scrollPercent.moduleIndex > myIndex ? 1 : 0;
      }

      let percent = { value: startSubPercent };
      let end = {
        value: endSubPercent
      };
      var tween = new TWEEN.Tween(percent) // Create a new tween that modifies 'coords'.
        .delay(delay)
        .to(end, duration) // Move to (300, 200) in 1 second.
        .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(obj => {
          // Called after tween.js updates 'coords'.
          // Move 'box' to the position described by 'coords' with a CSS translation.
          this._updateTimelines(obj.value);
        })
        .start(); // Start the tween immediately.
    }
  }
  _updateTimelines(value) {
    this.firstTimeline.progress(value);
  }
  render() {
    return (
      <>
        <Interactive
          initialTransform={"scale(2)"}
          desktopLeftAdjust={25}
          scaleFactor={2}
          viewWidth={200}
          viewHeight={200}
          bgLayer={layers[0]}
          layers={shorterLayers}
          layerWidth={LAYER_WIDTH}
          layerHeight={LAYER_HEIGHT}
          perspectiveStart={PERSPECTIVE_START}
          perspectiveEnd={PERSPECTIVE_END}
          bgScaleEnd={BG_SCALE_END}
          bgScaleStart={BG_SCALE_START}
          rotationAdjust={ROTATION_ADJUST}
          bgTranslateZ={BG_TRANSLATE_Z}
          backgroundWrapperId={BACKGROUND_WRAPPER_ID}
          backgroundId={BACKGROUND_ID}
          gradientComponent={<HeroGradient className="hero-gradient" />}
        />
        <FullScreen innerRef={div => (this.firstRef = div)}>
          <AnimalText color={["white", "white", "white", "white"]}>
            This year my production was modest. The community asked why?
            <br />
            <br /> I guided, “You seem very eager. Go slowly.” <br />
            <br />
            It’s time to rest, regain strength and fertilize my plants.
          </AnimalText>
        </FullScreen>
      </>
    );
  }
}

export default (HeroCacaoForest = connect(
  state => ({
    scrollPercent: state.scrollPercent,
    browser: state.browser
  }),
  null
)(HeroCacaoForest));
