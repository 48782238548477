export const showButton = () => ({
  type: "SHOW_BUTTON"
});
export const hideButton = () => ({
  type: "HIDE_BUTTON"
});
export const toggle = () => ({
  type: "TOGGLE_MOTION"
});
export const hide = () => ({
  type: "HIDE_MOTION"
});
