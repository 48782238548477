import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { GTAmerica } from "./typography.js";
import { Flex, Box, ClickCursorFlex, AbsoluteDiv } from "./layout.js";
import { ReactComponent as HomeSVG } from "../images/home.svg";
import { ReactComponent as LinkSVG } from "../images/link.svg";
import { ReactComponent as MenuSVG } from "../images/menu.svg";
import { ReactComponent as ShareBubbleSVG } from "../images/share-bubble.svg";

import { ReactComponent as FacebookSVG } from "../images/facebook.svg";
import { ReactComponent as TwitterSVG } from "../images/twitter.svg";
import { ReactComponent as ShareLinkSVG } from "../images/share-link.svg";

import { toggle } from "../actions/shareMenu";
import { copyToClipboard } from "../utils/copy";
import { SHORT_TRANSITION_TIMEOUT } from "../utils/animation";

const SHARE_URL = "https://www.wild-cacao.org";
const SHARE_TEXT =
  "Wild Chocolate: Unwrap the story from bean to bar on this immersive journey to the heart of the Amazon.";
const SHARE_HASHTAGS = ["wild", "chocolate", "biodiversity", "amazon", "rainforest", "knowyourfarmer"];
export class ShareBubble extends Component {
  state = {
    show_LinkCopied: false
  };
  constructor(props) {
    super(props);
  }
  _linkCopied(event) {
    copyToClipboard("https://www.wild-cacao.org");
    this.setState({ show_LinkCopied: true });
    setTimeout(() => {
      this.setState({ show_LinkCopied: false });
    }, 1332);

    event.stopPropagation();
  }
  render() {
    return (
      <>
        <AbsoluteDiv width={"242px"} right={"19px"} top={"56px"} display={this.props.visible ? "block" : "none"}>
          <ShareBubbleSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "black",
              filter: "blur(4px)",
              opacity: 0.25
            }}
          />
          <AbsoluteDiv top={"40px"} zIndex={10000} left={"20px"} right={"20px"} display={"inline"}>
            <Flex justifyContent="space-between">
              <a
                href={"https://www.facebook.com/sharer.php?u=" + encodeURIComponent("https://www.wild-cacao.org")}
                target="_blank"
              >
                <Flex pointerEvents={"all"} flexDirection="column">
                  <FacebookSVG
                    style={{
                      width: "54px",
                      height: "54px",
                      fill: "#3B5998"
                    }}
                  />
                  <GTAmerica pt={"11px"} fontWeight={300} fontSize={"12px"} color={"#6d6d6d"} textAlign={"center"}>
                    Facebook
                  </GTAmerica>
                </Flex>
              </a>
              <a
                href={
                  "http://twitter.com/share?text=" +
                  encodeURIComponent(SHARE_TEXT) +
                  "&url=" +
                  encodeURIComponent(SHARE_URL) +
                  "&hashtags=" +
                  SHARE_HASHTAGS.join(",")
                }
                target="_blank"
              >
                <Flex pointerEvents={"all"} flexDirection="column">
                  <TwitterSVG
                    style={{
                      width: "54px",
                      height: "54px",
                      fill: "#00ACED"
                    }}
                  />
                  <GTAmerica pt={"11px"} fontWeight={300} fontSize={"12px"} color={"#6d6d6d"} textAlign={"center"}>
                    Twitter
                  </GTAmerica>
                </Flex>
              </a>
              <ClickCursorFlex onClick={event => this._linkCopied(event)} flexDirection="column">
                <ShareLinkSVG
                  style={{
                    width: "54px",
                    height: "54px",
                    fill: "#02DCA1"
                  }}
                />
                <GTAmerica pt={"11px"} fontWeight={300} fontSize={"12px"} color={"#6d6d6d"} textAlign={"center"}>
                  Share Link
                </GTAmerica>
              </ClickCursorFlex>
            </Flex>
          </AbsoluteDiv>
        </AbsoluteDiv>
        <AbsoluteDiv width={"242px"} right={"20px"} top={"56px"} display={this.props.visible ? "block" : "none"}>
          <ShareBubbleSVG
            style={{
              width: "100%",
              height: "100%",
              fill: "white"
            }}
          />
        </AbsoluteDiv>
        <TransitionGroup component={null} appear={true} exit={true}>
          {this.state.show_LinkCopied && this.props.visible ? (
            <CSSTransition
              timeout={SHORT_TRANSITION_TIMEOUT}
              className="element"
              classNames="fade"
              key={"share-bubble"}
            >
              <AbsoluteDiv width={"242px"} right={"20px"} top={"56px"} zIndex={10001} display={"block"}>
                <ShareBubbleSVG
                  style={{
                    width: "100%",
                    height: "100%",
                    fill: "white"
                  }}
                />

                <AbsoluteDiv
                  top={"40px"}
                  zIndex={10000}
                  left={"20px"}
                  right={"20px"}
                  bottom={"30px"}
                  display={"inline"}
                >
                  <Flex height={"100%"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <GTAmerica fontSize={"16px"}>Link has been copied!</GTAmerica>
                  </Flex>
                </AbsoluteDiv>
              </AbsoluteDiv>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>
    );
  }
}
